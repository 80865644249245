//Components
import Divider from "../../../components/Tools/Basics/Divider";
import TableUsers from "./components/index/TableUsers";

const Users = () => {
  return (
    <div className="custom-container">
      <h2>
        <b>Usuarios Terminales y Servicios</b>
      </h2>
      <Divider />
      <TableUsers />
    </div>
  );
};

export default Users;
