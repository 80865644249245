import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import {
  urlGetItems_x_Checklist,
  urlLinkItemsCheckclist,
  urlUnlinkItemsChecklist,
} from "../../../../../api/URLs/mantenimiento";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
//--Modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../../../components/Tools/Tables/Table/Table";
//--Basic
import BoxContainer from "../../../../../components/Tools/Cards/BoxContainer/BoxContainer";

const LinkItems = ({ checklist }) => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //States
  const [loadingA, setLoadingA] = useState(false);
  const [loadingN, setLoadingN] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [itemsA, setItemsA] = useState([]);
  const [itemsN, setItemsN] = useState([]);
  const [selectedItemsA, setSelectedItemsA] = useState([]);
  const [selectedItemsN, setSelectedItemsN] = useState([]);
  //GET
  const getParallel = async () => {
    setLoadingA(true);
    setLoadingN(true);
    const promise_array = [];
    promise_array.push(
      await axiosWithAuth.get(urlGetItems_x_Checklist, {
        params: {
          id_checklist: checklist.id,
          accion: "A",
        },
      })
    );
    promise_array.push(
      await axiosWithAuth.get(urlGetItems_x_Checklist, {
        params: {
          id_checklist: checklist.id,
          accion: "N",
        },
      })
    );
    try {
      const api_responses = await Promise.all(promise_array);
      const [itemsA_response, itemsN_response] = api_responses;
      setItemsA(itemsA_response.data);
      setItemsN(itemsN_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoadingA(false);
      setLoadingN(false);
      setSelectedItemsA([]);
      setSelectedItemsN([]);
    }
  };
  //POST
  const handleSubmit = async (values, selector) => {
    try {
      let api_response;
      if (selector === "A") {
        setLoadingA(true);
        const formatValuesA = values.map(({ id_checklist, id_item }) => ({
          id_checklist,
          id_item,
        }));
        api_response = await axiosWithAuth.delete(urlUnlinkItemsChecklist, {
          data: { arrIDsCuerpoChecklist: formatValuesA },
        });
      } else {
        setLoadingN(true);
        const formatValuesN = values.map(({ id_item }) => ({
          id_checklist: checklist.id,
          id_item,
        }));
        api_response = await axiosWithAuth.post(urlLinkItemsCheckclist, {
          arrIDsChecklistItem: formatValuesN,
        });
      }
      handleSucces(api_response);
      setReFetch(!reFetch);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoadingA(false);
      setLoadingN(false);
    }
  };
  //HANDLE SELECT ITEMS
  const handleSelectItemsA = (item) => {
    const itemIndex = selectedItemsA.indexOf(item);
    if (itemIndex === -1) {
      setSelectedItemsA([...selectedItemsA, item]);
    } else {
      setSelectedItemsA(selectedItemsA.filter((ITEM) => ITEM !== item));
    }
  };
  const handleSelectItemsN = (item) => {
    const itemIndex = selectedItemsN.indexOf(item);
    if (itemIndex === -1) {
      setSelectedItemsN([...selectedItemsN, item]);
    } else {
      setSelectedItemsN(
        selectedItemsN.filter((ITEM) => ITEM.id_item !== item.id_item)
      );
    }
  };
  //TABLE A
  const checkbox = (item) => {
    return (
      <input
        type="checkbox"
        checked={selectedItemsA.indexOf(item) !== -1}
        onChange={() => handleSelectItemsA(item)}
        disabled={selectedItemsN.length > 0 ? true : false}
      />
    );
  };
  const columnsA = [
    { body: checkbox },
    { field: "item_nombre", header: "Nombre" },
    { field: "item_placeholder", header: "Placeholder" },
  ];
  const buttonA = [
    {
      type: "minus_table_header",
      text: " Quitar Items",
      disabled: false,
      click: () => handleSubmit(selectedItemsA, "A"),
      noData: true,
      disabled: selectedItemsA.length === 0 ? true : false,
    },
  ];
  //TABLE N
  const checkboxN = (item) => {
    return (
      <input
        type="checkbox"
        checked={selectedItemsN.indexOf(item) !== -1}
        onChange={() => handleSelectItemsN(item)}
        disabled={selectedItemsA.length > 0 ? true : false}
      />
    );
  };
  const columnsN = [
    { body: checkboxN },
    { field: "item_nombre", header: "Nombre" },
    { field: "item_placeholder", header: "Placeholder" },
  ];
  const buttonN = [
    {
      type: "plus_table_header",
      text: " Asignar Items",
      disabled: false,
      click: () => handleSubmit(selectedItemsN, "N"),
      //click: console.log,
      noData: true,
      disabled: selectedItemsN.length === 0 ? true : false,
    },
  ];
  useEffect(() => {
    getParallel();
  }, [reFetch]);
  return (
    <div className="row">
      <div className="col-md-6">
        <BoxContainer minSize={"lg"} maxSize={"lg"}>
          <Table
            data={itemsA}
            columns={columnsA}
            title={`Items de: ${checklist.nombre}`}
            loading={loadingA}
            buttons={buttonA}
            scrollSize={"lg"}
          />
        </BoxContainer>
      </div>
      <div className="col-md-6">
        <BoxContainer minSize={"lg"} maxSize={"lg"}>
          <Table
            data={itemsN}
            columns={columnsN}
            title={"Items Libres"}
            loading={loadingN}
            buttons={buttonN}
            scrollSize={"lg"}
          />
        </BoxContainer>
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default LinkItems;
