import * as Yup from "yup";

export const sendChecklistSchema = Yup.object().shape({
  psw_maquinista: Yup.string()
    .required("La contraseña es obligatoria")
    .typeError("Este campo es obligatorio"),
  psw_mecanico: Yup.string()
    .required("La contraseña es obligatoria")
    .typeError("Este campo es obligatorio"),
  horas_uso: Yup.number()
    .required("Campo obligatorio, solo numeros y puntos")
    .typeError("Campo obligatorio, solo numeros y puntos"),
});

export const closeChecklistSchema = Yup.object().shape({
  psw_maquinista: Yup.string()
    .required("La contraseña es obligatoria")
    .typeError("Este campo es obligatorio"),
  psw_mecanico: Yup.string()
    .required("La contraseña es obligatoria")
    .typeError("Este campo es obligatorio"),
  horas_uso_fin: Yup.number()
    .required("Campo obligatorio, solo numeros y puntos")
    .typeError("Campo obligatorio, solo numeros y puntos"),
});