import React from "react";
import { useLocation } from "react-router-dom";
//NavTabs
import NavTabs from "../../../../../components/NavBars/NavTabs/NavTabs";
//Components
import DetalleVehiculo from "../DetalleVehiculo";
import CubiertasVehiculo from "../CubiertasVehiculo";
import CombustibleVehiculo from "../CombustibleVehiculo";

const VehiculosDetalles = () => {
  const location = useLocation();
  const vehicle = location.state.vehicle;

  const sections = [
    {
      name: "Datos y Vencimientos",
      component: <DetalleVehiculo vehicle={vehicle} />,
    },
    { name: "Cubiertas", component: <CubiertasVehiculo vehicle={vehicle} /> },
    {
      name: "Combustible",
      component: <CombustibleVehiculo vehicle={vehicle} />,
    },
  ];
  return (
    <div className="custom-container">
      <h2>
        <b>Vehiculo | {vehicle.patente}</b>
      </h2>
      <NavTabs sections={sections} />
    </div>
  );
};

export default VehiculosDetalles;
