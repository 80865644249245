import BoxContainer from "../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import TableRows from "../../../../components/Tools/Tables/TableRows/TableRows";

const DetalleVehiculoPersonal = ({ vehicle }) => {
  //Datos tabla Detalles
  const rows = [
    {
      first_title: "Id:",
      first_prop: "id",
    },
    { first_title: "Desc.:", first_prop: "desc_vehiculo" },
    {
      first_title: "km:",
      first_prop: "kilometros",
      first_formater: "km",
    },
    {
      first_title: "Sector:",
      first_prop: "sector",
    },
  ];

  //Datos tabla Vencimientos
  const dates = [
    {
      first_title: "Fec. Seguro:",
      first_prop: "fecha_seguro",
      first_formater: "date",
    },
    {
      first_title: "Fec. VTV:",
      first_prop: "fecha_vtv",
      first_formater: "date",
    },
  ];
  return (
    <div className="row">
      <div className="col-md-6">
        <BoxContainer minSize={"md"} maxSize={"md"}>
          <TableRows
            title={"Datos"}
            data={vehicle}
            rows={rows}
            scrollSize={"md"}
          />
        </BoxContainer>
      </div>
      <div className="col-md-6">
        <BoxContainer minSize={"md"} maxSize={"md"}>
          <TableRows
            title={"Vencimientos"}
            data={vehicle}
            rows={dates}
            scrollSize={"md"}
          />
        </BoxContainer>
      </div>
    </div>
  );
};

export default DetalleVehiculoPersonal;
