import TableMaquinistas from "./components/TableMaquinistas"
import Divider from "../../../components/Tools/Basics/Divider"

const Maquinistas = () => {
  return (
    <div className="custom-container">
      <h2>
        <b>Maquinistas Terminales y Servicios</b>
      </h2>
      <Divider />
      <TableMaquinistas/>
    </div>
  )
}

export default Maquinistas
