import * as ExcelJS from "exceljs";

export const exportChecklist = (data) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(`Checklist maquina ${data.identificador_maquina}`);
  const globalStyles = {
    alignment: { vertical: "center" },
  };
  //Columnas
  ws.columns = [
    {
      header: "ITEM",
      key: "nombre",
      width: 20,
      style: globalStyles,
    },
    {
      header: "DESC",
      key: "placeholder",
      width: 40,
      style: globalStyles,
    },
    {
      header: "VALOR",
      key: "value",
      width: 20,
      style: globalStyles,
    },
    {
      header: "OBSERVACIONES",
      key: "obs",
      width: 50,
      style: globalStyles,
    },
  ];
  //Filas
  const values = data.campos_checklist;
  values.forEach((item) => {
    ws.addRow(item);
  });
  //Estilos Cabecera
  const headerRow = ws.getRow(1);
  headerRow.eachCell((cell) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "a6d98c" },
    };
    cell.font = { bold: true };
  });
  wb.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Checklist maquina ${data.identificador_maquina}`;
    a.click();
    URL.revokeObjectURL(url);
  });
};
