import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlGetUsers } from "../../../../../api/URLs/usuarios";
//ROUTER
import { useNavigate } from "react-router-dom";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
//COMPONENTS
//--modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//--table
import TableMain from "../../../../../components/Tools/Tables/TableMain/TableMain";
//--basic
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";

const TableUsers = () => {
  //States
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  //useNavigate
  const navigate = useNavigate();
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //GET
  const getUsers = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetUsers);
      setUsers(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  
  //BOTONES
  const actions = (user) => {
    return (
      <CustomBtn
        type={"details_table"}
        onClick={() =>
          navigate(`/admin/usuarios/detalle/${user.id}`, {
            state: { user },
          })
        }
      />
    );
  };
  //COLUMNAS
  const columns = [
    { field: "id", header: "Id" },
    { field: "nombre", header: "Nombre" },
    { field: "email", header: "Correo" },
    {
      field: "is_admin",
      header: "Admin",
      formater: "boolean_color",
      sortable: true,
    },
    { body: actions },
  ];
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <div>
      <TableMain
        data={users}
        columns={columns}
        loading={loading}
        title={"Usuarios"}
        textFilter
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default TableUsers;
