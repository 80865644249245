import { useEffect, useState } from "react";
//API Route
import { urlDetailFuel } from "../../../../api/URLs/entidadesSecundarias/combustible";
import { axiosWithAuth } from "../../../../api/axiosConfig";
//Tables
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
//Modal
import { useModal } from "../../../../hooks/useModal";
import ModalPlain from "../../../../components/Modals/ModalPlain";
import ModalAlert from "../../../../components/Modals/ModalAlert";
//Form
import FormFuel from "../forms/FormFuel";

const CombustibleVehiculo = ({ vehicle }) => {
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [fuel, setFuel] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [showModalPlain, setShowModalPlain] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  //Modal Plain
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //Get Data
  const getData = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlDetailFuel, {
        params: {
          id_conductor: "null",
          id_camion: vehicle.id,
          fecha: "null",
        },
      });
      setFuel(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //Show data
  const columns = [
    { field: "apelnomb", header: "Chof.", sortable: true },
    { field: "origen", header: "Origen", sortable: true },
    { field: "kms", header: "Km", sortable: true, formater: "km", pdfFormat: "km" },
    { field: "litros", header: "Litros", sortable: true, formater: "litros" },
    {
      field: "precio_litro",
      header: "$ Litro",
      sortable: true,
      formater: "priece",
      pdfFormat: "priece"
    },
    {
      field: "precio_final",
      header: "$ Final",
      sortable: true,
      formater: "priece",
      pdfFormat: "priece"
    },
    { field: "ticket", header: "Ticket" },
    { field: "vale", header: "Vale" },
    {
      header: "Fec. Carga",
      field: "fecha_carga",
      formater: "date",
      pdfFormat: "date",
      sortable: true,
    },
  ];
  const handleFuel = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowModalPlain(true);
  };
  const buttonsTrips = [
    {
      type: "plus_table_header",
      text: " Nueva Carga",
      click: () => handleFuel(vehicle),
    },
  ];

  useEffect(() => {
    getData();
  }, [reFetch]);
  return (
    <div className="row">
      <div className="col-md-12">
        <TableMain
          data={fuel}
          columns={columns}
          keyProp={"id"}
          textFilter
          title={"Historial de carga"}
          loading={loading}
          buttons={buttonsTrips}
          excelExport
          pdf={{
            pdfSave: `Tabla-combustible-${vehicle.patente}`,
            pdfTitle: `Historial Combustible Veh: ${vehicle.patente} - Terminales y Servicios`,
          }}
        />
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          <FormFuel
            vehicle={selectedVehicle}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default CombustibleVehiculo;
