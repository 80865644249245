import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetEventCategories,
  urlCreateEvent,
} from "../../../../api/URLs/vehiculosPersonal";
//FORMIK & YUP
import { Formik } from "formik";
import { eventSchema } from "../../../../schemas/PersonalVehicles/eventSchema";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import { useModal } from "../../../../hooks/useModal";
import { useAlertModal } from "../../../../hooks/useAlertModal";
//--Basic
import CustomDropdown from "../../../../components/Tools/Inputs/CustomDropdown";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";

const FormEvent = ({ vehicle, reFetch, setReFetch }) => {
  //--Modal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
    handleAlertWarning,
  } = useAlertModal();

  //States
  const [showCategories, setShowCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  //Get Categories
  const getEventCategories = async () => {
    try {
      setLoading(true)
      const response = await axiosWithAuth.get(urlGetEventCategories);
      const data = response.data;
      setShowCategories(data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false)
    }
  }; 

  //Set category id
  const handleSelectCategory = async (c) => {
    setCategory(c.id);
  };

  //Initial Values
  const initialValues = {
    id_vehiculo: vehicle.id,
    desc_evento: "",
    obs: "",
    fecha: "",
    kilometros: "",
  };

  //Validations
  const validateOptions = () => {
    if (!category) {
      handleAlertWarning("No se ha seleccionado una categoria");
      return false;
    }
    return true;
  };

  //POST
  const handleSubmit = async (values) => {
    const validations = validateOptions();
    if (!validations) return;
    try {
      setSendLoading(true);
      const api_response = await axiosWithAuth.post(urlCreateEvent, {
        ...values, id_categoria: category
      });
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };

  //Use effect
  useEffect(() => {
    getEventCategories();
  }, []);

  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;

  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        validationSchema={eventSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                {/* CATEGORIA */}
                <FormGroup>
                  <Form.Label>Categoria</Form.Label>
                  <CustomDropdown
                    options={showCategories}
                    displayProperty={"desc_categoria"}
                    onSelect={handleSelectCategory}
                    btnName={"Seleccione una categoria"}
                  />
                </FormGroup>
                {/* DESCRIPCION */}
                <FormGroup>
                  <Form.Label>Descripcion</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Breve descripcion"
                    name="desc_evento"
                    value={values.desc_evento}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      errors.desc_evento &&
                      touched.desc_evento &&
                      errors.desc_evento
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.desc_evento &&
                      touched.desc_evento &&
                      errors.desc_evento}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* OBSERVACIONES */}
                <FormGroup>
                  <Form.Label>Observaciones</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Observaciones"
                    name="obs"
                    value={values.obs}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* FECHA */}
                <FormGroup>
                  <Form.Label>Fecha</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha"
                    value={values.fecha}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                {/* KILOMETROS */}
                <FormGroup>
                  <Form.Label>Kilometros</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Km actuales"
                    name="kilometros"
                    value={values.kilometros}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      errors.kilometros &&
                      touched.kilometros &&
                      errors.kilometros
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.kilometros &&
                      touched.kilometros &&
                      errors.kilometros}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* INFO VEHICULO */}
                <FormGroup>
                  <Form.Label>Vehiculo</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={`Patente: ${vehicle.patente}`}
                    disabled
                  />
                </FormGroup>
              </div>
              <div className="col-md-6 offset-md-3 text-center">
                <ButtonSubmit
                  isSubmitting={isSubmitting}
                  loading={sendLoading}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormEvent;
