//RUTAS CUBIERTAS
//--gam
export const urlGetTyres = "/api/tyre";
export const urlCreateTyres = "/api/tyre";
export const urlEditTyres = "/api/tyre";
//--gam marcas
export const urlGetTyresBrand = "/api/tyre-brand";
export const urlCreateTyresBrand = "/api/tyre-brand";
export const urlEditTyresBrand = "/api/tyre-brand";
//--gam medidas
export const urlGetTyresSize = "/api/tyre-size";
export const urlCreateTyresSize = "/api/tyre-size";
export const urlEditTyresSize = "/api/tyre-size";
