import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//API
import { axiosWithAuth } from "../../../api/axiosConfig";
import { urlSwitchEntities } from "../../../api/URLs/utilities";
import { urlGetPersonalVehicles } from "../../../api/URLs/vehiculosPersonal";
//Form
import FormVehiculosPersonal from "./forms/Form";
//Basic
import CustomBtn from "../../../components/Tools/Basics/CustomBtn";
import TableMain from "../../../components/Tools/Tables/TableMain/TableMain";
import Divider from "../../../components/Tools/Basics/Divider";
import NoWrapContainer from "../../../components/Tools/Basics/NoWrapContainer";
//Modal
import { useModal } from "../../../hooks/useModal";
import ModalPlain from "../../../components/Modals/ModalPlain";
import ModalAlert from "../../../components/Modals/ModalAlert";

const VehiculosPersonal = () => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //useNavigate
  const navigate = useNavigate();
  //Modal Plain
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //States
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [showModalPlain, setShowModalPlain] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [loading, setLoading] = useState(false);

  //GET vehicles
  const getVehicles = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetPersonalVehicles);
      setVehicles(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Delete vehicle
  const handleSwitchActive = async (id) => {
    const merged = { nombre_entidad: "vehiculos_personal", id_entidad: id };
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.post(urlSwitchEntities, merged);
      setReFetch(!reFetch);
      handleSucces(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Confirmation delete
  const confirmDelete = (vehicle) => {
    if (
      window.confirm(`¿Confirmar eliminación del vehiculo: ${vehicle.patente}?`)
    )
      handleSwitchActive(vehicle.id);
  };

  //BTN Actions
  const handleCreate = () => {
    setSelectedVehicle(null);
    setShowModalPlain(true);
  };

  const handleEdit = async (v) => {
    setSelectedVehicle(v);
    setShowModalPlain(true);
  };

  const actionButton = (vehicle) => {
    return (
      <NoWrapContainer>
        <CustomBtn type={"edit_table"} onClick={() => handleEdit(vehicle)} />
        <CustomBtn
          type={"delete_table"}
          onClick={() => confirmDelete(vehicle)}
        />
        <CustomBtn
          type={"details_table"}
          onClick={() =>
            navigate(`/vehiculos/personal/detalles/${vehicle.id}`, {
              state: { vehicle },
            })
          }
        />
      </NoWrapContainer>
    );
  };

  //Data table
  const columns = [
    { field: "id", header: "Id" },
    { field: "patente", header: "Patente" },
    { field: "desc_vehiculo", header: "Desc." },
    { field: "sector", header: "Sector" },
    { field: "kilometros", header: "Km", sortable: true, formater: "km" },
    { body: actionButton },
  ];

  const buttonsTrips = [
    {
      type: "plus_table_header",
      text: " Vehiculo",
      click: () => handleCreate(),
    },
  ];

  useEffect(() => {
    getVehicles();
  }, [reFetch]);
  return (
    <div className="custom-container">
      <h2>
        <b>Vehiculos Personal Terminales y Servicios</b>
      </h2>
      <Divider />
      <div className="col-md-12">
        <TableMain
          data={vehicles}
          columns={columns}
          keyProp={"id"}
          loading={loading}
          title={"Vehiculos"}
          textFilter
          buttons={buttonsTrips}
        />
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          <FormVehiculosPersonal
            vehicle={selectedVehicle}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default VehiculosPersonal;
