import { useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
//FORMIK & YUP
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "../../../../components/Tools/Inputs/CustomInput";
//Excel export
import { informeExportExcel } from "./informeExportExcel";
//Funciones
import { dateFormat } from "../../../../functions/dateFormat";
//--Basic
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";

const InformeModal = ({ maq }) => {
  const [loading, setLoading] = useState(false);
  const [alertMsj, setAlertMsj] = useState(null);
  const initialValues = {
    fechaDesde: "",
    fechaHasta: "",
  };
  const validationSchema = Yup.object().shape({
    fechaDesde: Yup.string()
      .required("Campo obligatorio")
      .typeError("Campo obligatorio"),
    fechaHasta: Yup.string()
      .required("Campo obligatorio")
      .typeError("Campo obligatorio"),
  });
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const res = await axiosWithAuth.get(`/api/checklists/informe-chk-maq`, {
        params: {
          fechaDesde: values.fechaDesde,
          fechaHasta: values.fechaHasta,
          id_maquina: maq.id_maquina,
        },
      });
      if (res.data.length < 1) {
        const msj = `No existen checklist realizadas a la maquina ${maq.id_interno} en el periodo seleccionado`;
        return setAlertMsj(msj);
      }
      const fechas = {
        fecha_1: dateFormat("es", values.fechaDesde),
        fecha_2: dateFormat("es", values.fechaHasta),
      };
      informeExportExcel(res.data, fechas, maq.id_interno);
      setAlertMsj(null);
    } catch (error) {
      console.log(error);
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      setAlertMsj(auxError);
    } finally {
      setLoading(false);
    }
  };
  const Alert = () => {
    return (
      <div className="text-danger text-center">
        <i className="bi bi-exclamation-diamond fs-3 me-1" />
        <p>{alertMsj}</p>
      </div>
    );
  };
  return (
    <div>
      <p className="text-center">Generar informe de la maquina</p>
      <p className="text-center fs-5 fw-bold">{maq.id_interno}</p>
      <p className="text-center fw-light">{`${maq.tipo} | ${maq.marca} | ${maq.modelo}`}</p>
      {alertMsj ? <Alert /> : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <CustomInput
              name={"fechaDesde"}
              label={"Desde"}
              typeInput={"date"}
              loading={loading}
              className="me-1"
            />
            <CustomInput
              name={"fechaHasta"}
              label={"Hasta"}
              typeInput={"date"}
              loading={loading}
              className="me-1"
            />
            <div className="d-flex align-items-end">
              <ButtonSubmit
                text={"Generar Informe"}
                className={"btn-success"}
                loading={loading}
                isSubmitting={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InformeModal;
