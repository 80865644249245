import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import {
  urlGetLastCost,
  urlGetOperativosTipos,
} from "../../../../../api/URLs/operativos";
import { urlGetKmsLongDist } from "../../../../../api/URLs/viajes";
//HOOKS/FUNCTIONS
import { useModal } from "../../../../../hooks/useModal";
//FORMS
import FormOperativosTipos from "../../forms/FormOperativosTipos";
import FormKmsLongDist from "../../forms/FormKmsLongDist";
import FormCostos from "../../forms/FormCostos";
//COMPONENTS
//--Modal
import ModalPlain from "../../../../../components/Modals/ModalPlain";
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//-Modal-info
import HistorialCostos from "../../extra/HistorialCostos";
import HistorialLargaDist from "../../extra/HistorialLargaDist";
//--Table
import TableMain from "../../../../../components/Tools/Tables/TableMain/TableMain";
import Table from "../../../../../components/Tools/Tables/Table/Table";
import BoxContainer from "../../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import TableRows from "../../../../../components/Tools/Tables/TableRows/TableRows";
//--Basic
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";

const TableConfigs = () => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
  } = useModal();
  //states
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [kmsDist, setKmsDist] = useState([]);
  const [cost, setCost] = useState([]);
  //Modal State
  const [modalState, setModalState] = useState(null);
  //Modal Plain
  const handleCloseModalPlain = () => setShowModalPlain(false);
  const [showModalPlain, setShowModalPlain] = useState(false);
  //GET
  const getParallel = async () => {
    setLoading(true);
    const promise_array = [];
    promise_array.push(axiosWithAuth.get(urlGetOperativosTipos));
    promise_array.push(axiosWithAuth.get(urlGetKmsLongDist));
    promise_array.push(axiosWithAuth.get(urlGetLastCost));
    try {
      const api_responses = await Promise.all(promise_array);
      const [operativosTipos_response, kms_response, cost_response] =
        api_responses;
      setTypes(operativosTipos_response.data);
      setKmsDist(kms_response.data);
      setCost(cost_response.data[0]);
      setSelectedType(null);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //COLUMNS
  //Tipos
  const handleOperativoTypes = (v) => {
    if (v) {
      setSelectedType(v);
    } else {
      setSelectedType(null);
    }
    setModalState("tipo");
    setShowModalPlain(true);
  };
  const editButtonTypes = (v) => {
    return (
      <CustomBtn type={"edit_table"} onClick={() => handleOperativoTypes(v)} />
    );
  };
  const buttonsTypes = [
    {
      type: "plus_table_header",
      click: handleOperativoTypes,
      noData: true,
    },
  ];
  const columnsTypes = [
    { field: "desc_tipo", header: "Tipo Operativo" },
    { header: "Editar", body: editButtonTypes },
  ];
  //Larga distancia
  const handleChangeKms = () => {
    setModalState("kms");
    setShowModalPlain(true);
  };

  const handleHistoryKm = () => {
    setModalState("km-history");
    setShowModalPlain(true);
  };

  const buttonKms = [
    {
      type: "link_table",
      text: "Ver Historial",
      click: handleHistoryKm,
      noData: true,
    },
    {
      type: "plus_table_header",
      click: handleChangeKms,
      noData: true,
    },
  ];
  const colKms = [
    {
      first_title: "Min.",
      first_prop: "km_apartir",
      first_formater: "km",
      second_title: "Fecha Act.",
      second_prop: "fecha",
      second_formater: "date",
    },
  ];
  //Costos
  const handleChangeCost = () => {
    setModalState("cost");
    setShowModalPlain(true);
  };
  const handleHistoryCost = () => {
    setModalState("cost-history");
    setShowModalPlain(true);
  };
  const buttonCost = [
    {
      type: "link_table",
      text: "Ver Historial",
      click: handleHistoryCost,
      noData: true,
    },
    {
      type: "plus_table_header",
      click: handleChangeCost,
      noData: true,
    },
  ];
  const colCost = [
    {
      first_title: "Hora",
      first_prop: "hora_tys",
      first_formater: "priece",
      second_title: "Fecha. Act",
      second_prop: "fecha",
      second_formater: "date",
    },
    {
      first_title: "Km",
      first_prop: "km_camionero",
      first_formater: "priece",
    },
  ];
  useEffect(() => {
    getParallel();
  }, [reFetch]);

  return (
    <div className="row">
      <div className="col-md-6">
        <BoxContainer minSize={"md"} maxSize={"md"}>
          <Table
            data={types}
            columns={columnsTypes}
            keyProp={"id"}
            title={"Tipos de Operativos"}
            buttons={buttonsTypes}
            loading={loading}
            scrollSize={"md"}
          />
        </BoxContainer>
      </div>
      <div className="col-md-6">
        <BoxContainer>
          <TableRows
            data={kmsDist}
            rows={colKms}
            title={"Larga distancia"}
            loading={loading}
            buttons={buttonKms}
          />
        </BoxContainer>
        <BoxContainer>
          <TableRows
            data={cost}
            rows={cost ? colCost : []}
            title={"Costos"}
            loading={loading}
            buttons={buttonCost}
          />
        </BoxContainer>
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain}>
          {modalState === "tipo" ? (
            <FormOperativosTipos
              selectedType={selectedType}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          ) : modalState === "kms" ? (
            <FormKmsLongDist
              kmsDist={kmsDist}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          ) : modalState === "km-history" ? (
            <HistorialLargaDist />
          ) : modalState === "cost" ? (
            <FormCostos cost={cost} reFetch={reFetch} setReFetch={setReFetch} />
          ) : (
            <HistorialCostos />
          )}
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default TableConfigs;
