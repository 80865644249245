import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlGetDrivers } from "../../../../../api/URLs/choferes";
import { urlGetTrucks, urlGetTrailers } from "../../../../../api/URLs/vehiculos";
import { urlCretaePredefinedTeams } from "../../../../../api/URLs/viajes";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
import { useAlertModal } from "../../../../../hooks/useAlertModal";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../../../components/Tools/Tables/Table/Table";
//--Basic
import BoxContainer from "../../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";
import ButtonSubmit from "../../../../../components/Tools/Basics/BtnSubmit";
import CustomAlertMsj from "../../../../../components/Tools/Basics/CustomAlertMsj";

const FormAltaTeam = ({ reFetch, setReFetch }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [section, setSection] = useState(1);
  //--camiones
  const [trucks, setTrucks] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState(null);
  //--acoplados
  const [trailers, setTrailers] = useState([]);
  const [selectedTrailer, setSelectedTrailer] = useState(null);
  //--choferes
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  //GET camiones - acoplados - choferes
  const getParallel = async () => {
    setLoading(true);
    const promise_array = [];
    promise_array.push(await axiosWithAuth.get(urlGetDrivers));
    promise_array.push(await axiosWithAuth.get(urlGetTrucks));
    promise_array.push(await axiosWithAuth.get(urlGetTrailers));
    try {
      const api_responses = await Promise.all(promise_array);
      const [drivers_response, trucks_response, trailers_response] =
        api_responses;
      setDrivers(drivers_response.data);
      setTrucks(trucks_response.data);
      setTrailers(trailers_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
      setSelectedDriver(null);
      setSelectedTrailer(null);
      setSelectedTruck(null);
    }
  };
  //POST
  const handleSubmit = async () => {
    const merged = {
      id_camion: selectedTruck.id,
      id_acoplado: selectedTrailer.id,
      id_chofer: selectedDriver.id_chofer,
    };
    try {
      setSendLoading(true);
      const api_response = await axiosWithAuth.post(urlCretaePredefinedTeams, merged)
      handleAlertSuccess(api_response)
      setReFetch(!reFetch)
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  //HANDLE TABLES
  const handleSections = (selector) => {
    setSection(
      selector === 1 ? 1 : selector === 2 ? 2 : selector === 3 ? 3 : 4
    );
  };
  const handleSelect = (p) => {
    section === 1
      ? setSelectedTruck(p)
      : section === 2
      ? setSelectedTrailer(p)
      : setSelectedDriver(p);
  };
  //BOTONES
  const btnSelect = (v) => {
    return (
      <CustomBtn
        type={"link_table"}
        text={"Seleccionar"}
        onClick={() => handleSelect(v)}
      />
    );
  };
  //COLUMNS
  //--Trucks
  const columnsVehicles = [
    { header: "Patente", field: "patente" },
    {
      header: "Prop.",
      field: "es_propio",
      formater: "boolean_color",
      sortable: true,
    },
    { header: "Movil", field: "numMovil" },
    { body: btnSelect },
  ];
  //--Drivers
  const columnsDrivers = [
    { header: "Nombre", field: "apelnomb" },
    { header: "Empresa", field: "nomb_empresa" },
    { body: btnSelect },
  ];
  useEffect(() => {
    getParallel();
  }, []);
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      <section className="py-1 d-md-flex">
        <h4>Conformar Equipo</h4>
        <div className="ms-auto">
          <CustomBtn
            onClick={() => handleSections(1)}
            btnType={`${
              section === 1 ? "success" : "outline-success"
            } btn-sm me-1`}
            text={"Camión"}
          />
          <CustomBtn
            onClick={() => handleSections(2)}
            btnType={`${
              section === 2 ? "success" : "outline-success"
            } btn-sm me-1`}
            text={"Batea"}
          />
          <CustomBtn
            onClick={() => handleSections(3)}
            btnType={`${
              section === 3 ? "success" : "outline-success"
            } btn-sm me-1`}
            text={"Chofer"}
          />
          <CustomBtn
            onClick={() => handleSections(4)}
            btnType={"success btn-sm"}
            text={"Crear Equipo"}
            disabled={
              !selectedTruck || !selectedTrailer || !selectedDriver
                ? true
                : false
            }
          />
        </div>
      </section>
      <section>
        <BoxContainer>
          <div className="d-md-flex justify-content-evenly">
            <p>
              <strong>Camión: </strong>
              {selectedTruck ? selectedTruck.patente : "-"}
            </p>
            <p>
              <strong>Batea: </strong>
              {selectedTrailer ? selectedTrailer.patente : "-"}
            </p>
            <p>
              <strong>Chofer: </strong>
              {selectedDriver ? selectedDriver.apelnomb : "-"}
            </p>
          </div>
        </BoxContainer>
      </section>
      <section className="mt-3">
        {section === 4 ? (
          <div className="col-md-6 m-auto">
            {alertState === 2 ? (
              <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
            ) : null}
            <ButtonSubmit
              variant={"primary"}
              text={"Confirmar Equipo"}
              loading={sendLoading}
              onClick={() => handleSubmit()}
            />
          </div>
        ) : (
          <BoxContainer minSize={"md"} maxSize={"md"}>
            <Table
              data={section === 1 ? trucks : section === 2 ? trailers : drivers}
              columns={section === 3 ? columnsDrivers : columnsVehicles}
              keyProp={section === 3 ? "id_chofer" : "id"}
              title={
                section === 1
                  ? "Camiones"
                  : section === 2
                  ? "Bateas"
                  : "Choferes"
              }
              textFilter
              loading={loading}
              scrollSize={"md"}
            />
          </BoxContainer>
        )}
      </section>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormAltaTeam;
