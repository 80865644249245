import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlGetMaquinas } from "../../../../../api/URLs/mantenimiento";
//ROUTER
import { useNavigate } from "react-router-dom";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//--Basic
import CustomCard from "../extra/CustomCard";
import CenterSpinner from "../../../../../components/Tools/Basics/Spinner";
import CustomDropdown from "../../../../../components/Tools/Inputs/CustomDropdown";
//--Img
import auto from "../../../../../assets/Autoelevadora.png";
import pala from "../../../../../assets/pala.png";
import retro from "../../../../../assets/retro.png";

const CardsMaquinas = () => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //useNavigate
  const navigate = useNavigate();
  //States
  const [loading, setLoading] = useState(false);
  const [maqs, setMaqs] = useState([]);
  const [maqsTypes, setMaqsTypes] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  //FILTER
  const [search, setSearch] = useState("");
  const filteredData = filtered.filter((d) => {
    const lowerCasedValue = search.toLowerCase();
    return Object.keys(d).some(() =>
      d.id_interno?.toString()?.toLowerCase()?.includes(lowerCasedValue)
    );
  });
  //Handle Navigate
  const handleNavigates = (item) => {
    if (!item.id_checklist) {
      handleError(
        `La maquina "${item.id_interno}" aún no tiene una checklist asignada`
      );
    } else {
      navigate(`/checklist/maquina/${item.id_checklist}`, {
        state: { item },
      });
    }
  };
  //Components
  const Desc = ({ item }) => {
    return (
      <div className="w-100">
        <p className="fw-semibold fs-5">
          {item.id_interno}
          <i
            className={`bi bi-${
              item.id_checklist
                ? "check-circle-fill text-success"
                : "x-circle-fill text-danger"
            } ms-1 opacity-50`}
          />
        </p>
        <p className="text-end fw-light">{`${item.marca} | ${item.modelo}`}</p>
      </div>
    );
  };
  const NoMaq = () => {
    return (
      <div className="text-center mt-3">
        <i className="bi bi-exclamation-diamond fs-1 text-danger" />
        <p className="fw-semibold">No se encontraron máquinas que coincidan</p>
      </div>
    );
  };
  useEffect(() => {
    const getMaqs = async () => {
      try {
        setLoading(true);
        const promise_array = [];
        promise_array.push(axiosWithAuth.get(urlGetMaquinas));
        promise_array.push(axiosWithAuth.get("/api/maquinas-tipos"));
        const api_responses = await Promise.all(promise_array);
        const [maqs_response, maqsTypes_response] = api_responses;
        setMaqs(maqs_response.data);
        const tipos = [{ id: null, tipo: "Todas" }, ...maqsTypes_response.data];
        setMaqsTypes(tipos);
      } catch (error) {
        const auxError = error.response.data.msj
          ? error.response.data.msj
          : "Error del servidor";
        handleError(auxError);
      } finally {
        setLoading(false);
        setSelectedType(null);
      }
    };
    getMaqs();
  }, []);
  useEffect(() => {
    if (!selectedType) {
      setFiltered(maqs);
    } else {
      const filtered = maqs.filter((item) => item.id_tipo === selectedType);
      setFiltered(filtered);
    }
  }, [maqs, selectedType]);

  if (loading) return <CenterSpinner />;
  return (
    <div>
      <div className="d-flex col-10 col-md-7 col-lg-5 offset-1 offset-md-3">
        <input
          className={"form-control form-control-lg mx-2"}
          type="text"
          placeholder="Buscar.."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <div className="mx-2">
          <CustomDropdown
            options={maqsTypes}
            onSelect={(t) => setSelectedType(t.id)}
            displayProperty={"tipo"}
            btnName={"Todas"}
            size={"lg"}
            noFilter
          />
        </div>
      </div>
      <div className="d-flex justify-content-center flex-wrap">
        {filteredData.length === 0 ? (
          <NoMaq />
        ) : (
          filteredData.map((item) => (
            <div key={item.id_maquina}>
              <CustomCard
                img={
                  item.id_tipo === 1 ? auto : item.id_tipo === 2 ? pala : retro
                }
                desc={<Desc item={item} />}
                onClick={() => handleNavigates(item)}
              />
            </div>
          ))
        )}
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default CardsMaquinas;
