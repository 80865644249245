import { useLocation } from "react-router-dom";
import Divider from "../../../components/Tools/Basics/Divider";
import TableMaquinaChkl from "./components/TableMaquinaChkl";

const MaquinaChecklists = () => {
  //Location
  const location = useLocation();
  const maquina = location.state.maquina;
  return (
    <div className="custom-container">
      <h2> <b>Maquina | {maquina.id_interno}</b></h2>
      <Divider/>
      <TableMaquinaChkl maquina={maquina}/>
    </div>
  )
}

export default MaquinaChecklists
