import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlEventos,
  urlCorreos,
  urlCorreoEventoLista,
} from "../../../../api/URLs/correos";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
import { useAlertModal } from "../../../../hooks/useAlertModal";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
//--Basic
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";

const FormVincCE = ({ reFetch, setReFetch }) => {
  const [eventos, setEventos] = useState([]);
  const [correos, setCorreos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [evento, setEvento] = useState(null);
  const [correo, setCorreo] = useState(null);
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //GET
  const getParallel = async () => {
    setLoading(true);
    const promise_array = [];
    promise_array.push(axiosWithAuth.get(urlEventos));
    promise_array.push(axiosWithAuth.get(urlCorreos));
    try {
      const api_responses = await Promise.all(promise_array);
      const [eventos_response, correos_response] = api_responses;
      setEventos(eventos_response.data);
      setCorreos(correos_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //POST
  const handleSubmit = async () => {
    try {
      const merged = {
        id_evento: evento,
        id_correo: correo,
      };
      console.log(merged);
      setLoading(true);
      const api_response = await axiosWithAuth.post(
        urlCorreoEventoLista,
        merged
      );
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getParallel();
  }, []);
  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <div className="row">
        <div className="col-6">
          <h5 className="text-center">Seleccionar Evento</h5>
          <div className="list-group p-2 border tyres-free-scroll">
            {eventos.map((e) => {
              return (
                <button
                  key={e.id}
                  type="button"
                  onClick={() => setEvento(e.id)}
                  className={`list-group-item list-group-item-action py-1 ${
                    e.id === evento ? "active" : ""
                  }`}
                >
                  {e.evento}
                </button>
              );
            })}
          </div>
        </div>
        <div className="col-6">
          <h5 className="text-center">Seleccionar Correo</h5>
          <div className="list-group p-2 border tyres-free-scroll">
            {correos.map((c) => {
              return (
                <button
                  key={c.id}
                  type="button"
                  onClick={() => setCorreo(c.id)}
                  className={`list-group-item list-group-item-action py-1 ${
                    c.id === correo ? "active" : ""
                  }`}
                >
                  {c.mail}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center mt-2">
        <CustomBtn
          btnType={"primary"}
          text={"Confirmar"}
          disabled={!evento || !correo ? true : false}
          onClick={() => handleSubmit()}
        />
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormVincCE;
