//Router
import { useLocation } from "react-router-dom";
//NavTabs
import NavTabs from "../../../components/NavBars/NavTabs/NavTabs";
//Components
import TableDatosChofer from "./components/details/TableDatosChofer";
import TableCashMovs from "./components/details/TableCashMovs";

const ChoferesDetalles = () => {
  //Location
  const location = useLocation();
  const driver = location.state.driver;
  //NavTabs
  const sections = [
    {
      name: "Datos y Vencimientos",
      component: <TableDatosChofer driver={driver} />,
    },
    { name: "Movimientos", component: <TableCashMovs driver={driver} /> },
  ];
  return (
    <div className="custom-container">
      <h2>
        <b>Chofer | {driver.apelnomb}</b>
      </h2>
      <NavTabs sections={sections} />
    </div>
  );
};

export default ChoferesDetalles;
