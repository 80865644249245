import { useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlCreateMaquinistas,
  urlEditMaquinistas,
} from "../../../../api/URLs/mantenimiento";
//HOOKS
import { useAlertModal } from "../../../../hooks/useAlertModal";
//FORMIK
import { Formik, Form } from "formik";
//COMPONENTS
//--Modal
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
//--Basic
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";
import CustomInput from "../../../../components/Tools/Inputs/CustomInput";
import CustomCheckbox from "../../../../components/Tools/Inputs/CustomCheckbox";

const FormMaquinista = ({ selectedMaq, reFetch, setReFetch }) => {
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //INITIAL VALUES
  let newValues, updateValues;
  if (selectedMaq) {
    updateValues = {
      id: selectedMaq.id,
      nombre: selectedMaq.nombre ? selectedMaq.nombre : "",
      psw_checklist: selectedMaq.psw_checklist ? selectedMaq.psw_checklist : "",
      cierra_chk: selectedMaq.cierra_chk ? selectedMaq.cierra_chk : false,
    };
  } else {
    newValues = {
      nombre: "",
      psw_checklist: "",
      cierra_chk: false,
    };
  }
  const initialValues = selectedMaq ? updateValues : newValues;
  //POST - PUT
  const handleSubmit = async (values) => {
    try {
      let api_response;
      if (!selectedMaq) {
        api_response = await axiosWithAuth.post(urlCreateMaquinistas, values);
      } else {
        api_response = await axiosWithAuth.put(urlEditMaquinistas, values);
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    }
  };
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <FormTitle prop={selectedMaq} text={"Maquinista"} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <CustomInput
              name={"nombre"}
              label={"Nombre de Maquinista"}
              placeholder={"Nombre"}
              loading={isSubmitting}
            />
            <CustomInput
              name={"psw_checklist"}
              typeInput={"password"}
              label={"Constraseña checklist"}
              placeholder={"Contraseña"}
              loading={isSubmitting}
            />
            <CustomCheckbox
              name={"cierra_chk"}
              text={"¿Permiso para cerrar checklist?"}
              loading={isSubmitting}
            />
            <ButtonSubmit prop={selectedMaq} loading={isSubmitting} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormMaquinista;
