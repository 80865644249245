import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../api/axiosConfig";
import {
  urlGetOperativoTrips,
  urlGetOperativoNomina,
} from "../../../api/URLs/operativos";
//ROUTER
import { useLocation } from "react-router-dom";
//HOOKS
import { useModal } from "../../../hooks/useModal";
import { exportCleanExcel } from "../../../functions/exportCleanExcel";
//FORMS
import FormViajes from "./forms/FormViajes";
//COMPONENTS
import TableTripsData from "./components/details/TableTripsData";
import TableTripsTeams from "./components/details/TableTripsTeams";
//--Modal
import ModalAlert from "../../../components/Modals/ModalAlert";
import ModalPlain from "../../../components/Modals/ModalPlain";
//--Basic
import CustomBtn from "../../../components/Tools/Basics/CustomBtn";
import OperativoHeaderDetails from "./extra/OperativoHeaderDetails";
import CenterSpinner from "../../../components/Tools/Basics/Spinner";

const OperativosViajes = () => {
  const location = useLocation();
  const operativo = location.state.operativo;
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //States
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  //Modal Plain
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET VIAJES
  const getTrips = async () => {
    setLoading(true);
    try {
      const trips_response = await axiosWithAuth.get(
        `${urlGetOperativoTrips}/${operativo.id}`
      );
      setTrips(trips_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //EXPORT NOMINA
  const exportNomina = async () => {
    try {
      const api_response = await axiosWithAuth.get(urlGetOperativoNomina, {
        params: {
          id_operativo: operativo.id,
        },
      });
      const data = api_response.data;
      const fileName = `Nomina - ${operativo.nombre}`;
      exportCleanExcel(data, fileName);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    }
  };
  //DISABLED ESTADO 10
  const disabledState = () => {
    if (operativo.id_estado === 10) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    getTrips();
  }, [reFetch]);
  return (
    <div className="custom-container">
      <div className="d-sm-flex justify-content-between align-items-center mb-4">
        <h2>
          <b>Operativo | {operativo.nombre}</b>
        </h2>
        <div>
          <CustomBtn
            btnType={"success me-1"}
            text={" Nomina"}
            icon={"bi bi-download"}
            onClick={() => exportNomina()}
          />
          <CustomBtn
            btnType={"success"}
            text={"Crear Nuevo Viaje"}
            onClick={() => setShowModalPlain(true)}
            disabled={disabledState()}
          />
        </div>
      </div>
      <div>
        {trips.map((t) => (
          <div key={t.id} className="border-top border-2">
            <OperativoHeaderDetails
              title={`| Viaje Numero ${t.id} |`}
              details={`| ${t.ubi_origen} - ${t.ubi_destino} - ${t.desc_cliente} |`}
              loading={loading}
            />
            <div className="row pb-2">
              <TableTripsData
                data={t}
                operativo={operativo}
                loading={loading}
                reFetch={reFetch}
                setReFetch={setReFetch}
              />
              <TableTripsTeams
                trip={t}
                operativo={operativo}
                reFetch={reFetch}
                setReFetch={setReFetch}
              />
            </div>
          </div>
        ))}
        {trips.length === 0 ? (
          !loading ? (
            <h4 className="text-center">
              Aún no existen viajes en este Operativo
            </h4>
          ) : (
            <CenterSpinner />
          )
        ) : null}
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          <FormViajes
            operativo={operativo}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default OperativosViajes;
