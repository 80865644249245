import { useState } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import {
  urlCreateChecklists,
  urlEditChecklists,
} from "../../../../../api/URLs/mantenimiento";
//HOOKS
import { useAlertModal } from "../../../../../hooks/useAlertModal";
//FORMIK
import { Formik, Form } from "formik";
//COMPONENTS
//--Modal
import CustomAlertMsj from "../../../../../components/Tools/Basics/CustomAlertMsj";
//--Basic
import ButtonSubmit from "../../../../../components/Tools/Basics/BtnSubmit";
import FormTitle from "../../../../../components/Tools/Basics/FormTitle";
import CustomInput from "../../../../../components/Tools/Inputs/CustomInput";

const FormCabeceras = ({ selectedChecklist, reFetch, setReFetch }) => {
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [sendLoading, setSendLoading] = useState(false);
  //INITIAL VALUES
  let newValues, updateValues;
  if (selectedChecklist) {
    updateValues = {
      id: selectedChecklist.id,
      nombre: selectedChecklist.nombre,
    };
  } else {
    newValues = {
      nombre: "",
    };
  }
  const initialValues = selectedChecklist ? updateValues : newValues;
  //POST - PUT
  const handleSubmit = async (values) => {
    try {
      setSendLoading(true);
      let api_response;
      if (!selectedChecklist) {
        api_response = await axiosWithAuth.post(urlCreateChecklists, values);
      } else {
        api_response = await axiosWithAuth.put(urlEditChecklists, values);
      }
      handleAlertSuccess(api_response);
      setReFetch(!reFetch);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : (
        <>
          <FormTitle prop={selectedChecklist} text={"Checklist"} />
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <CustomInput
                  name={"nombre"}
                  label={"Nombre Cabecera"}
                  placeholder={"Nombre de cabecera"}
                  loading={sendLoading}
                />
                <ButtonSubmit
                  prop={selectedChecklist}
                  isSubmitting={isSubmitting}
                  loading={sendLoading}
                />
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default FormCabeceras;
