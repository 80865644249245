export const dateFormat = (language, value) => {
  if (!value) {
    const fechaActual = new Date();
    const anio = fechaActual.getFullYear();
    const mes = String(fechaActual.getMonth() + 1).padStart(2, "0"); // +1 porque los meses se cuentan desde 0
    const dia = String(fechaActual.getDate()).padStart(2, "0");
    return language === "es"
      ? `${dia}-${mes}-${anio}`
      : `${anio}-${mes}-${dia}`;
  } else {
    const [anio, mes, dia] = value.split("-");
    return language === "es"
      ? `${dia}-${mes}-${anio}`
      : `${anio}-${mes}-${dia}`;
  }
};
