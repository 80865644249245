import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../api/axiosConfig";
import { urlGetChecklistDetalle } from "../../../api/URLs/mantenimiento";
//HOOKS
import { useModal } from "../../../hooks/useModal";
//--Modal
import ModalAlert from "../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../components/Tools/Tables/Table/Table";
import BoxContainer from "../../../components/Tools/Cards/BoxContainer/BoxContainer";

const DetailsChecklist = ({ id_cheklist }) => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  //GET
  const getItems = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetChecklistDetalle, {
        params: {
          id_resultado_chk: id_cheklist,
        },
      });
      setItems(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //COLUMNAS
  const columns = [
    { header: "Item", field: "placeholder" },
    {
      header: "Valor",
      field: "valor",
      sortable: true,
      formater: "boolean_color",
    },
    { header: "Obs", field: "obs" },
  ];
  useEffect(() => {
    getItems();
  }, []);
  return (
    <div>
      <BoxContainer minSize={"xl"} maxSize={"xl"}>
        <Table
          data={items}
          title={"Listado de items"}
          columns={columns}
          loading={loading}
          scrollSize={"xl"}
        />
      </BoxContainer>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default DetailsChecklist;
