import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlGetChecklists_x_dia } from "../../../../api/URLs/mantenimiento";
//ROUTER
import { useNavigate } from "react-router-dom";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
import { dateFormat } from "../../../../functions/dateFormat";
import { dateSQLformat } from "../../../../functions/dateSQLformat";
//export excel
import { exportExcelChklPorDia } from "./exportExcelChklPorDia";
//FOMRIK
import { Formik, Form } from "formik";
import CustomInput from "../../../../components/Tools/Inputs/CustomInput";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
//--Table
import TableMain2 from "../../../../components/Tools/Tables/TableMain2/TableMain2";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";

const TableChecklistPorDia = () => {
  const navigate = useNavigate();
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [data, setData] = useState([]);
  const [callEndpoint, setCallEndpoint] = useState(false);
  const [datesValues, setDatesValues] = useState(null);
  const initialValues = {
    fecha_a: "",
    fecha_b: "",
  };
  const handleSubmit = (values) => {
    const emptyInputs = values.fecha_a === "" || values.fecha_b === "";
    if (emptyInputs) {
      handleError("Debe ingresar ambas fechas para filtrar");
    } else {
      setDatesValues(values);
      setCallEndpoint(true);
    }
  };
  const exportToExcel = () => {
    try {
      setExportLoading(true);
      const dateFrom = dateSQLformat(datesValues?.fecha_a);
      const dateTo = dateSQLformat(datesValues?.fecha_b);
      exportExcelChklPorDia(data, dateFrom, dateTo);
    } finally {
      setExportLoading(false);
    }
  };
  const rowClass = (chkl) => {
    return {
      "bg-danger bg-opacity-10": chkl.chk_cerrada === false,
    };
  };
  //COLUMNAS
  const state = (chkl) => {
    return chkl.chk_cerrada ? (
      "Cerrada"
    ) : (
      <span className="text-danger fw-semibold">Abierta</span>
    );
  };
  const horas_uso = (chkl) => {
    return chkl.horas_uso_fin ? chkl.horas_uso_fin : chkl.horas_uso;
  };
  const fecha = (chkl) => {
    return dateSQLformat(chkl.fecha, "addTime");
  };
  const actions = (chkl) => {
    return (
      <CustomBtn
        type={"link_table"}
        text={"Detalles"}
        onClick={() =>
          navigate(
            `/mantenimiento/checklists/detalle/${chkl.id_resultado_chk}`,
            {
              state: {
                id_checklist: chkl.id_resultado_chk,
                id_maquina: chkl.id_maquina,
              },
            }
          )
        }
      />
    );
  };
  const headerButtons = [
    {
      type: "download_table_header",
      text: exportLoading ? " Cargando" : " Excel",
      click: exportToExcel,
      noData: true,
      disabled: data.length < 1 || exportLoading,
    },
  ];
  const columns = [
    { header: "Id", field: "id_resultado_chk" },
    { header: "Maquinista", field: "maquinista" },
    { header: "Maquina", field: "interno_maq" },
    { header: "Tipo Maq", field: "tipo_maq" },
    { header: "Marca", field: "marca" },
    { header: "Modelo", field: "modelo" },
    { header: "Fecha", field: "fecha", body: fecha, sortable: true },
    { header: "Hs uso", body: horas_uso },
    { header: "Estado", field: "chk_cerrada", body: state, sortable: true },
    { body: actions },
  ];
  useEffect(() => {
    const getChecklists = async () => {
      try {
        setLoading(true);
        const api_response = await axiosWithAuth.get(urlGetChecklists_x_dia, {
          params: {
            fechaDesde: dateFormat("en", datesValues.fecha_a),
            fechaHasta: dateFormat("en", datesValues.fecha_b),
          },
        });
        setData(api_response.data);
        setCallEndpoint(false);
      } catch (error) {
        const auxError = error.response.data.msj
          ? error.response.data.msj
          : "Error del servidor";
        handleError(auxError);
      } finally {
        setLoading(false);
      }
    };
    if (callEndpoint) {
      getChecklists();
    }
  }, [callEndpoint]);
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form className="d-md-flex d-block justify-content-start mb-3">
            <CustomInput
              name={"fecha_a"}
              label={"Desde"}
              typeInput={"date"}
              className="me-1"
            />
            <CustomInput
              name={"fecha_b"}
              label={"Hasta"}
              typeInput={"date"}
              className="me-1"
            />
            <div className="d-flex align-items-end">
              <ButtonSubmit text={"Filtrar"} className={"btn-success"} />
            </div>
          </Form>
        )}
      </Formik>
      <TableMain2
        data={data}
        title={"Checklist"}
        columns={columns}
        loading={loading}
        buttons={headerButtons}
        textFilter
        rowClass={rowClass}
        sortField={"fecha"}
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default TableChecklistPorDia;
