import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../api/axiosConfig";
import { urlGetChecklistDetalle } from "../../../api/URLs/mantenimiento";
//ROUTER
import { useSearchParams } from "react-router-dom";
//HOOKS
import { useModal } from "../../../hooks/useModal";
//--Modal
import ModalAlert from "../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../components/Tools/Tables/Table/Table";
import BoxContainer from "../../../components/Tools/Cards/BoxContainer/BoxContainer";

const CompareChecklists = () => {
  //Router
  const [searchParams] = useSearchParams();
  const idChkl1 = searchParams.get("idChkl1");
  const idChkl2 = searchParams.get("idChkl2");
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [chkl_A, setChkl_A] = useState([]);
  const [chkl_B, setChkl_B] = useState([]);
  //GET
  const getParallel = async () => {
    setLoading(true);
    const promise_array = [];
    promise_array.push(
      await axiosWithAuth.get(urlGetChecklistDetalle, {
        params: {
          id_resultado_chk: idChkl1,
        },
      })
    );
    promise_array.push(
      await axiosWithAuth.get(urlGetChecklistDetalle, {
        params: {
          id_resultado_chk: idChkl2,
        },
      })
    );
    try {
      const api_responses = await Promise.all(promise_array);
      const [chkl_A_response, chkl_B_response] = api_responses;
      setChkl_A(chkl_A_response.data);
      const chkl_B_mod = compareValues(
        chkl_A_response.data,
        chkl_B_response.data
      );
      setChkl_B(chkl_B_mod);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const compareValues = (arrA, arrB) => {
    const newArrB = [...arrB];
    newArrB.forEach((objB) => {
      const objA = arrA.find((a) => a.nombre === objB.nombre);
      if (objA && objA.valor !== objB.valor) {
        objB.difference = true;
      }
    });
    return newArrB;
  };
  //COLUMNAS
  const checkbox = (item) => {
    return (
      <input type="checkbox" checked={item.valor === "1"} disabled={true} />
    );
  };
  const difference = (item) => {
    return (
      <span className={item.difference ? "text-danger" : null}>
        {item.placeholder}
      </span>
    );
  };
  const columns_A = [
    { header: "Item", field: "placeholder" },
    { header: "Valor", field: "valor", body: checkbox, sortable: true },
    { header: "Obs", field: "obs" },
  ];
  const columns_B = [
    { header: "Item", field: "placeholder", body: difference },
    { header: "Valor", field: "valor", body: checkbox, sortable: true },
    { header: "Obs", field: "obs" },
  ];
  useEffect(() => {
    getParallel();
  }, []);
  return (
    <div className="custom-container row">
      <div className="col-lg-6">
        <BoxContainer minSize={"xl"} maxSize={"xl"}>
          <Table
            data={chkl_A}
            title={`Checklist ${idChkl1}`}
            columns={columns_A}
            loading={loading}
            scrollSize={"xl"}
            textFilter
          />
        </BoxContainer>
      </div>
      <div className="col-lg-6">
        <BoxContainer minSize={"xl"} maxSize={"xl"}>
          <Table
            data={chkl_B}
            title={`Checklist ${idChkl2}`}
            columns={columns_B}
            loading={loading}
            scrollSize={"xl"}
            textFilter
          />
        </BoxContainer>
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default CompareChecklists;
