import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlGetItems_x_Checklist } from "../../../../../api/URLs/mantenimiento";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
//FORMIK & YUP
import { Formik, Form } from "formik";
//Forms
import ChecklistForm from "./ChecklistForm";
//--Basic
import PlainModal from "../../../../../components/Modals/ModalPlain";
import ModalAlert from "../../../../../components/Modals/ModalAlert";
import ButtonSubmit from "../../../../../components/Tools/Basics/BtnSubmit";
import CustomInput from "../../../../../components/Tools/Inputs/CustomInput";
import CenterSpinner from "../../../../../components/Tools/Basics/Spinner";
import CustomCard from "../extra/CustomCard";
import ChecklistInput from "../extra/ChecklistInput";
//--Img
import auto from "../../../../../assets/Autoelevadora.png";
import pala from "../../../../../assets/pala.png";
import retro from "../../../../../assets/retro.png";

const ChecklistValues = ({ maquina }) => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [campos, setCampos] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [merged, setMerged] = useState(null);
  const [noFooter, setNoFooter] = useState(null);
  //--Modal
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET
  const getCampos = async () => {
    setMerged(null);
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetItems_x_Checklist, {
        params: {
          id_checklist: maquina.id_checklist,
          accion: "A",
        },
      });
      setCampos(api_response.data);
      //Creamos un objeto nuevo para definir las initialValues con la prop id_item
      const initialValues = api_response.data.reduce((objeto, item) => {
        const { id_item } = item;
        objeto[id_item] = {
          check: false,
          obs: "",
          nombre: item.item_nombre,
          placeholder: item.item_placeholder,
        };
        return objeto;
      }, {});
      setInitialValues(initialValues);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //FUNCION VALIDAR CHECKLIST
  const validationSchema = (data) => {
    const objToArr = Object.values(data);
    return objToArr.some(
      (item) => item.check === false && (!item.obs || item.obs.trim() === "")
    );
  };
  //POST
  const handleSubmit = (values) => {
    const validation = validationSchema(values);
    if (validation) {
      const msj =
        "En todo ítem sin marcar es obligatorio llenar el campo observaciones.";
      handleError(msj);
    } else {
      const formatValues = Object.keys(values).map((key) => {
        const item = values[key];
        return {
          id_item_chkl: key,
          value: item.check,
          obs: item.obs,
          nombre: item.nombre,
          placeholder: item.placeholder,
        };
      });
      setMerged(formatValues);
      setShowModalPlain(true);
    }
  };
  const Desc = ({ item }) => {
    return (
      <div className="w-100">
        <p className="fw-semibold fs-5">{item.id_interno}</p>
        <p className="text-end fw-light">{`${item.marca} | ${item.modelo}`}</p>
      </div>
    );
  };
  useEffect(() => {
    getCampos();
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-lg-9">
          {loading ? (
            <CenterSpinner />
          ) : !maquina.id_checklist ? (
            <h4 className="text-center fw-bold mb-3">{`La maquina ${maquina.id_interno} no tiene una checklist asignada`}</h4>
          ) : campos.length === 0 ? (
            <h4 className="text-center fw-bold mb-3">{`La checklist "${maquina.nombre_checklist}" no tiene items asignados`}</h4>
          ) : (
            <>
              <h4 className="fw-bold mb-3">{`${maquina.nombre_checklist} - ${maquina.id_interno}`}</h4>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                {() => (
                  <Form>
                    <div className="d-flex flex-wrap justify-content-start">
                      {campos.map((item, index) => (
                        <div key={index} className="col-lg-6 col-12">
                          <ChecklistInput
                            name={`${item.id_item}.check`}
                            text={item.item_placeholder}
                            size={"lg"}
                          >
                            <CustomInput
                              name={`${item.id_item}.obs`}
                              placeholder={"Observaciones"}
                              className={"me-5 mt-1"}
                            />
                          </ChecklistInput>
                        </div>
                      ))}
                    </div>
                    <div className="col-lg-5 mb-3">
                      <ButtonSubmit variant={"primary"} text={"Confirmar"} />
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
        <div className="col-lg-3 d-flex justify-content-center">
          <div>
            <h4 className="text-center fw-bold mb-3">{maquina.tipo}</h4>
            <CustomCard
              img={
                maquina.id_tipo === 1
                  ? auto
                  : maquina.id_tipo === 2
                  ? pala
                  : retro
              }
              desc={<Desc item={maquina} />}
              noHover
            />
          </div>
        </div>
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <PlainModal
          show={true}
          handleClose={handleCloseModalPlain}
          noFooter={noFooter}
        >
          <ChecklistForm
            valuesChecklist={merged}
            maquina={maquina}
            setNoFooter={setNoFooter}
            cerrar={null}
          />
        </PlainModal>
      ) : null}
    </div>
  );
};

export default ChecklistValues;
