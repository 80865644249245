//Styles
import "./TableRows.css";
//Components
import CenterSpinner from "../../Basics/Spinner";
import CustomBtn from "../../Basics/CustomBtn";
import { cellFormater } from "../../../../functions/cellFormater";

const TableRows = ({ title, buttons, rows, data, loading, scrollSize }) => {
  const handleScroll = (size) => {
    switch (size) {
      case "sm":
        return "small";
      case "md":
        return "medium";
      case "lg":
        return "large";
      case "xl":
        return "large-xl";
      default:
        return "auto";
    }
  };
  return (
    <div className="mb-2">
      {!loading ? (
        <>
          <div className="d-sm-flex justify-content-between align-items-end mb-0 pb-1 header-details-box">
            <p className="fw-bold">{title}</p>
            <div className="d-flex">
              {buttons
                ? buttons.map((b, index) => (
                    <div key={index}>
                      <CustomBtn
                        onClick={() => b.click(b.noData ? null : data)}
                        type={b.type}
                        btnType={b.styles}
                        icon={b.icon}
                        text={b.text}
                        disabled={b.disabled}
                      />
                    </div>
                  ))
                : null}
            </div>
          </div>
          <div className={`table-row-scroll-box-${handleScroll(scrollSize)}`}>
            <table className="table-details custom-striped w-100">
              <tbody>
                {rows ? (
                  rows.map((row, index) => (
                    <tr key={index} className="border-bottom">
                      <td className="col-md-2">{row.first_title}</td>
                      <td className="col-md-4 fw-bold">
                        {row.first_formater
                          ? cellFormater(
                              data[row.first_prop],
                              row.first_formater
                            )
                          : row.first_body
                          ? row.first_body(data[row.first_prop])
                          : data[row.first_prop]}
                      </td>
                      <td className="col-md-2">{row.second_title}</td>
                      <td className="col-md-4 fw-bold">
                        {row.second_formater
                          ? cellFormater(
                              data[row.second_prop],
                              row.second_formater
                            )
                          : row.second_body
                          ? row.second_body(data[row.second_prop])
                          : data[row.second_prop]}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Algo salio mal</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <CenterSpinner />
      )}
    </div>
  );
};

export default TableRows;
