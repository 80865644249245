import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import {
  urlGetRoutesNotAsigned,
  urlSwitchUserRoutes,
} from "../../../../../api/URLs/usuarios";
//Modal
import { useModal } from "../../../../../hooks/useModal";
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//Components
import TableMain from "../../../../../components/Tools/Tables/TableMain/TableMain";
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";

const TableRoutesNotAsigned = () => {
  const location = useLocation();
  const user = location.state.user;

  //States
  const [loading, setLoading] = useState(false);
  const [userRoutes, setUserRoutes] = useState([]);
  const [reFetch, setReFetch] = useState(false);

  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();

  //Get data
  const getUserRoutes = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetRoutesNotAsigned, {
        params: {
          id_usuario: user.id,
        },
      });
      setUserRoutes(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Add route to user
  const addRoute = async (route, user) => {
    const merged = { accion: "A", id_user: user.id, id_route: route.id_ruta };
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.post(
        urlSwitchUserRoutes,
        merged
      );
      setReFetch(!reFetch);
      handleSucces(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Confirmation delete
  const confirmAdd = (route) => {
    if (
      window.confirm(
        `¿Vincular ruta: ${route.route_descip} - '${route.route_path}' al usuario ${user.nombre}?`
      )
    )
      addRoute(route, user);
  };

  //BUTTONS
  const actions = (route) => {
    return (
      <CustomBtn
        type={"link_success_table"}
        text={"Vincular"}
        onClick={() => {
          confirmAdd(route);
        }}
      />
    );
  };

  //Data column
  const columns = [
    { field: "id_ruta", header: "Id" },
    { field: "route_category", header: "Cat." },
    { field: "route_descip", header: "Desc." },
    { field: "route_path", header: "Path" },
    { body: actions },
  ];

  useEffect(() => {
    getUserRoutes();
  }, [reFetch]);
  return (
    <div>
      {" "}
      <TableMain
        data={userRoutes}
        columns={columns}
        loading={loading}
        title={"Rutas no asignadas"}
        textFilter
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default TableRoutesNotAsigned;
