import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlGetCompanies } from "../../../../api/URLs/entidadesSecundarias/empresas";
import { urlGetPlaces } from "../../../../api/URLs/entidadesSecundarias/ubicaciones";
import { urlGetTarifasNegociadas } from "../../../../api/URLs/viajes";
//Modal
import { useModal } from "../../../../hooks/useModal";
import ModalAlert from "../../../../components/Modals/ModalAlert";
//Basics
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
import CustomDropdown from "../../../../components/Tools/Inputs/CustomDropdown";
import Divider from "../../../../components/Tools/Basics/Divider";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";

const TarifasNegociadas = () => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();

  //States
  const [places, setPlaces] = useState(null);
  const [clients, setClients] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tarifas, setTarifas] = useState(null);
  const [reFetch, setReFetch] = useState(false);

  //States Selected
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });

  //Get proveedores y localidades
  const getParallel = async () => {
    setLoading(true);
    const promise_array = [];
    promise_array.push(axiosWithAuth.get(urlGetPlaces));
    promise_array.push(axiosWithAuth.get(urlGetCompanies));
    try {
      const api_responses = await Promise.all(promise_array);
      const [places_response, clients_response] = api_responses;
      setPlaces(places_response.data);
      setClients(clients_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Handle Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDates((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  //Get Tarifas
  const getTarifas = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetTarifasNegociadas, {
        params: {
          id_cliente: selectedClient !== null ? selectedClient.id : null,
          id_proveedor: selectedSupplier !== null ? selectedSupplier.id : null,
          id_origen: selectedOrigin !== null ? selectedOrigin.id : null,
          id_destino:
            selectedDestination !== null ? selectedDestination.id : null,
          fecha1: dates.startDate,
          fecha2: dates.endDate,
        },
      });
      setTarifas(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "fecha",
      header: "Fecha",
      formater: "date",
      sortable: true,
      pdfFormat: "date",
    },
    { field: "tipo_tarifa_viaje", header: "Tipo T. Viaje" },
    {
      field: "tarifa_cliente",
      header: "T. Cliente",
      formater: "priece",
      sortable: true,
    },
    {
      field: "tarifa_chofer",
      header: "T. Chofer",
      formater: "priece",
      sortable: true,
    },
    { field: "cliente", header: "Cliente" },
    { field: "origen", header: "Origen" },
    { field: "destino", header: "Destino" },
    { field: "proveedor", header: "Proveedor" },
    { field: "tipo_tarifa_contrato", header: "Tipo T. Contrato" },
    {
      field: "valor_tarifa_proveedor",
      header: "T. Prov.",
      formater: "priece",
      sortable: true,
    },
    {
      field: "tarifa_referencia",
      header: "T. Ref.",
      formater: "priece",
      sortable: true,
    },
  ];

  //Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setReFetch(true);
  };

  useEffect(() => {
    getTarifas();
    getParallel();
    if (reFetch) {
      getTarifas();
      setReFetch(false);
    }
  }, [reFetch]);

  if (loading) return <CenterSpinner />;
  return (
    <div className="custom-container">
      <h2>
        <b>Tarifas Negociadas</b>
      </h2>
      <Divider />
      {(clients || places) !== null ? (
        <form className="mb-3" onSubmit={handleSubmit}>
          <div className="d-flex align-items-end flex-wrap">
            <div>
              <div className="pb-2">
                <span>Fecha Desde:</span>
                <input
                  type="date"
                  name="startDate"
                  className="border rounded text-secondary"
                  style={{ height: "38px" }}
                  value={dates.startDate}
                  onChange={handleChange}
                ></input>
              </div>
              <div>
                <span>Fecha Hasta:</span>
                <input
                  type="date"
                  name="endDate"
                  className="border rounded text-secondary"
                  style={{ height: "38px" }}
                  value={dates.endDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mx-2">
              <div className="pb-2">
                <span>Cliente</span>
                <CustomDropdown
                  options={clients}
                  displayProperty={"nomb_empresa"}
                  btnName={"Seleccione una opcion"}
                  onSelect={(c) => setSelectedClient(c)}
                  defaultOption={
                    selectedClient
                      ? { nomb_empresa: selectedClient.nomb_empresa }
                      : null
                  }
                />
              </div>
              <div>
                <span>Proveedor</span>
                <CustomDropdown
                  options={clients}
                  displayProperty={"nomb_empresa"}
                  btnName={"Seleccione una opcion"}
                  onSelect={(p) => setSelectedSupplier(p)}
                  defaultOption={
                    selectedSupplier
                      ? { nomb_empresa: selectedSupplier.nomb_empresa }
                      : null
                  }
                />
              </div>
            </div>
            <div>
              <div className="pb-2">
                <span>Origen</span>
                <CustomDropdown
                  options={places}
                  displayProperty={"desc_place"}
                  btnName={"Seleccione una opcion"}
                  onSelect={(o) => setSelectedOrigin(o)}
                  defaultOption={
                    selectedOrigin
                      ? { desc_place: selectedOrigin.desc_place }
                      : null
                  }
                />
              </div>
              <div>
                <span>Destino</span>
                <CustomDropdown
                  options={places}
                  displayProperty={"desc_place"}
                  btnName={"Seleccione una opcion"}
                  onSelect={(d) => setSelectedDestination(d)}
                  defaultOption={
                    selectedDestination
                      ? { desc_place: selectedDestination.desc_place }
                      : null
                  }
                />
              </div>
            </div>
            <button type="submit" className="btn btn-primary px-3 mx-2">
              Filtrar
            </button>
          </div>
        </form>
      ) : (
        <CenterSpinner />
      )}
      {tarifas !== null ? (
        <TableMain
          data={tarifas}
          columns={columns}
          keyProp={"id"}
          textFilter
          title={"Historial"}
          loading={loading}
          excelExport
          pdf={{
            pdfSave: `Tarifas-Negociadas`,
            pdfTitle: `Tarifas-Negociadas`,
            pdfHorizontal: true,
            pdfCompact: true,
          }}
        />
      ) : null}
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default TarifasNegociadas;
