//Router
import { useLocation } from "react-router-dom";
//navTabs
import NavTabs from "../../../components/NavBars/NavTabs/NavTabs";
//components
import TableRoutesAsigned from "./components/details/TableRoutesAsigned";
import TableRoutesNotAsigned from "./components/details/TableRoutesNotAsigned";

const UsuarioDetalle = () => {
  //Location
  const location = useLocation();
  const user = location.state.user;

  const sections = [
    { name: "Rutas Asignadas", component: <TableRoutesAsigned /> },
    { name: "Asignar Nueva Ruta", component: <TableRoutesNotAsigned /> },
  ];
  return (
    <div className="custom-container">
      <h2>
        <b>Usuario | {user.nombre}</b>
      </h2>
      <NavTabs sections={sections} />
    </div>
  );
};

export default UsuarioDetalle;
