import { useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlCreateOperativosTipo,
  urlEditOperativosTipo,
} from "../../../../api/URLs/operativos";
//HOOKS
import { useAlertModal } from "../../../../hooks/useAlertModal";
//FORMIK & YUP
import { Formik } from "formik";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Basic
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";

const FormOperativosTipos = ({selectedType, reFetch, setReFetch}) => {
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [sendLoading, setSendLoading] = useState(false);
  //INITIAL VALUES
  let newValues, updateValues;
  if (selectedType) {
    updateValues = {
      desc_tipo: selectedType.desc_tipo ? selectedType.desc_tipo : "",
    };
  } else {
    newValues = {
      desc_tipo: "",
    };
  }
  const initialValues = selectedType ? updateValues : newValues;
  //POST
  const handleSubmit = async (values) => {
    try {
      setSendLoading(true);
      let api_response;
      if (!selectedType) {
        api_response = await axiosWithAuth.post(
          urlCreateOperativosTipo,
          values
        );
      } else {
        api_response = await axiosWithAuth.put(urlEditOperativosTipo, {
          ...values,
          id_tipo: selectedType.id,
        });
      } 
      setReFetch(!reFetch)
      handleAlertSuccess(api_response)
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      <FormTitle prop={selectedType} text={"Tipo Operativo"}/>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="shadow-none mb-2">
            {/* OPERATIVO TIPO */}
            <Form.Label>Tipo de Operativo</Form.Label>
            <FormGroup>
              <Form.Control
                type="text"
                placeholder="Ingresar tipo de operativo"
                name="desc_tipo"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.desc_tipo}
              />
            </FormGroup>
            <ButtonSubmit
              prop={selectedType}
              isSubmitting={isSubmitting}
              loading={sendLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormOperativosTipos;
