import { useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlPostCost } from "../../../../api/URLs/operativos";
//HOOKS
import { useAlertModal } from "../../../../hooks/useAlertModal";
//FORMIK & YUP
import { Formik } from "formik";
//BOOTSTRAP
import { Card, Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Basic
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";

const FormCostos = ({ cost, reFetch, setReFetch }) => {
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [sendLoading, setSendLoading] = useState(false);
  //INITIAL VALUES
  const newValues = {
    hora_tys: "",
    km_camionero: "",
  };
  //PUT
  const handleSubmit = async (values) => {
    try {
      setSendLoading(true);
      const confirmed = window.confirm(
        `¿Está seguro que desea modificar a $${values.hora_tys} el costo de hora y $${values.km_camionero} el costo de km?`
      );
      if (confirmed) {
        const api_response = await axiosWithAuth.post(urlPostCost, {
          ...values,
          fecha: new Date(),
        });
        setReFetch(!reFetch);
        handleAlertSuccess(api_response);
      }
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };

  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {cost ? (
        <Card className="mb-3">
          <Card.Body className="text-center">
            Costos actuales:
            <br />
            <span className="px-2">
              Hora: $<b>{cost.hora_tys}</b>
            </span>
            Km: $<b>{cost.km_camionero}</b>
          </Card.Body>
        </Card>
      ) : null}
      <FormTitle prop={cost} text={"Costos"} />
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={newValues}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="shadow-none mb-2">
            {/* HORA */}
            <Form.Label>Nuevo valor para hora</Form.Label>
            <FormGroup>
              <Form.Control
                type="number"
                placeholder="Ingresar nuevo valor"
                name="hora_tys"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.hora_tys}
              />
            </FormGroup>
            {/* KM */}
            <Form.Label>Nuevo valor para km</Form.Label>
            <FormGroup>
              <Form.Control
                type="number"
                placeholder="Ingresar nuevo valor"
                name="km_camionero"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.km_camionero}
              />
            </FormGroup>
            <ButtonSubmit
              prop={cost}
              isSubmitting={isSubmitting}
              loading={sendLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormCostos;
