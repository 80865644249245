import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlCreateContract,
  urlEditContract,
} from "../../../../api/URLs/operativos";
import { urlGetFareTypes } from "../../../../api/URLs/viajes";
import { urlGetCompanies } from "../../../../api/URLs/entidadesSecundarias/empresas";
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import { useModal } from "../../../../hooks/useModal";
import { useAlertModal } from "../../../../hooks/useAlertModal";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
//--Basic
import { Formik } from "formik";
import { Form, FormGroup } from "react-bootstrap";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";
import CustomDropdown from "../../../../components/Tools/Inputs/CustomDropdown";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";

const FormContract = ({
  trip,
  reFetch,
  setReFetch,
  selectedContract,
  setSelectedContract,
}) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  //State TIPO DE TARIFA
  const [fareTypes, setFareTypes] = useState([]);
  const [selectedFareType, setSelectedFareType] = useState(null);
  //State PROVEEDOR
  const [supplier, setSupplier] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  //Initial Values
  let newValues, updateValues;
  if (selectedContract) {
    updateValues = {
      valor_tarifa_catac: selectedContract.valor_tarifa_catac
        ? selectedContract.valor_tarifa_catac
        : "",
      valor_tarifa_proveedor: selectedContract.valor_tarifa_proveedor
        ? selectedContract.valor_tarifa_proveedor
        : "",
    };
  } else {
    newValues = {
      valor_tarifa_catac: "",
      valor_tarifa_proveedor: "",
    };
  }
  const initialValues = selectedContract ? updateValues : newValues;
  //GET TIPO TARIFA Y PROVEEDORES
  const getParellel = async () => {
    setLoading(true);
    const promise_array = [];
    promise_array.push(axiosWithAuth.get(urlGetFareTypes));
    promise_array.push(axiosWithAuth.get(urlGetCompanies));
    try {
      const api_responses = await Promise.all(promise_array);
      const [fareTypes_response, supplier_response] = api_responses;
      setFareTypes(fareTypes_response.data);
      setSupplier(supplier_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //HANDLES
  const handleSelectFareType = async (f) => {
    setSelectedFareType(f.id);
  };

  //HANDLE EDIT
  const handleEdit = () => {
    if (selectedContract) {
      setSelectedFareType(selectedContract.id_tipo_tarifa);
      setSelectedSupplier(selectedContract.id_proveedor);
    }
  };

  const handleSubmit = async (values) => {
    const merged = {
      ...values,
      id_proveedor: selectedSupplier,
      id_viaje: selectedContract ? selectedContract.id_viaje : trip.id,
      id_tipo_tarifa: selectedFareType,
    };
    try {
      setSendLoading(true);
      let api_response;
      if (!selectedContract) {
        api_response = await axiosWithAuth.post(urlCreateContract, merged);
      } else {
        api_response = await axiosWithAuth.put(urlEditContract, {
          ...merged,
          id: selectedContract.id_contrato,
        });
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
      setSelectedContract(null);
    }
  };
  useEffect(() => {
    getParellel();
    handleEdit();
  }, []);
  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      <FormTitle text={"Contrato"} prop={selectedContract} />
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="shadow-none">
            <div className="row">
              <div className="col-md-6">
                {/* CLIENTE */}
                <Form.Label>Proveedor</Form.Label>
                <CustomDropdown
                  options={supplier}
                  onSelect={(v) => setSelectedSupplier(v.id)}
                  displayProperty={"nomb_empresa"}
                  btnName={"Seleccione un proveedor"}
                  defaultOption={
                    selectedContract
                      ? { nomb_empresa: selectedContract.proveedor }
                      : null
                  }
                />
                {/* TARIFA CATAC*/}
                <FormGroup>
                  <Form.Label>Tarifa Catac</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ingrese valor"
                    name="valor_tarifa_catac"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.valor_tarifa_catac}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* TIPO TARIFA */}
                <Form.Label>Tipo de Tarifa</Form.Label>
                <CustomDropdown
                  options={fareTypes}
                  onSelect={handleSelectFareType}
                  displayProperty={"desc_tarifa"}
                  btnName={"Seleccione tipo de tarifa"}
                  defaultOption={
                    selectedContract
                      ? { desc_tarifa: selectedContract.tarifa }
                      : null
                  }
                />

                {/* TARIFA PROVEEDOR*/}
                <FormGroup>
                  <Form.Label>Tarifa Proveedor</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ingrese valor"
                    name="valor_tarifa_proveedor"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.valor_tarifa_proveedor}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6 offset-md-3">
                <ButtonSubmit
                  prop={selectedContract}
                  isSubmitting={isSubmitting}
                  loading={sendLoading}
                  modalMinSize={"sm"}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormContract;
