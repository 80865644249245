import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetTyresBrand,
  urlCreateTyresBrand,
  urlEditTyresBrand,
} from "../../../../api/URLs/cubiertas";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMIK & YUP
import { Formik } from "formik";
import { tyresBrandSchema } from "../../../../schemas/Cubiertas/tyresBrandSchema";
//BOOTSTRAP
import { Button, Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../../components/Tools/Tables/Table/Table";
//--Basic
import BoxContainer from "../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";

const FormTyreBrand = () => {
  //useModal
  const {
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleError,
    handleSucces,
  } = useModal();
  //States
  const [reForm, setReForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [tyreBrand, setTyreBrand] = useState(null);
  const [tyresBrand, setTyresBrand] = useState([]);
  //INITIAL VALUES
  let newValues, updateValues;
  if (tyreBrand) {
    updateValues = {
      marca: tyreBrand.marca ? tyreBrand.marca : "",
    };
  } else {
    newValues = {
      marca: "",
    };
  }
  const initialValues = tyreBrand ? updateValues : newValues;
  //GET
  const getTyresBrand = async () => {
    try {
      setLoadingTable(true);
      const api_response = await axiosWithAuth.get(urlGetTyresBrand);
      setTyresBrand(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoadingTable(false);
    }
  };
  //POST - PUT
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      let api_response;
      if (tyreBrand) {
        api_response = await axiosWithAuth.put(urlEditTyresBrand, {
          ...values,
          id: tyreBrand.id,
        });
      } else {
        api_response = await axiosWithAuth.post(urlCreateTyresBrand, values);
      }
      handleSucces(api_response);
      setReForm(!reForm);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
      setTyreBrand(null);
    }
  };
  //HANDLE EDIT
  const handleEdit = async (t) => {
    setTyreBrand(t);
    setReForm(!reForm);
  };
  //BOTONES
  const actions = (t) => {
    return <CustomBtn type={"edit_table"} onClick={() => handleEdit(t)} />;
  };
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id" },
    { header: "Marca", field: "marca" },
    { header: "Editar", body: actions },
  ];
  useEffect(() => {
    getTyresBrand();
  }, [reForm]);
  return (
    <div className="row">
      <div className="col-md-8">
        <BoxContainer maxSize={"lg"} minSize={"lg"}>
          <Table
            data={tyresBrand}
            columns={columns}
            keyProp={"id"}
            title={"Marcas de Cubiertas"}
            scrollSize={"lg"}
            textFilter
            loading={loadingTable}
          />
        </BoxContainer>
      </div>
      <div className="col-md-4">
        {!loading ? (
          <>
            <div
              className={`alert alert-${
                tyreBrand ? "success" : "primary"
              } w-100`}
              role="alert"
            >
              {`${tyreBrand ? "Modificar" : "Añadir nueva"} marca ${
                tyreBrand ? tyreBrand.marca : ""
              }`}
            </div>
            <Formik
              enableReinitialize
              validationSchema={tyresBrandSchema}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit} className="shadow-none mb-2">
                  {/* MARCA */}
                  <FormGroup>
                    <Form.Control
                      type="text"
                      placeholder="Ingresar"
                      name="marca"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.marca}
                      isInvalid={errors.marca && touched.marca && errors.marca}
                    />
                  </FormGroup>
                  <Button
                    variant={tyreBrand ? "success" : "primary"}
                    className="mt-3 w-100"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {tyreBrand ? "Modificar" : "Agregar"}
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <CenterSpinner />
        )}
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormTyreBrand;
