import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetChecklists,
  urlLinkMaquinaChecklist,
} from "../../../../api/URLs/mantenimiento";
//HOOKS
import { useAlertModal } from "../../../../hooks/useAlertModal";
//COMPONENTS
//--Modal
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
//--Basic
import Table from "../../../../components/Tools/Tables/Table/Table";
import BoxContainer from "../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";

const FormLinkChecklist = ({ maquina, reFetch, setReFetch }) => {
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [checklists, setChecklists] = useState([]);
  const [selectedChecklist_id, setSelectedChecklist_id] = useState(
    maquina.id_checklist ? maquina.id_checklist : null
  );
  const [selectedChecklist_name, setSelectedChecklist_name] = useState(
    maquina.nombre_checklist ? maquina.nombre_checklist : null
  );
  //GET
  const getChecklists = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetChecklists);
      setChecklists(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //PUT
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const merged = {
        id_maquina: maquina.id_maquina,
        id_checklist: selectedChecklist_id,
      };
      const api_response = await axiosWithAuth.put(
        urlLinkMaquinaChecklist,
        merged
      );
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //HANDLE
  const handleSelect = (checklist) => {
    setSelectedChecklist_id(checklist.id);
    setSelectedChecklist_name(checklist.nombre);
  };
  //BUTTONS
  const actions = (checklist) => {
    return (
      <CustomBtn
        type={
          checklist.id === selectedChecklist_id
            ? "link_success_table"
            : "link_table"
        }
        text={
          checklist.id === selectedChecklist_id ? "Seleccionado" : "Seleccionar"
        }
        onClick={() => handleSelect(checklist)}
      />
    );
  };
  const button = [
    {
      styles: `${!selectedChecklist_id ? "danger" : "success"} btn-sm`,
      text: "Confirmar",
      click: () => handleSubmit(),
      noData: true,
      disabled: !selectedChecklist_id ? true : false,
    },
  ];
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id" },
    { header: "Checklist", field: "nombre" },
    { body: actions },
  ];
  useEffect(() => {
    getChecklists();
  }, []);
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : (
        <>
          <div className="card mb-2">
            <div className="py-1 px-3">
              <div>
                Maquina Seleccionada: <b>{maquina.id_interno}</b>
              </div>
              <div>
                Checklist Seleccionada:{" "}
                <b
                  className={
                    !selectedChecklist_id ? "text-danger" : "text-success"
                  }
                >
                  {selectedChecklist_id
                    ? selectedChecklist_name
                    : "Aún no se ha seleccionado"}
                </b>
              </div>
            </div>
          </div>
          <BoxContainer minSize={"md"} maxSize={"lg"}>
            <Table
              title={"Checklists"}
              data={checklists}
              columns={columns}
              buttons={button}
              loading={loading}
              scrollSize={"lg"}
            />
          </BoxContainer>
        </>
      )}
    </div>
  );
};

export default FormLinkChecklist;
