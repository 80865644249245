//NavTabs
import NavTabs from "../../../components/NavBars/NavTabs/NavTabs";
//Components
import TableCubiertasPersonal from "./components/TableCubiertasPersonal";
import FormTyreBrand from "../../Camiones/EntidadesSecundarias/Cubiertas/FormTyreBrand";
import FormTyreSize from "../../Camiones/EntidadesSecundarias/Cubiertas/FormTyreSize";

const CubiertasPersonal = () => {
  const sections = [
    { name: "Cubiertas", component: <TableCubiertasPersonal /> },
    { name: "Marcas", component: <FormTyreBrand /> },
    { name: "Medidas", component: <FormTyreSize /> },
  ];
  return (
    <div className="custom-container">
      <h2>
        <b>Cubiertas Terminales y Servicios</b>
      </h2>
      <NavTabs sections={sections} />
    </div>
  );
};

export default CubiertasPersonal;
