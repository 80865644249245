import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlSwitchEntities } from "../../../../../api/URLs/utilities";
import { urlGetDrivers } from "../../../../../api/URLs/choferes";
//ROUTER
import { useNavigate } from "react-router-dom";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
//FORMULARIO
import FormChoferes from "../../forms/Form";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
import PlainModal from "../../../../../components/Modals/ModalPlain";
//--Table
import TableMain from "../../../../../components/Tools/Tables/TableMain/TableMain";
//--Basic
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";
import NoWrapContainer from "../../../../../components/Tools/Basics/NoWrapContainer";

const TableChoferes = () => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //useNavigate
  const navigate = useNavigate();
  //States
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  //--Modal
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET
  const getDrivers = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetDrivers);
      setDrivers(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //POST Switch ACT-DESC
  const handleSwitchActive = async (id) => {
    const merged = { nombre_entidad: "choferes", id_entidad: id };
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.post(urlSwitchEntities, merged);
      setReFetch(!reFetch);
      handleSucces(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const confirmDelete = (driver) => {
    if (
      window.confirm(`Confirmar eliminación del chofer: ${driver.apelnomb}?`)
    ) {
      handleSwitchActive(driver.id_chofer);
    }
  };
  //HANDLES
  const handleDrivers = (driver) => {
    if (driver) {
      setSelectedDriver(driver);
    } else {
      setSelectedDriver(null);
    }
    setShowModalPlain(true);
  };
  //BOTONES
  const actions = (driver) => {
    return (
      <NoWrapContainer>
        <CustomBtn type={"edit_table"} onClick={() => handleDrivers(driver)} />
        <CustomBtn
          type={"delete_table"}
          onClick={() => confirmDelete(driver)}
        />
        <CustomBtn
          type={"details_table"}
          onClick={() =>
            navigate(`/choferes/detalles/${driver.id_chofer}`, {
              state: { driver },
            })
          }
        />
      </NoWrapContainer>
    );
  };
  const buttons = [
    {
      type: "plus_table_header",
      text: " Chofer",
      click: handleDrivers,
      noData: true,
    },
  ];
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id_chofer" },
    { header: "Nombre", field: "apelnomb" },
    { header: "Nº Cred. Puerto", field: "num_cred_puerto" },
    { header: "Empresa", field: "nomb_empresa" },
    { header: "DNI", field: "cuil" },
    { body: actions },
  ];
  useEffect(() => {
    getDrivers();
  }, [reFetch]);
  return (
    <div>
      <div>
        <TableMain
          data={drivers}
          columns={columns}
          keyProp={"id_chofer"}
          title={"Choferes"}
          textFilter
          loading={loading}
          buttons={buttons}
        />
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <PlainModal show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          <FormChoferes
            driver={selectedDriver}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </PlainModal>
      ) : null}
    </div>
  );
};

export default TableChoferes;
