import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlGetItems_x_Checklist } from "../../../../../api/URLs/mantenimiento";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
//--Modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../../../components/Tools/Tables/Table/Table";
import BoxContainer from "../../../../../components/Tools/Cards/BoxContainer/BoxContainer";

const CabeceraDetails = ({ chkl }) => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  //GET
  const getItems = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetItems_x_Checklist, {
        params: {
          id_checklist: chkl.id,
          accion: "A",
        },
      });
      setItems(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //COLUMNS
  const columns = [
    { field: "id_item", header: "Id" },
    { field: "item_nombre", header: "Item" },
    { field: "item_placeholder", header: "Placeholder" },
  ];
  const columnsError = [
    {header: "Esta checklist no tiene items asignados"}
  ]
  useEffect(() => {
    getItems();
  }, []);
  return (
    <div>
      <BoxContainer minSize={"md"} maxSize={"lg"}>
        <Table
          data={items}
          title={chkl.nombre}
          columns={items.length === 0 ? columnsError : columns}
          loading={loading}
          scrollSize={"lg"}
          textFilter
        />
      </BoxContainer>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default CabeceraDetails;
