import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlCorreoEventoLista } from "../../../../api/URLs/correos";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORM
import FormVincCE from "../forms/FormVincCE";
//COMPONENTS
//--modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import ModalPlain from "../../../../components/Modals/ModalPlain";
//--table
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
//--basic
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";

const TableCE = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //desvinc
  const desvincCorreo = async (values) => {
    try {
      setLoading(true);
      const merged = {
        id_correo: values.id_mail,
        id_evento: values.id_evento,
      };
      const api_response = await axiosWithAuth.post(
        urlCorreoEventoLista,
        merged
      );
      handleSucces(api_response);
      setReFetch(!reFetch);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //Confirm desvinc
  const confirmDesvinc = (c) => {
    if (
      window.confirm(
        `¿Desvincular correo "${c.mail}" del evento "${c.evento}"?`
      )
    )
      desvincCorreo(c);
  };
  //COLUMNAS
  const actionButton = (c) => {
    return (
      <CustomBtn
        type={"link_danger_table"}
        text={"Desvincular"}
        onClick={() => confirmDesvinc(c)}
      />
    );
  };
  const columns = [
    { field: "evento", header: "Evento", sortable: true },
    { field: "mail", header: "Correo", sortable: true },
    { body: actionButton },
  ];
  const buttons = [
    {
      type: "plus_table_header",
      text: " Vincular",
      click: () => setShowModalPlain(true),
      noData: true,
    },
  ];
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const api_response = await axiosWithAuth.get(urlCorreoEventoLista);
        setData(api_response.data);
      } catch (error) {
        const auxError = error.response.data.msj
          ? error.response.data.msj
          : "Error del servidor";
        handleError(auxError);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [reFetch]);
  return (
    <div>
      <TableMain
        data={data}
        columns={columns}
        buttons={buttons}
        loading={loading}
        title={"Correos y Eventos Vinculados"}
        textFilter
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          <FormVincCE reFetch={reFetch} setReFetch={setReFetch} />
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default TableCE;
