import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import {
  urlCreateItems,
  urlEditItems,
  urlGetItemsTipos,
} from "../../../../../api/URLs/mantenimiento";
//HOOKS
import { useAlertModal } from "../../../../../hooks/useAlertModal";
//FORMIK & YUP
import { Formik, Form } from "formik";
//--Basic
import CustomAlertMsj from "../../../../../components/Tools/Basics/CustomAlertMsj";
import ButtonSubmit from "../../../../../components/Tools/Basics/BtnSubmit";
import FormTitle from "../../../../../components/Tools/Basics/FormTitle";
import CustomInput from "../../../../../components/Tools/Inputs/CustomInput";
import CustomDropdown from "../../../../../components/Tools/Inputs/CustomDropdown";
import CenterSpinner from "../../../../../components/Tools/Basics/Spinner";

const FormItems = ({ item, reFetch, setReFetch }) => {
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
    handleAlertWarning,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [tipos, setTipos] = useState([]);
  const [selectedTipo, setSelectedTipo] = useState(item ? item : null);
  //Initial Values
  let newValues, updateValues;
  if (item) {
    updateValues = {
      id: item.id_item,
      nombre: item.nombre ? item.nombre : "",
      placeholder: item.placeholder ? item.placeholder : "",
    };
  } else {
    newValues = {
      nombre: "",
      placeholder: "",
    };
  }
  const initialValues = item ? updateValues : newValues;
  //GET
  const getTipos = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetItemsTipos);
      setTipos(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //VALIDACIONES (dropdowns)
  const validateOptions = () => {
    if (!selectedTipo) {
      handleAlertWarning("No se ha seleccionado tipo");
      return false;
    }
    return true;
  };
  //POST - PUT
  const handleSubmit = async (values) => {
    const validations = validateOptions();
    if (!validations) return;
    const merged = {
      ...values,
      id_tipo: item ? selectedTipo.id_tipo : selectedTipo.id,
    };
    try {
      setSendLoading(true);
      let api_response;
      if (!item) {
        api_response = await axiosWithAuth.post(urlCreateItems, merged);
      } else {
        api_response = await axiosWithAuth.put(urlEditItems, merged);
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  useEffect(() => {
    getTipos();
  }, []);
  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      <FormTitle prop={item} text={"Item"} />
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <CustomInput
              name={"nombre"}
              label={"Nombre Item"}
              placeholder={"Ingresar nombre"}
              loading={sendLoading}
            />
            <CustomDropdown
              options={tipos}
              onSelect={(t) => setSelectedTipo(t)}
              defaultOption={item ? { tipo: item.tipo } : null}
              displayProperty={"tipo"}
              btnName={"Seleccione un tipo"}
              label={"Tipo de Item"}
              loading={sendLoading}
            />
            <CustomInput
              name={"placeholder"}
              label={"Placeholder"}
              placeholder={"Ingresar placeholder"}
              loading={sendLoading}
            />
            <ButtonSubmit
              prop={item}
              isSubmitting={isSubmitting}
              loading={sendLoading}
              modalMinSize={"sm"}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormItems;
