//--Basics
import { Card, Col, Row } from "react-bootstrap";
import CustomBtn from "./CustomBtn";

const CardContracts = ({ buttons, data, handleEdit, trip, operativo }) => {
  return (
    <div>
      <div className="d-sm-flex justify-content-between align-items-end mb-0 pb-1 header-details-box">
        <p className="fw-bold">Contratos</p>
        <div className="d-flex">
          {buttons
            ? buttons.map((b, index) => (
                <div key={index}>
                  <CustomBtn
                    method={b.method}
                    onClick={() => b.click(b.noData ? null : trip)}
                    type={b.type}
                    btnType={b.styles}
                    icon={b.icon}
                    text={b.text}
                    disabled={b.disabled}
                  />
                </div>
              ))
            : null}
        </div>
      </div>
      <div>
        <Row>
          {data
            ? data.map((c, index) => (
                <Col key={index} sm={6} className="my-1">
                  <Card key={index}>
                    <div className="d-flex justify-content-between px-1 my-1">
                      <div className="d-flex flex-column">
                        <p className="fw-bold" style={{ fontSize: "14px" }}>
                          {c.proveedor}
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          Tipo Tarifa:
                          <span className="fw-bold"> {c.tarifa}</span>
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          Tarifa Referecia:{" "}
                          <span className="fw-bold">
                            ${c.valor_tarifa_catac}
                          </span>
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          Tarifa Proveedor:{" "}
                          <span className="fw-bold">
                            ${c.valor_tarifa_proveedor}
                          </span>
                        </p>
                      </div>
                      <div>
                        <CustomBtn
                          btnType={"link m-0 p-0"}
                          icon={
                            operativo.id_estado === 10
                              ? null
                              : "bi bi-pencil-square"
                          }
                          onClick={() => handleEdit(c)}
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              ))
            : null}
        </Row>
      </div>
    </div>
  );
};

export default CardContracts;
