import { useEffect, useState } from "react";
//API Route
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlGetEvents } from "../../../../api/URLs/vehiculosPersonal";
//Tables
import Table from "../../../../components/Tools/Tables/Table/Table";
import BoxContainer from "../../../../components/Tools/Cards/BoxContainer/BoxContainer";
//Modal
import { useModal } from "../../../../hooks/useModal";
import ModalPlain from "../../../../components/Modals/ModalPlain";
import ModalAlert from "../../../../components/Modals/ModalAlert";
//Form
import FormEvent from "../forms/FormEvent";

const EventosVehiculoPersonal = ({ vehicle }) => {
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
  } = useModal();

  //States
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [showModalPlain, setShowModalPlain] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  //Modal Plain
  const handleCloseModalPlain = () => setShowModalPlain(false);

  //GET vehicles
  const getData = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetEvents, {
        params: { id_veh: vehicle.id },
      });
      setEvent(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Show data
  const columns = [
    { field: "id", header: "Id" },
    { field: "desc_categoria", header: "Cat.", sortable: true },
    { field: "desc_evento", header: "Desc." },
    { field: "obs", header: "Obs." },
    { field: "kilometros", header: "Km", formater: "km", sortable: true },
    { field: "fecha", header: "Fecha", formater: "date", sortable: true },
  ];

  const handleEvent = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowModalPlain(true);
  };
  const buttonsTrips = [
    {
      type: "plus_table_header",
      text: " Evento",
      click: () => handleEvent(vehicle),
    },
  ];

  useEffect(() => {
    getData();
  }, [reFetch]);

  return (
    <div className="row">
      <div className="col-md-12">
        <BoxContainer minSize={"lg"} maxSize={"lg"}>
          <Table
            title={"Eventos"}
            data={event}
            reFetch={reFetch}
            columns={columns}
            keyProp={"id"}
            loading={loading}
            buttons={buttonsTrips}
            textFilter
            scrollSize={"lg"}
          />
        </BoxContainer>
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          <FormEvent
            vehicle={selectedVehicle}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default EventosVehiculoPersonal;
