import TableMaquinas from "./components/TableMaquinas";

const MaquinasChklCambiarHoras = () => {
  return (
    <div className="custom-container">
      <h2>
        <b>Maquinas Terminales y Servicios</b>
      </h2>
      <TableMaquinas />
    </div>
  );
};

export default MaquinasChklCambiarHoras;
