//Components
import Divider from "../../../components/Tools/Basics/Divider";
import TableRoutes from "./components/TableRoutes";

const FrontRoutes = () => {
  return (
    <div className="custom-container">
      <h2>
        <b>Rutas Web Terminales y Servicios</b>
      </h2>
      <Divider />
      <TableRoutes />
    </div>
  );
};

export default FrontRoutes;
