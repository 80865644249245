import { useState } from "react";
//FORMS
import FormConformTeam from "./FormConformTeam";
import FormSelectTeam from "./FormSelectTeam";
//COMPONENTS
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";

const FormInitTrip = ({ trip, reFetch, setReFetch }) => {
  const [state, setState] = useState(null);
  if (!state)
    return (
      <div>
        <CustomBtn
          btnType={"success btn-lg w-100 my-3"}
          text={"Crear Nuevo Equipo"}
          onClick={() => setState("conform")}
        />
        <CustomBtn
          btnType={"info btn-lg w-100 mb-3"}
          text={"Seleccionar Equipo"}
          onClick={() => setState("select")}
        />
      </div>
    );
  return (
    <div>
      <CustomBtn
        btnType={"link btn-lg p-0 m-0 ms-1"}
        icon={"bi bi-arrow-left-circle"}
        onClick={() => setState(null)}
      />
      {state === "conform" ? (
        <FormConformTeam
          trip={trip}
          reFetch={reFetch}
          setReFetch={setReFetch}
        />
      ) : (
        <FormSelectTeam
          trip={trip}
          reFetch={reFetch}
          setReFetch={setReFetch}
        />
      )}
    </div>
  );
};

export default FormInitTrip;
