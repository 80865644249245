import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetWebRoutes,
  urlDeleteWebRoute,
} from "../../../../api/URLs/rutasWeb";
//Components
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";
import NoWrapContainer from "../../../../components/Tools/Basics/NoWrapContainer";
//Modal
import { useModal } from "../../../../hooks/useModal";
import ModalAlert from "../../../../components/Modals/ModalAlert";
import ModalPlain from "../../../../components/Modals/ModalPlain";
//Form
import FormRoutes from "../forms/Form";

const TableRoutes = () => {
  //States
  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [showModalPlain, setShowModalPlain] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [reFetch, setReFetch] = useState(false);

  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();

  //Modal Plain
  const handleCloseModalPlain = () => setShowModalPlain(false);

  //Get Data
  const getRoutes = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetWebRoutes);
      setRoutes(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //Delete Route
  const deleteRoute = async (id) => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.post(urlDeleteWebRoute, {
        id_route: id,
      });
      handleSucces(api_response);
      setReFetch(!reFetch);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const confirmDelete = (route) => {
    if (
      window.confirm(
        `Esta seguro de eliminar la ruta: ${route.descrip} - '${route.path}'`
      )
    )
      deleteRoute(route.id);
  };

  //BUTTONS
  const handleCreate = () => {
    setSelectedRoute(null);
    setShowModalPlain(true);
  };

  const handleEdit = async (r) => {
    setSelectedRoute(r);
    setShowModalPlain(true);
  };

  const buttons = [
    {
      type: "plus_table_header",
      text: " Ruta",
      click: () => handleCreate(),
    },
  ];

  const actionButton = (route) => {
    return (
      <NoWrapContainer>
        <CustomBtn type={"edit_table"} onClick={() => handleEdit(route)} />
        <CustomBtn type={"delete_table"} onClick={() => confirmDelete(route)} />
      </NoWrapContainer>
    );
  };

  //Data columns
  const columns = [
    { field: "id", header: "Id" },
    { field: "descrip", header: "Desc." },
    { field: "category", header: "Cat.", sortable: true },
    { field: "element", header: "Elemen." },
    { field: "path", header: "Path" },
    {
      field: "display",
      header: "Disp.",
      formater: "boolean_color",
    },
    { body: actionButton },
  ];

  useEffect(() => {
    getRoutes();
  }, [reFetch]);
  return (
    <>
      <TableMain
        data={routes}
        columns={columns}
        loading={loading}
        buttons={buttons}
        title={"Rutas web"}
        textFilter
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          <FormRoutes
            routes={selectedRoute}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </ModalPlain>
      ) : null}
    </>
  );
};

export default TableRoutes;
