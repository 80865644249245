import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlCreatePersonalVehicles,
  urlEditPersonalVehicles,
  urlGetPersonalVehiclesSectors,
} from "../../../../api/URLs/vehiculosPersonal";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMIK & YUP
import { Formik } from "formik";
import { personalVehiclesSchema } from "../../../../schemas/PersonalVehicles/personalVehiclesSchema";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import { useAlertModal } from "../../../../hooks/useAlertModal";
//--Basic
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";
import CustomDropdown from "../../../../components/Tools/Inputs/CustomDropdown";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";

const FormVehiculosPersonal = ({ vehicle, reFetch, setReFetch }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [sectores, setSectores] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  //Initial Values
  let newValues, updateValues;
  if (vehicle) {
    updateValues = {
      patente: vehicle.patente ? vehicle.patente : "",
      desc_vehiculo: vehicle.desc_vehiculo ? vehicle.desc_vehiculo : "",
      fecha_seguro: vehicle.fecha_seguro
        ? vehicle.fecha_seguro.slice(0, 10)
        : "",
      fecha_vtv: vehicle.fecha_vtv ? vehicle.fecha_vtv.slice(0, 10) : "",
      kilometros: vehicle.kilometros ? vehicle.kilometros : "",
    };
  } else {
    newValues = {
      patente: "",
      desc_vehiculo: "",
      fecha_seguro: "",
      fecha_vtv: "",
      kilometros: "",
    };
  }
  //GET SECTORES
  const getSectores = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(
        urlGetPersonalVehiclesSectors
      );
      setSectores(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //HANDLE IDs ON EDIT
  const handleEdit = () => {
    if (vehicle) {
      setSelectedSector(vehicle.id_sector);
    }
  };

  const initialValues = vehicle ? updateValues : newValues;
  //POST - PUT
  const handleSubmit = async (values) => {
    const merged = {
      ...values,
      id_sector: selectedSector,
    };
    try {
      setSendLoading(true);
      let api_response;
      if (!vehicle) {
        api_response = await axiosWithAuth.post(
          urlCreatePersonalVehicles,
          merged
        );
      } else {
        api_response = await axiosWithAuth.put(urlEditPersonalVehicles, {
          ...merged,
          id: vehicle.id,
        });
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  useEffect(() => {
    getSectores();
    handleEdit();
  }, []);
  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      <FormTitle prop={vehicle} text={"Vehiculo"} />
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        validationSchema={personalVehiclesSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="shadow-none">
            <div className="row">
              <div className="col-md-6">
                {/* PATENTE */}
                <FormGroup>
                  <Form.Label>Patente</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Patente"
                    name="patente"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.patente}
                    isInvalid={
                      errors.patente && touched.patente && errors.patente
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.patente && touched.patente && errors.patente}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* Descripcion vehiculo */}
                <FormGroup>
                  <Form.Label>Descripcion vehiculo</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Descripcion vehiculo"
                    name="desc_vehiculo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.desc_vehiculo}
                  />
                </FormGroup>
                {/* Kilometros */}
                <FormGroup>
                  <Form.Label>Kilometros</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Kilometros"
                    name="kilometros"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.kilometros}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* SECTORES */}
                <FormGroup className="w-100 mb-1">
                  <Form.Label>Sector</Form.Label>
                  <CustomDropdown
                    options={sectores}
                    onSelect={(s) => setSelectedSector(s.id)}
                    defaultOption={vehicle ? { sector: vehicle.sector } : null}
                    displayProperty={"sector"}
                    btnName={"Seleccione un sector"}
                  />
                </FormGroup>
                {/* Fecha Seguro */}
                <FormGroup className="w-100">
                  <Form.Label>Fecha Seguro</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha_seguro"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fecha_seguro}
                  />
                </FormGroup>
                {/* Fecha VTV */}
                <FormGroup className="w-100 mb-1">
                  <Form.Label>Fecha VTV</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha_vtv"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fecha_vtv}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6 offset-md-3">
                <ButtonSubmit
                  prop={vehicle}
                  isSubmitting={isSubmitting}
                  loading={sendLoading}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormVehiculosPersonal;
