import { useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlPostKmsLongDist } from "../../../../api/URLs/viajes";
//HOOKS
import { useAlertModal } from "../../../../hooks/useAlertModal";
//FORMIK & YUP
import { Formik } from "formik";
//BOOTSTRAP
import { Card, Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Basic
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";

const FormKmsLongDist = ({ kmsDist, reFetch, setReFetch }) => {
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [sendLoading, setSendLoading] = useState(false);
  //INITIAL VALUES
  const newValues = {
    km_apartir: "",
  };
  //PUT
  const handleSubmit = async (values) => {
    try {
      setSendLoading(true);
      const confirmed = window.confirm(
        `¿Está seguro que desea modificar a ${values.km_apartir} km el valor para larga distancia?`
      );
      if (confirmed) {
        const api_response = await axiosWithAuth.post(urlPostKmsLongDist, {
          ...values,
          fecha: new Date(),
        });
        setReFetch(!reFetch);
        handleAlertSuccess(api_response);
      }
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };

  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {kmsDist ? (
        <Card className="mb-3">
          <Card.Body className="text-center">
            Larga distancia actual a partir de <b>{kmsDist.km_apartir}</b> km:
          </Card.Body>
        </Card>
      ) : null}
      <FormTitle prop={kmsDist} text={"Kms Larga distancia"} />
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={newValues}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="shadow-none mb-2">
            {/* KMS LARGA DISTANCIA*/}
            <Form.Label>Nuevos kilometros</Form.Label>
            <FormGroup>
              <Form.Control
                type="number"
                placeholder="Ingresar nuevo valor"
                name="km_apartir"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.km_apartir}
              />
            </FormGroup>
            <ButtonSubmit
              prop={kmsDist}
              isSubmitting={isSubmitting}
              loading={sendLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormKmsLongDist;
