import { useState, useEffect } from "react";
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlLinkTripTeamV3 } from "../../../../../api/URLs/viajes";
import { urlGetContracts } from "../../../../../api/URLs/operativos";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
import { useAlertModal } from "../../../../../hooks/useAlertModal";
//FORMIK & YUP
import { Formik, Form } from "formik";
//COMPONENTS
//--modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//--basics
import ButtonSubmit from "../../../../../components/Tools/Basics/BtnSubmit";
import CustomAlertMsj from "../../../../../components/Tools/Basics/CustomAlertMsj";
import CustomInput from "../../../../../components/Tools/Inputs/CustomInput";
import CustomCheckbox from "../../../../../components/Tools/Inputs/CustomCheckbox";
import CustomDropdown from "../../../../../components/Tools/Inputs/CustomDropdown";
import CenterSpinner from "../../../../../components/Tools/Basics/Spinner";
import BoxContainer from "../../../../../components/Tools/Cards/BoxContainer/BoxContainer";

const FormTeam = ({ trip, team, reFetch, setReFetch, dataEdit }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);
  //INITIAL VALUES
  let newValues, updateValues;
  if (dataEdit) {
    updateValues = {
      id: dataEdit.id_viaje_equipo,
      toneladas: dataEdit.toneladas ? dataEdit.toneladas : "",
      fecha_inicio: dataEdit.fecha_inicio
        ? dataEdit.fecha_inicio.slice(0, 16)
        : "",
      fecha_fin: dataEdit.fecha_fin ? dataEdit.fecha_fin.slice(0, 16) : "",
      cant_viajes: dataEdit.cant_viajes ? dataEdit.cant_viajes : "",
      cant_controles_descarga: dataEdit.cant_controles_descarga
        ? dataEdit.cant_controles_descarga
        : "",
      son_km_idayvuelta: dataEdit.son_km_idayvuelta
        ? dataEdit.son_km_idayvuelta
        : false,
    };
  } else {
    newValues = {
      toneladas: "",
      fecha_inicio: "",
      fecha_fin: "",
      cant_viajes: "",
      cant_controles_descarga: "",
      son_km_idayvuelta: true,
    };
  }
  const initialValues = dataEdit ? updateValues : newValues;
  //GET
  const getContracts = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetContracts, {
        params: { id_viaje: trip.id },
      });
      setContracts(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //POST
  const handleSubmit = async (values) => {
    Object.keys(values).forEach((key) => {
      if (key === "fecha_inicio" && values[key] === "" || values[key] === null) {
        values[key] = null;
      }
      if (key === "fecha_inicio" && values[key] !== "" && values[key] !== null) {
        values[key] = values[key].replace("T", " ");
      }
      if (key === "fecha_fin" && values[key] === "" || values[key] === null) {
        values[key] = null;
      }
      if (key === "fecha_fin" && values[key] !== "" && values[key] !== null) {
        values[key] = values[key].replace("T", " ");
      }
      if (key === "cant_viajes" && values[key] === "") {
        values[key] = 1;
      }
    });
    const merged = {
      ...values,
      ...team,
      id_viaje: trip.id,
      distancia_calculada: trip.distancia_calculada,
      id_contrato: selectedContract ? selectedContract.id_contrato : "",
    };
    try {
      setSendLoading(true);
      let api_response;
      if (dataEdit) {
        api_response = await axiosWithAuth.put(urlLinkTripTeamV3, merged);
      } else {
        api_response = await axiosWithAuth.post(urlLinkTripTeamV3, merged);
      }
      handleAlertSuccess(api_response);
      setReFetch(!reFetch);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  useEffect(() => {
    getContracts();
    if (dataEdit) {
      setSelectedContract({
        id_contrato: dataEdit.id_proveedor ? dataEdit.id_proveedor : null,
      });
    }
  }, []);
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  if (loading) return <CenterSpinner />;
  return (
    <div>
      {dataEdit ? (
        <BoxContainer>
          <div className="d-md-flex justify-content-evenly">
            <p>
              <strong>Camión: </strong>
              {dataEdit.patente_camion}
            </p>
            <p>
              <strong>Batea: </strong>
              {dataEdit.patente_acoplado}
            </p>
            <p>
              <strong>Chofer: </strong>
              {dataEdit.apelnomb_choferv}
            </p>
          </div>
        </BoxContainer>
      ) : null}
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="row">
              <div className="col-md-6">
                <CustomInput
                  name={"toneladas"}
                  label={"Toneladas"}
                  placeholder={"Ingresar las toneladas"}
                  loading={sendLoading}
                  typeInput={"number"}
                />
                <CustomInput
                  name={"cant_viajes"}
                  label={"Cantidad de Viajes"}
                  placeholder={"Cantidad de Viajes"}
                  loading={sendLoading}
                  typeInput={"number"}
                />
                <CustomInput
                  name={"cant_controles_descarga"}
                  label={"Controles de Descarga"}
                  placeholder={"Ingresar ctrl de carga"}
                  loading={sendLoading}
                  typeInput={"number"}
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  name={"fecha_inicio"}
                  label={"Fecha y Hora Inicio de Viaje"}
                  loading={sendLoading}
                  typeInput={"datetime-local"}
                />
                <CustomInput
                  name={"fecha_fin"}
                  label={"Fecha y Hora Fin de Viaje"}
                  loading={sendLoading}
                  typeInput={"datetime-local"}
                />
                <CustomDropdown
                  options={contracts}
                  onSelect={(v) => setSelectedContract(v)}
                  displayProperty={"proveedor"}
                  defaultOption={
                    !dataEdit
                      ? null
                      : dataEdit.proveedor
                      ? { proveedor: dataEdit.proveedor }
                      : null
                  }
                  btnName={"Seleccione un contrato"}
                  keyProperty={"id_contrato"}
                  label={"Contrato"}
                  loading={sendLoading}
                />
              </div>
              <CustomCheckbox
                name={"son_km_idayvuelta"}
                text={"¿Km ida y vuelta?"}
                loading={sendLoading}
              />
            </div>
            <div className="col-md-6 offset-md-3">
              <ButtonSubmit
                variant={dataEdit ? "success" : "primary"}
                text={`${dataEdit ? "Modificar" : "Crear"} Equipo`}
                isSubmitting={isSubmitting}
                loading={sendLoading}
                modalMinSize={"sm"}
              />
            </div>
          </Form>
        )}
      </Formik>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormTeam;
