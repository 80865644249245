import React, { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetNotAssignedTyres,
  urlLinkVehiclesTyres,
} from "../../../../api/URLs/vehiculos";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMIK & YUP
import { Formik } from "formik";
import { kilometresSchema } from "../../../../schemas/Camiones/Vehicles/kilometresSchema";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import { useAlertModal } from "../../../../hooks/useAlertModal";
//--Basics
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";

const FormVehiclesTyres = ({ vehicle, selector, setReFetch, reFetch }) => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
    handleAlertWarning,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [tyres, setTyres] = useState([]);
  const [selectedTyres, setSelectedTyres] = useState([]);
  const [search, setSearch] = useState("");
  //Initial Values
  const initialValues = {
    kilometros_tractor: "",
  };
  //GET
  const getTyres = async () => {
    clearStates();
    try {
      setLoading(true);
      if (selector === 1) {
        const api_response = await axiosWithAuth.get(urlGetNotAssignedTyres);
        setTyres(api_response.data);
      } else {
        const api_response = await axiosWithAuth.get(
          `${urlLinkVehiclesTyres}/${vehicle.id}`
        );
        setTyres(api_response.data);
      }
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //CLEAR STATES
  const clearStates = () => {
    setTyres([]);
    setSelectedTyres([]);
  };
  //MANEJADOR DE CUBIERTAS SELECCIONADAS
  const handleTyreSelect = (t) => {
    const itemIndex = selectedTyres.indexOf(t);
    if (itemIndex === -1) {
      setSelectedTyres([...selectedTyres, t]);
    } else {
      setSelectedTyres(
        selectedTyres.filter((id_cubierta) => id_cubierta !== t)
      );
    }
  };
  //POST VINCULAR-DESVINCULAR
  const handleSubmit = async (values) => {
    const tyresId = selectedTyres.map((ids) => {
      return { id_cubierta: ids };
    });
    const merged = {
      id_vehiculo: vehicle.id,
      ...values,
      tyres: tyresId,
      accion: selector === 1 ? 1 : 2,
    };
    setSendLoading(true);
    try {
      const api_response = await axiosWithAuth.post(
        urlLinkVehiclesTyres,
        merged
      );
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  //FILTRO
  //Logica para filtrar vehiculos por texto
  const filteredData = tyres.filter((v) => {
    const lowerCasedValue = search.toLowerCase();
    return Object.keys(v).some((key) =>
      v[key]?.toString()?.toLowerCase()?.includes(lowerCasedValue)
    );
  });
  useEffect(() => {
    getTyres();
  }, []);
  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  if (selector === 2)
    return (
      <div className="col-md-8 m-auto">
        <h4 className="text-center">Cubiertas</h4>
        <div className="card-body">
          <ul className="list-group">
            {filteredData.map((t) => (
              <li key={t.id_cubierta} className="list-group-item py-1 pl-2">
                {`${t.numero} - ${t.medida} - ${t.marca}`}
              </li>
            ))}
          </ul>
          {tyres.length > 0 ? null : (
            <p className="text-center">
              {`El movil ${vehicle.numMovil} no tiene cubiertas vinculadas`}
            </p>
          )}
        </div>
      </div>
    );
  return (
    <div className="row">
      <div className="col-lg-6">
        <h4 className="text-center">
          {selector === 1 ? "Cubiertas libres" : "Cubiertas asignadas"}
        </h4>
        <div className="card">
          <div className="card-body tyres-free-scroll">
            {tyres.length < 15 ? null : (
              <input
                className="form-control form-control-sm border-0 focus-none"
                type="text"
                placeholder="Buscar..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            )}
            <ul className="list-group">
              {filteredData.map((t) => (
                <li key={t.id_cubierta} className="list-group-item py-1 pl-2">
                  <input
                    type="checkbox"
                    checked={selectedTyres.indexOf(t.id_cubierta) !== -1}
                    onChange={() => handleTyreSelect(t.id_cubierta)}
                  />
                  {`${t.numero} - ${t.medida} - ${t.marca}`}
                </li>
              ))}
            </ul>
            {tyres.length !== 0 ? null : (
              <p className="text-center">
                {selector === 1
                  ? "No existen cubiertas libres"
                  : `El movil ${vehicle.numMovil} no tiene cubiertas vinculadas`}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <h4 className="text-center mt-2 mt-lg-0">Cubiertas seleccionadas</h4>
        <div className="card">
          <div className="card-body tyres-select-scroll">
            <ul className="list-group">
              {selectedTyres.map((tyreId) => {
                const selectedTyre = tyres.find(
                  (t) => t.id_cubierta === tyreId
                );
                return (
                  <li key={tyreId} className="list-group-item py-1">
                    {`${selectedTyre.numero} - ${selectedTyre.marca}`}
                  </li>
                );
              })}
            </ul>
            {selectedTyres.length !== 0 ? null : (
              <p className="text-center">No existen cubiertas seleccionadas</p>
            )}
          </div>
        </div>
        <Formik
          enableReinitialize
          validationSchema={kilometresSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} className="shadow-none">
              <FormGroup>
                <Form.Label>Kilometros del vehiculo</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ingresar kilometros"
                  name="kilometros_tractor"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.kilometros_tractor}
                  isInvalid={
                    errors.kilometros_tractor &&
                    touched.kilometros_tractor &&
                    errors.kilometros_tractor
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.kilometros_tractor &&
                    touched.kilometros_tractor &&
                    errors.kilometros_tractor}
                </Form.Control.Feedback>
              </FormGroup>
              <ButtonSubmit
                variant={selector === 1 ? "primary" : "danger"}
                text={selector === 1 ? "Vincular" : "Desvincular"}
                isSubmitting={isSubmitting}
                loading={sendLoading}
              />
              {alertState === 2 ? (
                <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
              ) : null}
            </Form>
          )}
        </Formik>
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormVehiclesTyres;
