import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetMaquinistas,
  urlDeleteMaquinistas,
} from "../../../../api/URLs/mantenimiento";
//ROUTER
import { useNavigate } from "react-router-dom";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMULARIO
import FormMaquinista from "../forms/FormMaquinista";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import PlainModal from "../../../../components/Modals/ModalPlain";
//--Table
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
//--Basic
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";
import NoWrapContainer from "../../../../components/Tools/Basics/NoWrapContainer";

const TableMaquinistas = () => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //useNavigate
  const navigate = useNavigate();
  //States
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [maqs, setMaqs] = useState([]);
  const [selectedMaq, setSelectedMaq] = useState(null);
  //--Modal
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET
  const getMaqs = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetMaquinistas);
      setMaqs(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
      setSelectedMaq(null);
    }
  };
  //DELETE
  const handleDelete = async (id_maq) => {
    const merged = { id: id_maq };
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.delete(urlDeleteMaquinistas, {
        data: merged,
      });
      setReFetch(!reFetch);
      handleSucces(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const confirmDelete = (maq) => {
    if (
      window.confirm(
        `Confirmar eliminación del maquinista: ${maq.nombre}?`
      )
    ) {
      handleDelete(maq.id);
    }
  };
  //HANDLES
  const handleForm = (maq) => {
    if (maq) {
      setSelectedMaq(maq);
    } else {
      setSelectedMaq(null);
    }
    setShowModalPlain(true);
  };
  //BOTONES
  const actions = (maq) => {
    return (
      <NoWrapContainer>
        <CustomBtn type={"edit_table"} onClick={() => handleForm(maq)} />
        <CustomBtn type={"delete_table"} onClick={() => confirmDelete(maq)} />
        <CustomBtn
          type={"link_table"}
          text={"Checklists"}
          onClick={() =>
            navigate(`/maquinista/checklists/${maq.id}`, {
              state: { maq },
            })
          }
        />
      </NoWrapContainer>
    );
  };
  const buttons = [
    {
      type: "plus_table_header",
      text: " Maquinista",
      click: handleForm,
      noData: true,
    },
  ];
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id" },
    { header: "Nombre", field: "nombre" },
    { header: "¿Cerrar Chkl?", field: "cierra_chk", formater: "boolean_color"},
    { body: actions },
  ];
  useEffect(() => {
    getMaqs();
  }, [reFetch]);
  return (
    <div>
      <TableMain
        data={maqs}
        title={"Listado de maquinistas"}
        columns={columns}
        loading={loading}
        textFilter
        scrollSize={"lg"}
        buttons={buttons}
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <PlainModal show={true} handleClose={handleCloseModalPlain}>
          <FormMaquinista
            selectedMaq={selectedMaq}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </PlainModal>
      ) : null}
    </div>
  );
};

export default TableMaquinistas;
