//NavTabs
import NavTabs from "../../components/NavBars/NavTabs/NavTabs";
//Components
import TableEstadoOperaciones from "./components/TableEstadoOperaciones";
import TableDetalleTurnos from "./components/TableDetalleTurnos";

const OperacionesEstado = () => {
  const sections = [
    { name: "Estados", component: <TableEstadoOperaciones /> },
    { name: "Detalles Turnos", component: <TableDetalleTurnos /> },
  ];
  return (
    <div className="custom-container">
      <h2>
        <b>Estado Operaciones</b>
      </h2>
      <NavTabs sections={sections} />
    </div>
  );
};

export default OperacionesEstado;
