//RUTAS OPERATIVOS
//--gam
export const urlGetOperativos = "/api/operativos";
export const urlCreateOperativo = "/api/operativo";
export const urlEditOperativo = "/api/operativo";
//--gam tipos de operativos
export const urlGetOperativosTipos = "/api/operativos/tipos";
export const urlCreateOperativosTipo = "/api/operativo/tipo";
export const urlEditOperativosTipo = "/api/operativo/tipo";
//--viajes x operativo
export const urlGetOperativoTrips = "/api/operativos/viajes";
//--estado de operativo
export const urlSwitchOperativoEstado = "/api/operativos/mod-estado";
//--Get OC
export const urlGetOC = "/api/operativo-oc";
//--costos
export const urlGetLastCost = "/api/ult-costo";
export const urlPostCost = "/api/costos";
export const urlHistoryCost = "/api/costos";
//--contratos
export const urlGetContracts = "/api/contratos/viaje";
export const urlCreateContract = "/api/contratos";
export const urlEditContract = "/api/contratos";
//--Get nomina
export const urlGetOperativoNomina = "/api/operativo-nomina";
//--Resumen operativos
export const urlGetResumenOperativos = "/api/resumen-operativos";
