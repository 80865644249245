import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlCreateFuel } from "../../../../api/URLs/entidadesSecundarias/combustible";
import { urlGetDrivers } from "../../../../api/URLs/choferes";
//FORMIK
import { Formik } from "formik";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//--Basic
import CustomDropdown from "../../../../components/Tools/Inputs/CustomDropdown";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import { useModal } from "../../../../hooks/useModal";
import { useAlertModal } from "../../../../hooks/useAlertModal";

const FormFuel = ({ vehicle, reFetch, setReFetch }) => {
  //--Modal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();

  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();

  //States
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  //Get Categories
  const getDriversNames = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetDrivers);
      setDrivers(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Initial Values
  const initialValues = {
    id_camion: vehicle.id,
    origen: "",
    kms: "",
    litros: "",
    precio_litro: "",
    ticket: "",
    vale: "",
    fecha_carga: "",
  };

  //POST
  const handleSubmit = async (values) => {
    try {
      setSendLoading(true);
      const api_response = await axiosWithAuth.post(urlCreateFuel, {
        ...values,
        id_conductor: selectedDriver,
      });
      handleAlertSuccess(api_response);
      setReFetch(!reFetch);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };

  const handleSelectDriver = async (d) => {
    setSelectedDriver(d.id_chofer);
  };

  //Use effect
  useEffect(() => {
    getDriversNames();
  }, []);

  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                {/* ID CAMION */}
                <FormGroup>
                  <Form.Label>Info Camion</Form.Label>
                  <Form.Control
                    placeholder={`Patente: ${vehicle.patente}`}
                    disabled
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* CHOFER */}
                <FormGroup>
                  <Form.Label>Chofer</Form.Label>
                  <CustomDropdown
                    options={drivers}
                    displayProperty={"apelnomb"}
                    onSelect={handleSelectDriver}
                    btnName={"Seleccione un chofer"}
                    keyProperty={"id_chofer"}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* FECHA */}
                <FormGroup>
                  <Form.Label>Fecha</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha_carga"
                    value={values.fecha_carga}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* KILOMETROS */}
                <FormGroup>
                  <Form.Label>Origen</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre estacion"
                    name="origen"
                    value={values.origen}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* KILOMETROS */}
                <FormGroup>
                  <Form.Label>Kilometros</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Kilometros"
                    name="kms"
                    value={values.kms}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* LITROS */}
                <FormGroup>
                  <Form.Label>Litros</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Litros"
                    name="litros"
                    value={values.litros}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* PRECIO POR LITROS */}
                <FormGroup>
                  <Form.Label>Precio por litro</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="$"
                    name="precio_litro"
                    value={values.precio_litro}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* VALE */}
                <FormGroup>
                  <Form.Label>Vale</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ingrese numero de vale"
                    name="vale"
                    value={values.vale}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* TICKET */}
                <FormGroup>
                  <Form.Label>Ticket</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ingrese el numero de ticket"
                    name="ticket"
                    value={values.ticket}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6 d-flex align-self-end mt-3">
                <ButtonSubmit
                  className={"my-0"}
                  isSubmitting={isSubmitting}
                  loading={sendLoading}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormFuel;
