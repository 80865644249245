import { useLocation } from "react-router-dom";
import TableChklMaquinas from "./components/TableChklsMaquinas";

const ChklCambiarHoras = () => {
  //Location
  const location = useLocation();
  const maquina = location.state.maquina;
  return (
    <div className="custom-container">
      <h2>
        <b>Maquina | {maquina.id_interno}</b>
      </h2>
      <TableChklMaquinas maquina={maquina} />
    </div>
  );
};

export default ChklCambiarHoras;
