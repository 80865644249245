import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../api/axiosConfig";
import { urlGetOperaciones2 } from "../../../api/URLs/operaciones";
//HOOKS
import { useModal } from "../../../hooks/useModal";
//COMPONENTS
//--Modal
import ModalAlert from "../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../components/Tools/Tables/Table/Table";
//--Basics
import BoxContainer from "../../../components/Tools/Cards/BoxContainer/BoxContainer";
import CenterSpinner from "../../../components/Tools/Basics/Spinner";

const TableDetalleTurnos = () => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [resumen, setResumen] = useState([]);
  const [puros, setPuros] = useState([]);
  const [mezclas, setMezclas] = useState([]);
  const [loading, setLoading] = useState(false);
  //GET
  const getOperaciones = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetOperaciones2);
      const data = api_response.data;
      setPuros(data[0]);
      setMezclas(data[1]);
      setResumen(data[2]);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //COLUMNS PUROS
  const columnsPuros = [
    { header: "Turno", field: "TURNO" },
    { header: "Granel", field: "GRANEL" },
    { header: "Embols.", field: "EMBOLSADO" },
    { header: "Bolson", field: "BOLSON" },
    { header: "Líq.", field: "LIQUIDOS" },
  ];
  //COLUMNS MEZCLAS
  const columnsMezclas = [
    { header: "Turno", field: "TURNO" },
    { header: "Granel", field: "GRANEL" },
    { header: "Embols.", field: "EMBOLSADO" },
    { header: "Bolson", field: "BOLSON" },
    { header: "Líq.", field: "LIQUIDOS" },
  ];
  //COLUMNS RESUMEN
  const columnsResumen = [
    { header: "Turno", field: "TURNO" },
    { header: "Granel", field: "GRANEL" },
    { header: "Embols.", field: "EMBOLSADO" },
    { header: "Bolson", field: "BOLSON" },
    { header: "Líq.", field: "LIQUIDOS" },
  ];
  useEffect(() => {
    getOperaciones();
  }, []);
  if (loading) return <CenterSpinner />;
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <BoxContainer>
            <Table
              data={puros}
              columns={columnsPuros}
              title={"PUROS"}
              keyProp={"PUROS"}
            />
          </BoxContainer>
        </div>
        <div className="col-md-6">
          <BoxContainer>
            <Table
              data={mezclas}
              columns={columnsMezclas}
              title={"MEZCLAS"}
              keyProp={"MEZCLAS"}
            />
          </BoxContainer>
        </div>
        <div className="col-md-6">
          <BoxContainer>
            <Table
              data={resumen}
              columns={columnsResumen}
              title={"RESUMEN"}
              keyProp={"RESUMEN"}
            />
          </BoxContainer>
        </div>
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </>
  );
};

export default TableDetalleTurnos;
