import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlGetMaquinas } from "../../../../api/URLs/mantenimiento";
//ROUTER
import { useNavigate } from "react-router-dom";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMULARIO

//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
//--Table
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
//--Basic
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";
import NoWrapContainer from "../../../../components/Tools/Basics/NoWrapContainer";

const TableMaquinas = () => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //useNavigate
  const navigate = useNavigate();
  //States
  const [loading, setLoading] = useState(false);
  const [maquinas, setMaquinas] = useState([]);
  //GET
  const getMaquinas = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetMaquinas);
      setMaquinas(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //BOTONES
  const actions = (maquina) => {
    return (
      <NoWrapContainer>
        <CustomBtn
          type={"link_table"}
          text={"Checklists"}
          onClick={() =>
            navigate(
              `/checklists-cambiar-horas/maquinas/${maquina.id_maquina}`,
              {
                state: { maquina },
              }
            )
          }
        />
      </NoWrapContainer>
    );
  };
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id_maquina" },
    { header: "Id Int.", field: "id_interno" },
    { header: "Tipo", field: "tipo", sortable: true },
    { header: "Marca", field: "marca" },
    { header: "Modelo", field: "modelo" },
    { body: actions },
  ];
  useEffect(() => {
    getMaquinas();
  }, []);
  return (
    <div>
      <TableMain
        data={maquinas}
        title={"Maquinas"}
        columns={columns}
        loading={loading}
        textFilter
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default TableMaquinas;
