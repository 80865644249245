import { useState } from "react";
import "../TableMain/TableMain.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomBtn from "../../Basics/CustomBtn";
import CenterSpinner from "../../Basics/Spinner";
import { cellFormater } from "../../../../functions/cellFormater";
import ExportExcel from "../../../../functions/ExportExcel";
import { exportPdf } from "../../../../functions/pdf/exportPdf";

const TableMain2 = ({
  data,
  columns,
  sortField,
  sortOrder,
  keyProp,
  buttons,
  loading,
  textFilter,
  title,
  noHeader,
  rowClass,
  scrollSize,
  excelExport,
  noStriped,
  pdf,
  compactCells,
}) => {
  //PDF PROPS
  const pdfSave = pdf && pdf.pdfSave ? pdf.pdfSave : "Table";
  const pdfTitle = pdf && pdf.pdfTitle ? pdf.pdfTitle : "";
  const pdfOrientation = pdf && pdf.pdfHorizontal ? "l" : "p";
  const pdfCompact = pdf && pdf.pdfCompact ? "compact" : "";
  //FILTER
  const [search, setSearch] = useState("");
  const filteredData = data.filter((d) => {
    const lowerCasedValue = search.toLowerCase();
    return Object.keys(d).some((key) =>
      d[key]?.toString()?.toLowerCase()?.includes(lowerCasedValue)
    );
  });
  //SCROLL
  const handleScroll = (size) => {
    switch (size) {
      case "md":
        return "medium";
      case "lg":
        return "large";
      case "xl":
        return "large-xl";
      default:
        return "auto";
    }
  };
  return (
    <div>
      {!loading ? (
        <div className="mb-2">
          <div
            className={`${
              !noHeader ? "header-main" : null
            } d-sm-flex justify-content-between`}
          >
            {title ? (
              <p className="fw-bold align-self-end">{title}</p>
            ) : (
              <div />
            )}
            <div className="d-flex">
              {pdf ? (
                <i
                  className="bi bi-filetype-pdf fs-4 me-1 text-danger pointer"
                  onClick={() =>
                    exportPdf(
                      columns,
                      filteredData,
                      pdfSave,
                      pdfTitle,
                      pdfOrientation,
                      pdfCompact
                    )
                  }
                />
              ) : null}
              {excelExport ? (
                <div className="me-1">
                  <ExportExcel excelData={filteredData} />
                </div>
              ) : (
                <div />
              )}
              {buttons ? (
                buttons.map((b, index) => (
                  <div key={index}>
                    <CustomBtn
                      onClick={() => b.click(b.noData ? null : data)}
                      type={b.type}
                      btnType={`${b.styles} btn-sm`}
                      icon={b.icon}
                      text={b.text}
                      disabled={b.disabled}
                    />
                  </div>
                ))
              ) : (
                <div />
              )}
              {textFilter ? (
                <div>
                  <input
                    className={`table-main-filter ${buttons ? "ms-2" : null}`}
                    type="text"
                    id="filter"
                    placeholder="Buscar"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
          <DataTable
            value={filteredData}
            sortField={sortField ? sortField : null}
            sortOrder={sortOrder}
            tableStyle={{ minWidth: "100%" }}
            className={`table-main table-main-th${
              compactCells ? "-sm" : ""
            } table-main-cell${compactCells ? "-sm" : ""} ${
              noStriped ? "" : "custom-striped"
            } table-main-scroll-${handleScroll(scrollSize)}`}
            removableSort
            rowClassName={rowClass}
          >
            {columns.map((col, index) => (
              <Column
                key={keyProp ? keyProp : index}
                field={col.field}
                header={col.header}
                body={
                  col.formater
                    ? (t) => cellFormater(t[col.field], col.formater)
                    : col.body
                }
                sortable={col.sortable ? true : null}
              />
            ))}
          </DataTable>
        </div>
      ) : (
        <CenterSpinner />
      )}
    </div>
  );
};

export default TableMain2;
