//Router
import { useLocation } from "react-router-dom";
//Components
import NavTabs from "../../../components/NavBars/NavTabs/NavTabs";
//Sections
import DetalleVehiculoPersonal from "./components/DetalleVehiculoPersonal";
import CubiertasVehiculoPersonal from "./components/CubiertasVehiculoPersonal";
import EventosVehiculoPersonal from "./components/EventosVehiculoPersonal";

const VehiculosPersonalDetalles = () => {
  const location = useLocation();
  const vehicle = location.state.vehicle;

  const sections = [
    {
      name: "Datos y Vencimientos",
      component: <DetalleVehiculoPersonal vehicle={vehicle} />,
    },
    {
      name: "Eventos",
      component: <EventosVehiculoPersonal vehicle={vehicle} />,
    },
    {
      name: "Cubiertas",
      component: <CubiertasVehiculoPersonal vehicle={vehicle} />,
    },
  ];
  return (
    <div className="custom-container">
      <h2>
        <b>Vehiculo | {vehicle.patente}</b>
      </h2>
      <NavTabs sections={sections} />
    </div>
  );
};

export default VehiculosPersonalDetalles;
