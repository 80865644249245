import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlGetChecklists_x_maquina } from "../../../../api/URLs/mantenimiento";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORM
import FormCambiarHoraChkl from "./FormCambiarHoraChkl";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import PlainModal from "../../../../components/Modals/ModalPlain";
//--Table
import TableMain2 from "../../../../components/Tools/Tables/TableMain2/TableMain2";
//--Basic
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";

const TableChklMaquinas = ({ maquina }) => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [chkls, setChkls] = useState([]);
  const [selectedChkl, setSelectedChkl] = useState(null);
  const [reFetch, setReFetch] = useState(false);
  //--Modal
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //Handle Modal
  const handleModal = (chkl) => {
    setSelectedChkl(chkl);
    setShowModalPlain(true);
  };
  //GET
  const getChkls = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetChecklists_x_maquina, {
        params: {
          id_maquina: maquina.id_maquina,
          id_maquinista: null,
        },
      });
      setChkls(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //BOTONES
  const actions = (chkl) => {
    return (
      <CustomBtn
        type={"link_table"}
        text={"Cambiar horas de uso"}
        onClick={() => handleModal(chkl)}
      />
    );
  };
  //COLUMNAS
  const state = (chkl) => {
    return chkl.chk_cerrada ? (
      "Cerrada"
    ) : (
      <span className="text-danger fw-semibold">Abierta</span>
    );
  };
  const columns = [
    { header: "Id", field: "id_resultado_chk" },
    { header: "Maquinista", field: "maquinista", sortable: true },
    {
      header: "Fecha",
      field: "fec_restulado_chk",
      formater: "date_time",
      sortable: true,
    },
    { header: "Hs uso Inicio", field: "horas_uso" },
    { header: "Hs uso Fin", field: "horas_uso_fin" },
    { header: "Estado", field: "chk_cerrada", body: state },
    { body: actions },
  ];
  useEffect(() => {
    getChkls();
  }, [reFetch]);
  return (
    <div>
      <TableMain2
        data={chkls}
        title={"Listado de Checklists"}
        columns={columns}
        loading={loading}
        textFilter
        sortField={"fec_restulado_chk"}
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <PlainModal show={true} handleClose={handleCloseModalPlain}>
          <FormCambiarHoraChkl
            chkl={selectedChkl}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </PlainModal>
      ) : null}
    </div>
  );
};

export default TableChklMaquinas;
