import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
//--Drivers
import {
  urlCreateDrivers,
  urlEditDrivers,
} from "../../../../api/URLs/choferes";
//--Companies
import { urlGetCompanies } from "../../../../api/URLs/entidadesSecundarias/empresas";
//--Provincias
import {
  urlGetLocalidades,
  urlGetProvincias,
} from "../../../../api/URLs/entidadesSecundarias/ubicaciones";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMIK & YUP
import { Formik } from "formik";
import { driversSchema } from "../../../../schemas/Camiones/Choferes/driversSchema";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import { useAlertModal } from "../../../../hooks/useAlertModal";
//--Basic
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomDropdown from "../../../../components/Tools/Inputs/CustomDropdown";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";

const FormChoferes = ({ driver, reFetch, setReFetch }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
    handleAlertWarning,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanie, setSelectedCompanie] = useState(null);
  //Prov
  const [provincias, setProvincias] = useState([]);
  const [selectedProvincia, setSelectedProvincia] = useState(null);
  //Loc
  const [localidades, setLocalidades] = useState([]);
  const [selectedLocalidad, setSelectedLocalidad] = useState(null);
  const [loadingLocalidades, setloadingLocalidades] = useState(false);
  //Initial Values
  let newValues, updateValues;
  if (driver) {
    updateValues = {
      cuil: driver.cuil ? driver.cuil : "",
      direccion: driver.direccion ? driver.direccion : "",
      codigoPostal: driver.codigopostal ? driver.codigopostal : "",
      telefono: driver.telefono ? driver.telefono : "",
      correo: driver.correo ? driver.correo : "",
      dateRevMedica: driver.dateRevMedica
        ? driver.dateRevMedica.slice(0, 10)
        : "",
      dateCargaGral: driver.dateCargaGral
        ? driver.dateCargaGral.slice(0, 10)
        : "",
      dateCargaPeligrosa: driver.dateCargaPeligrosa
        ? driver.dateCargaPeligrosa.slice(0, 10)
        : "",
      dateLicConducir: driver.dateLicConducir
        ? driver.dateLicConducir.slice(0, 10)
        : "",
      dateCredPuerto: driver.dateCredPuerto
        ? driver.dateCredPuerto.slice(0, 10)
        : "",
      apelnomb: driver.apelnomb ? driver.apelnomb : "",
      num_cred_puerto: driver.num_cred_puerto ? driver.num_cred_puerto : "",
    };
  } else {
    newValues = {
      cuil: "",
      direccion: "",
      codigoPostal: "",
      telefono: "",
      correo: "",
      dateRevMedica: "",
      dateCargaGral: "",
      dateCargaPeligrosa: "",
      dateLicConducir: "",
      dateCredPuerto: "",
      apelnomb: "",
      num_cred_puerto: "",
    };
  }
  const initialValues = driver ? updateValues : newValues;
  //VALIDACIONES (dropdowns)
  const validateOptions = () => {
    if (!selectedCompanie) {
      handleAlertWarning("No se ha seleccionado empresa");
      return false;
    }
    if (!selectedProvincia) {
      handleAlertWarning("No se ha seleccionado provincia");
      return false;
    }
    if (!selectedLocalidad) {
      handleAlertWarning("No se ha seleccionado localidad");
      return false;
    }
    return true;
  };
  //POST - PUT de choferes
  const handleSubmit = async (values) => {
    const validations = validateOptions();
    if (!validations) return;
    Object.keys(values).forEach((key) => {
      if (values[key] === "") {
        values[key] = null;
      }
    });
    const merged = {
      ...values,
      id_razonsocial: selectedCompanie,
      idLocalidad: selectedLocalidad,
    };
    try {
      setSendLoading(true);
      let api_response;
      if (!driver) {
        api_response = await axiosWithAuth.post(urlCreateDrivers, merged);
      } else {
        api_response = await axiosWithAuth.put(urlEditDrivers, {
          ...merged,
          id: driver.id_chofer,
        });
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  /*
    GET - Provincias / Empresas
  */
  const getProvAndCompanies = async () => {
    setLoading(true);
    const promise_array = [];
    promise_array.push(axiosWithAuth.get(urlGetCompanies));
    if (!provincias.length > 0)
      promise_array.push(axiosWithAuth.get(urlGetProvincias));
    try {
      const api_responses = await Promise.all(promise_array);
      const [companies_response, provincias_response] = api_responses;
      setCompanies(companies_response.data);
      if (provincias_response) setProvincias(provincias_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //GET - Localidades
  const getlocalidades = async (id_provincia) => {
    try {
      setloadingLocalidades(true);
      const api_response = await axiosWithAuth.get(
        `${urlGetLocalidades}/${id_provincia}`
      );
      setLocalidades(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setloadingLocalidades(false);
    }
  };
  //HANDLE DROPDOWS
  const handleSelectCompanie = async (c) => {
    setSelectedCompanie(c.id);
  };
  const handleSelectProvincia = async (p) => {
    setSelectedProvincia(p.id);
    getlocalidades(p.id);
  };
  const handleSelectLocalidad = async (l) => {
    setSelectedLocalidad(l.id);
  };
  //HANDLE IDs ON EDIT
  const handleEdit = () => {
    if (driver) {
      setSelectedCompanie(driver.id_razonsocial);
      setSelectedLocalidad(driver.idLocalidad);
      setSelectedProvincia(driver.nombrecorto);
    }
  };
  useEffect(() => {
    getProvAndCompanies();
    handleEdit();
  }, []);
  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      <FormTitle prop={driver} text={"Chofer"} />
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        validationSchema={driversSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="shadow-none">
            <div className="row">
              <div className="col-md-6">
                {/* APELLIDO Y NOMBRE */}
                <FormGroup>
                  <Form.Label>Apellido y nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Apellido y Nombre"
                    name="apelnomb"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.apelnomb}
                    isInvalid={
                      errors.apelnomb && touched.apelnomb && errors.apelnomb
                    }
                  />
                </FormGroup>
                {/* EMPRESA */}
                <Form.Label>Empresa</Form.Label>
                <CustomDropdown
                  options={companies}
                  onSelect={handleSelectCompanie}
                  defaultOption={driver ? driver : null}
                  displayProperty={"nomb_empresa"}
                  btnName={"Seleccione una empresa"}
                />
                {/* PROVINCIA */}
                <Form.Label>Provincia</Form.Label>
                <CustomDropdown
                  options={provincias}
                  onSelect={handleSelectProvincia}
                  displayProperty={"nombrecorto"}
                  defaultOption={driver ? driver : null}
                  btnName={"Seleccione una provincia"}
                />
                {/* LOCALIDAD */}
                {loadingLocalidades ? (
                  <p className="text-center">Cargando localidades...</p>
                ) : selectedProvincia ? (
                  <>
                    <Form.Label>Localidad</Form.Label>
                    <CustomDropdown
                      options={localidades}
                      onSelect={handleSelectLocalidad}
                      displayProperty={"nombre"}
                      defaultOption={driver ? driver : null}
                      btnName={"Seleccione una localidad"}
                    />
                  </>
                ) : null}

                {/* CORREO */}
                <FormGroup>
                  <Form.Label>Mail</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Direccion de correo"
                    name="correo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.correo}
                  />
                </FormGroup>
                {/* DIRECCION */}
                <FormGroup>
                  <Form.Label>Dirección</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Direccion"
                    name="direccion"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.direccion}
                    isInvalid={
                      errors.direccion && touched.direccion && errors.direccion
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.direccion && touched.direccion && errors.direccion}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* CODIGO POSTAL */}
                <FormGroup>
                  <Form.Label>Codigo postal</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Codigo postal"
                    name="codigoPostal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.codigoPostal}
                    isInvalid={
                      errors.codigoPostal &&
                      touched.codigoPostal &&
                      errors.codigoPostal
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.codigoPostal &&
                      touched.codigoPostal &&
                      errors.codigoPostal}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* NUMERO CREDENCIAL PUERTO */}
                <FormGroup>
                  <Form.Label>Numero de Credencial Puerto</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Nº de Credencial Puerto"
                    name="num_cred_puerto"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.num_cred_puerto}
                    isInvalid={
                      errors.num_cred_puerto &&
                      touched.num_cred_puerto &&
                      errors.num_cred_puerto
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.num_cred_puerto &&
                      touched.num_cred_puerto &&
                      errors.num_cred_puerto}
                  </Form.Control.Feedback>
                </FormGroup>
              </div>
              <div className="col-md-6">
                {/* CUIL */}
                <FormGroup>
                  <Form.Label>DNI</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Cuil"
                    name="cuil"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cuil}
                    isInvalid={errors.cuil && touched.cuil && errors.cuil}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cuil && touched.cuil && errors.cuil}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* TELEFONO */}
                <FormGroup>
                  <Form.Label>Telefono</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Telefono"
                    name="telefono"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.telefono}
                    isInvalid={
                      errors.telefono && touched.telefono && errors.telefono
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.telefono && touched.telefono && errors.telefono}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* LIC CONDUCIR */}
                <FormGroup>
                  <Form.Label>Lic. de Conducir</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateLicConducir"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.dateLicConducir}
                  />
                </FormGroup>
                {/* CRED PUERTO */}
                <FormGroup>
                  <Form.Label>Credencial Puerto</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateCredPuerto"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.dateCredPuerto}
                  />
                </FormGroup>
                {/* REV MEDICA */}
                <FormGroup>
                  <Form.Label>Rev Medica</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateRevMedica"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.dateRevMedica}
                  />
                </FormGroup>
                {/* CARGA GENERAL */}
                <FormGroup>
                  <Form.Label>Carga general</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateCargaGral"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.dateCargaGral}
                  />
                </FormGroup>
                {/* CARGA PELIGROSA */}
                <FormGroup>
                  <Form.Label>Carga peligrosa</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateCargaPeligrosa"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.dateCargaPeligrosa}
                  />
                </FormGroup>
              </div>
              <div className="col-md-6 offset-md-3">
                <ButtonSubmit
                  prop={driver}
                  isSubmitting={isSubmitting}
                  loading={sendLoading}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormChoferes;
