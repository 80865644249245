import React from "react";
//NavTabs
import NavTabs from "../../../components/NavBars/NavTabs/NavTabs";
//Components
import TableOperativos from "./components/index/TableOperativos";
import TableTeams from "./components/index/TableTeams";
import FormCompanies from "../EntidadesSecundarias/Empresas/FormCompanies";
import FormProducts from "../EntidadesSecundarias/Productos/FormProducts";
import FormPlaces from "../EntidadesSecundarias/Ubicaciones/FormPlaces";
import TableConfigs from "./components/index/TableConfigs";

const Operativos = () => {
  const sections = [
    { name: "Operativos", component: <TableOperativos /> },
    { name: "Configuración", component: <TableConfigs /> },
    { name: "Equipos", component: <TableTeams /> },
    { name: "Empresas", component: <FormCompanies /> },
    { name: "Productos", component: <FormProducts /> },
    { name: "Ubicaciones", component: <FormPlaces /> },
  ];
  return (
    <div className="custom-container">
      <h3>
        <b>Operativos Terminales y Servicios</b>
      </h3>
      <NavTabs sections={sections} />
    </div>
  );
};

export default Operativos;
