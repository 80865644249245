import "./CustomCard.css";

const CustomCard = ({ title, img, onClick, desc, noHover }) => {
  return (
    <div className="m-2 cursor-pointer" onClick={onClick}>
      <div className={`card card-container ${!noHover ? "card-hover" : null}`}>
        <img src={img} alt="img" height={150} />
        <div className="card-body">
          <h5 className="card-title text-center fw-semibold">{title}</h5>
          {desc ? <div>{desc}</div> : null}
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
