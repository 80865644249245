import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetCatMovements,
  urlCreateDriverCashMovement,
} from "../../../../api/URLs/choferes";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMIK & YUP
import { Formik } from "formik";
import { CashMovementsSchema } from "../../../../schemas/Camiones/Choferes/cashMovementsSchema";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import { useAlertModal } from "../../../../hooks/useAlertModal";
//--Basic
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomDropdown from "../../../../components/Tools/Inputs/CustomDropdown";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";

const FormCashMov = ({ driver, reFetch, setReFetch }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [catMovements, setCatMovements] = useState([]);
  const [selectedCatMov, setSelectedCatMov] = useState(null);
  //Initial Values
  const initialValues = {
    ingreso: "",
    egreso: "",
    obs: "",
    nro_punto_venta: "",
    nro_comprobante: "",
    proveedor: "",
    fecha: "",
  };
  //GET Cash Movs
  const getCatsMovements = async () => {
    setLoading(true);
    try {
      const api_response = await axiosWithAuth.get(urlGetCatMovements);
      setCatMovements(api_response.data);
      setSelectedCatMov(null);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //POST
  const handleSubmit = async (values) => {
    Object.keys(values).forEach((key) => {
      if ((key === "ingreso" || key === "egreso") && values[key] === "") {
        values[key] = null;
      }
    });
    const merged = {
      ...values,
      id_movimiento: selectedCatMov ? selectedCatMov : 17,
      id_chofer: driver.id_chofer,
    };
    try {
      setSendLoading(true);
      const api_response = await axiosWithAuth.post(
        urlCreateDriverCashMovement,
        merged
      );
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  useEffect(() => {
    getCatsMovements();
  }, []);
  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        validationSchema={CashMovementsSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="shadow-none">
            <div className="row">
              <section className="col-md-6">
                {/* INGRESO */}
                <FormGroup>
                  <Form.Label>Ingreso</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Valor a registrar"
                    name="ingreso"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ingreso}
                  />
                </FormGroup>
                {/* EGRESO */}
                <FormGroup>
                  <Form.Label>Egreso</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Valor a registrar"
                    name="egreso"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.egreso}
                  />
                </FormGroup>
                {/* NUMERO PUNTO VENTA */}
                <FormGroup>
                  <Form.Label>Punto de venta</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Numero de punto de venta"
                    name="nro_punto_venta"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nro_punto_venta}
                  />
                </FormGroup>
                {/* NUMERO COMPROBANTE */}
                <FormGroup>
                  <Form.Label>Comprobante</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Numero de comprobante"
                    name="nro_comprobante"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nro_comprobante}
                  />
                </FormGroup>
              </section>
              <section className="col-md-6">
                {/* MOVIMIENTO */}
                <Form.Label>Movimiento</Form.Label>
                <CustomDropdown
                  options={catMovements}
                  onSelect={(p) => setSelectedCatMov(p.id)}
                  displayProperty={"descripcion"}
                  btnName={"Seleccione un movimiento"}
                />
                {/* PROVEEDOR */}
                <FormGroup>
                  <Form.Label>Proveedor</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese proveedor"
                    name="proveedor"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.proveedor}
                  />
                </FormGroup>
                {/* FECHA*/}
                <FormGroup>
                  <Form.Label>Fecha</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fecha}
                    isInvalid={errors.fecha && touched.fecha && errors.fecha}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fecha && touched.fecha && errors.fecha}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* OBSERVACIONES */}
                <FormGroup>
                  <Form.Label>Observaciones *opcional*</Form.Label>
                  <Form.Control
                    type="text"
                    name="obs"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.obs}
                  />
                </FormGroup>
              </section>
              <div className="col-md-6 offset-md-3">
                <ButtonSubmit
                  isSubmitting={isSubmitting}
                  loading={sendLoading}
                  text={"Enviar"}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormCashMov;
