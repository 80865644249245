import TableCabeceras from "./components/TableCabeceras";
import TableItems from "./components/TableItems";
import NavTabs from "../../../../components/NavBars/NavTabs/NavTabs";

const CreateChecklist = () => {
  const sections = [
    {name: "Cabeceras", component: <TableCabeceras/>},
    {name: "Items", component: <TableItems/>},
  ]
  return (
    <div className="custom-container">
      <h2>
        <b>Crear Checklists Terminales y Servicios</b>
      </h2>
      <NavTabs sections={sections} />
    </div>
  );
};

export default CreateChecklist;
