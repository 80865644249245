import TableCE from "./components/TableCE";
import TableEventos from "./components/TableEventos";
import TableCorreos from "./components/TableCorreos";

const Correos = () => {
  return (
    <div className="custom-container">
      <div className="row">
        <div className="col-6">
          <TableEventos />
        </div>
        <div className="col-6">
          <TableCorreos />
        </div>
      </div>
      <TableCE />
    </div>
  );
};

export default Correos;
