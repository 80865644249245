//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlCorreos } from "../../../../api/URLs/correos";
//HOOKS
import { useAlertModal } from "../../../../hooks/useAlertModal";
//FORMIK
import { Formik, Form } from "formik";
//COMPONENTS
//--Modal
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
//--Basic
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";
import CustomInput from "../../../../components/Tools/Inputs/CustomInput";

const FormCorreo = ({ correo, reFetch, setReFetch }) => {
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //Initial Values
  let newValues, updateValues;
  if (correo) {
    updateValues = {
      id: correo.id,
      correo: correo.mail ? correo.mail : "",
    };
  } else {
    newValues = {
      correo: "",
    };
  }
  const initialValues = correo ? updateValues : newValues;
  //POST - PUT
  const handleSubmit = async (values) => {
    console.log(values)
    try {
      let api_response;
      if (!correo) {
        api_response = await axiosWithAuth.post(urlCorreos, values);
      } else {
        api_response = await axiosWithAuth.put(urlCorreos, values);
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    }
  };
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <FormTitle prop={correo} text={"Correo"} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <CustomInput
              name={"correo"}
              label={"Correo electronico"}
              placeholder={"Ingresar correo"}
              loading={isSubmitting}
            />
            <ButtonSubmit prop={correo} loading={isSubmitting} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormCorreo;
