import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlCorreos } from "../../../../api/URLs/correos";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORM
import FormCorreo from "../forms/FormCorreo";
//COMPONENTS
//--modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import ModalPlain from "../../../../components/Modals/ModalPlain";
//--table
import Table from "../../../../components/Tools/Tables/Table/Table";
import BoxContainer from "../../../../components/Tools/Cards/BoxContainer/BoxContainer";
//--basic
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";

const TableCorreos = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [selectedCorreo, setSelectedCorreo] = useState(null);
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  const handleCorreo = (c) => {
    if (c) {
      setSelectedCorreo(c);
    } else {
      setSelectedCorreo(null);
    }
    setShowModalPlain(true);
  };
  //COLUMNAS
  const actionButton = (c) => {
    return <CustomBtn type={"edit_table"} onClick={() => handleCorreo(c)} />;
  };
  const columns = [
    { field: "id", header: "Id" },
    { field: "mail", header: "Correos" },
    { body: actionButton },
  ];
  const buttons = [
    {
      type: "plus_table_header",
      click: () => handleCorreo(),
      noData: true,
    },
  ];
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const api_response = await axiosWithAuth.get(urlCorreos);
        setData(api_response.data);
      } catch (error) {
        const auxError = error.response.data.msj
          ? error.response.data.msj
          : "Error del servidor";
        handleError(auxError);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [reFetch]);
  return (
    <div>
      <BoxContainer maxSize={"sm"} minSize={"sm"}>
        <Table
          data={data}
          columns={columns}
          buttons={buttons}
          loading={loading}
          title={"Correos"}
          scrollSize={"sm"}
          textFilter
        />
      </BoxContainer>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain}>
          <FormCorreo
            correo={selectedCorreo}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default TableCorreos;
