import { useField } from "formik";
import { FormGroup, Form } from "react-bootstrap";

const CustomInput = ({
  placeholder,
  label,
  loading,
  text,
  children,
  typeInput,
  className,
  ...props
}) => {
  const [field, meta] = useField({ ...props });
  //console.log(field)
  return (
    <FormGroup className={className}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        {...field}
        {...props}
        placeholder={placeholder}
        isInvalid={meta.error && meta.touched && meta.error}
        disabled={loading ? true : false}
        type={typeInput ? typeInput : null}
      />
      <Form.Control.Feedback type="invalid">
        {meta.touched && meta.error ? meta.error : null}
      </Form.Control.Feedback>
    </FormGroup>
  );
};

export default CustomInput;
