import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetProducts,
  urlCreateProducts,
  urlEditProducts,
} from "../../../../api/URLs/entidadesSecundarias/productos";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMIK & YUP
import { Formik } from "formik";
import { productsSchema } from "../../../../schemas/Camiones/EntidadesSecundarias/productsSchema";
//BOOTSTRAP
import { Button, Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../../components/Tools/Tables/Table/Table";
//--Basic
import BoxContainer from "../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";
const FormProducts = () => {
  //useModal
  const {
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleError,
    handleSucces,
  } = useModal();
  //States
  const [reForm, setReForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  //INITIAL VALUES
  let newValues, updateValues;
  if (product) {
    updateValues = {
      desc_product: product.desc_producto ? product.desc_producto : "",
    };
  } else {
    newValues = {
      desc_product: "",
    };
  }
  const initialValues = product ? updateValues : newValues;
  //GET
  const getProducts = async () => {
    try {
      setLoadingTable(true);
      const api_response = await axiosWithAuth.get(urlGetProducts);
      setProducts(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoadingTable(false);
    }
  };
  //POST - PUT
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      let api_response;
      if (!product) {
        api_response = await axiosWithAuth.post(urlCreateProducts, values);
      } else {
        api_response = await axiosWithAuth.put(urlEditProducts, {
          ...values,
          id: product.id,
        });
      }
      handleSucces(api_response);
      setReForm(!reForm);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setProduct(null);
      setLoading(false);
    }
  };
  //HANDLE EDIT
  const handleEdit = (p) => {
    setProduct(p);
    setReForm(!reForm);
  };
  //BOTONES
  const actions = (p) => {
    return (
      <CustomBtn
        type={"edit_table"}
        onClick={() => handleEdit(p)}
      />
    );
  };
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id" },
    { header: "Producto", field: "desc_producto" },
    { header: "Editar", body: actions },
  ];
  useEffect(() => {
    getProducts();
  }, [reForm]);
  return (
    <div className="row">
      <div className="col-md-8">
        <BoxContainer maxSize={"lg"} minSize={"lg"}>
          <Table
            data={products}
            columns={columns}
            keyProp={"id"}
            title={"Productos"}
            scrollSize={"lg"}
            textFilter
            loading={loadingTable}
          />
        </BoxContainer>
      </div>
      <div className="col-md-4">
        {!loading ? (
          <>
            <div
              className={`alert alert-${product ? "success" : "primary"} w-100`}
              role="alert"
            >
              {`${product ? "Modificar" : "Agregar nuevo"} Producto ${
                product ? product.desc_producto : ""
              }`}
            </div>
            <Formik
              enableReinitialize
              validationSchema={productsSchema}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit} className="shadow-none mb-2">
                  {/* UBICACION */}
                  <FormGroup>
                    <Form.Control
                      type="text"
                      placeholder="Ingresar producto"
                      name="desc_product"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.desc_product}
                      isInvalid={
                        errors.desc_product &&
                        touched.desc_product &&
                        errors.desc_product
                      }
                    />
                  </FormGroup>
                  <Button
                    variant={product ? "success" : "primary"}
                    className="mt-3 w-100"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {product ? "Modificar" : "Agregar"}
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <CenterSpinner />
        )}
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormProducts;
