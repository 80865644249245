import { useEffect, useState } from "react";
//Tables
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
//API ROUTES
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlGetTnsLargaDist } from "../../../../api/URLs/viajes";
//Basics
import Divider from "../../../../components/Tools/Basics/Divider";
//Modal
import { useModal } from "../../../../hooks/useModal";
import ModalAlert from "../../../../components/Modals/ModalAlert";

const KmViajesLargaDist = () => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [fetch, reFetch] = useState(false);

  //Handle Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDates((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  //Get data
  const getData = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetTnsLargaDist, {
        params: {
          fecha1: dates.startDate,
          fecha2: dates.endDate,
        },
      });
      setData(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Submit and fetch data
  const handleSubmit = (e) => {
    e.preventDefault();
    reFetch(true);
  };
  //Clase de fila
  const rowClass = (data) => {
    if (data.camion_es_propio) {
      return "bg-success bg-opacity-50 text-white fw-bold";
    } else {
      return "bg-danger bg-opacity-50 text-white fw-bold";
    }
  };
  //Show data
  const columns = [
    { field: "chofer", header: "Chofer" },
    { field: "camion", header: "Camion" },
    {
      field: "camion_es_propio",
      header: "Prop.",
      formater: "boolean",
      pdfFormat: "boolean",
      sortable: true,
    },
    { field: "acoplado", header: "Batea" },
    { field: "operativo", header: "Operativo" },
    { field: "id_tipo_operativo", header: "Tipo.Op" },
    { field: "numero_proforma", header: "Prof." },
    {
      field: "fecha_viaje",
      header: "F. Viaje",
      formater: "date",
      pdfFormat: "date",
      sortable: true,
    },
    { field: "horas_de_viaje", header: "Hs Viaje" },
    { field: "toneladas", header: "Tons." },
    { field: "km_recorridos", header: "Km" },
    {
      field: "es_larga_dist",
      header: "Larga dis.",
      formater: "boolean",
      pdfFormat: "boolean",
      sortable: true,
    },
    { field: "cant_controles_descarga", header: "Ctrl Desc." },
    { field: "cliente", header: "Cliente" },
    { field: "desc_tarifa", header: "Tipo Tar." },
    { field: "tarifa", header: "Tarifa", formater: "priece" },
    { field: "tarifa_chofer", header: "Tar. Chof.", formater: "priece" },
    { field: "cant_viajes", header: "Cant. Viajes" },
    { field: "observaciones", header: "Obs" },
  ];
  useEffect(() => {
    if (fetch) {
      getData();
      reFetch(false);
    }
  }, [fetch]);
  return (
    <div className="custom-container">
      <h2>
        <b>Km Viajes Larga Distancia</b>
      </h2>
      <Divider />
      <form className="mb-3" onSubmit={handleSubmit}>
        <div className="d-flex align-items-end">
          <div>
            <span>Fecha Inicio:</span>
            <input
              type="date"
              name="startDate"
              className="border rounded"
              style={{ height: "38px" }}
              value={dates.startDate}
              onChange={handleChange}
            ></input>
          </div>
          <div className="mx-2">
            <span>Fecha Fin:</span>
            <input
              type="date"
              name="endDate"
              className="border rounded"
              style={{ height: "38px" }}
              value={dates.endDate}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn btn-primary px-3">
            Filtrar
          </button>
        </div>
      </form>
      <TableMain
        data={data}
        columns={columns}
        keyProp={"id"}
        textFilter
        title={"Historial"}
        loading={loading}
        rowClass={rowClass}
        excelExport
        pdf={{
          pdfSave: `Historial-Viajes-${dates.startDate}-a-${dates.endDate}`,
          pdfTitle: `Historial Viajes de: ${dates.startDate} hasta: ${dates.endDate}`,
          pdfHorizontal: true,
          pdfCompact: true,
        }}
        compactCells
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default KmViajesLargaDist;
