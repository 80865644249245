import { useEffect, useState } from "react";
//Api
import { axiosWithAuth } from "../../../api/axiosConfig";
import { urlGetChecklistCabecera } from "../../../api/URLs/mantenimiento";
//Hooks
import { useLocation } from "react-router-dom";
import { dateSQLformat } from "../../../functions/dateSQLformat";
import { useModal } from "../../../hooks/useModal";
//Components
import DetailsChecklist from "../Checklists/DetailsChecklist";
import ModalAlert from "../../../components/Modals/ModalAlert";
import CustomBtn from "../../../components/Tools/Basics/CustomBtn";
import CenterSpinner from "../../../components/Tools/Basics/Spinner";
import CustomCard from "../Checklists/EnviarChecklist/extra/CustomCard";
//--Img
import auto from "../../../assets/Autoelevadora.png";
import pala from "../../../assets/pala.png";
import retro from "../../../assets/retro.png";

const MaqDetalleChecklist = () => {
  //Location
  const location = useLocation();
  const id_checklist = location.state.id_checklist;
  const id_maquina = location.state.id_maquina;
  const [idChecklist, setIdChecklist] = useState(id_checklist);
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  const [data, setData] = useState([]);
  const [maqChkls, setMaqChkls] = useState([]);
  const [reFetch, setReFetch] = useState(false);
  const [currentChklIndex, setCurrentChklIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const Desc = () => {
    if (!data) return null;
    const fecha = data.fecha_fin ? data.fecha_fin : data.fecha_inicio;
    return (
      <div className="w-100">
        <p className="fw-semibold fs-5">{data.maquina_identificador}</p>
        <p className="text-end fw-light">{dateSQLformat(fecha, "addTime")}</p>
        <p className="text-end fs-sm fw-light">{data.maquinista}</p>
      </div>
    );
  };
  const InfoCard = ({ info, title, className }) => {
    return (
      <p className={`fs-sm py-1 border-bottom ${className}`}>
        {title}: <span className="fw-semibold">{info}</span>
      </p>
    );
  };
  const infoCardArr = [
    {
      title: "Estado",
      info: data.chk_cerrada ? "Cerrada" : "Abierta",
      className: "mt-1",
    },
    {
      title: "Horas de uso",
      info: `${data.horas_uso_fin ? data.horas_uso_fin : data.horas_uso}hs`,
    },
    {
      title: "Maq. Operativa",
      info: data.maq_operativa_fin
        ? "Si"
        : data.maq_operativa_ini
        ? "Si"
        : "No",
    },
    {
      title: "Mecanico (abrir)",
      info: data.mecanico_abrio_chk ? data.mecanico_abrio_chk : "No definido",
    },
    {
      title: "Mecanico (cerrar)",
      info: data.mecanico_cerro_chk ? data.mecanico_cerro_chk : "No definido",
    },
    {
      title: "Tipo de chkl",
      info: data.tipo_checklist,
      className: "mb-1",
    },
  ];
  //funcion para cambiar a la siguiente/anterior chkl en lista
  const handleChangeChkl = (action) => {
    const newIdChecklist =
      action === "next"
        ? maqChkls[currentChklIndex + 1].id_result_chk
        : maqChkls[currentChklIndex - 1].id_result_chk;
    setIdChecklist(newIdChecklist);
    setReFetch(!reFetch);
  };
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const promise_array = [];
        promise_array.push(
          axiosWithAuth.get(urlGetChecklistCabecera, {
            params: {
              id_result_chk: idChecklist,
            },
          })
        );
        promise_array.push(
          axiosWithAuth.get(`/api/maquina/${id_maquina}/resultchecklist`)
        );
        const api_responses = await Promise.all(promise_array);
        const [data_response, maq_chkls_response] = api_responses;
        setData(data_response.data[0]);
        setMaqChkls(maq_chkls_response.data);
        //Guardamos la ubicacion/index del elemento actual en el listado de chkls posibles para la maquina
        const currentIndex = maq_chkls_response.data.findIndex(
          (obj) => obj.id_result_chk === idChecklist
        );
        setCurrentChklIndex(currentIndex);
      } catch (error) {
        const auxError = error.response.data.msj
          ? error.response.data.msj
          : "Error del servidor";
        handleError(auxError);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [reFetch]);
  if (loading) return <CenterSpinner />;
  return (
    <div className="custom-container row">
      <div className="col-lg-4 col-12">
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-between align-items-center">
            <CustomBtn
              icon={"bi bi-chevron-left"}
              btnType={"secondary p-1"}
              onClick={() => handleChangeChkl("back")}
              disabled={currentChklIndex < 1}
            />
            <CustomCard
              img={
                data.id_tipo_maquina === 1
                  ? auto
                  : data.id_tipo_maquina === 2
                  ? pala
                  : retro
              }
              desc={<Desc />}
              noHover
            />
            <CustomBtn
              icon={"bi bi-chevron-right"}
              btnType={"secondary p-1"}
              onClick={() => handleChangeChkl("next")}
              disabled={maqChkls.length === currentChklIndex + 1}
            />
          </div>
        </div>
        <div className="card my-2">
          <div className="px-2">
            {infoCardArr.map((item, i) => {
              return (
                <InfoCard
                  key={i}
                  title={item.title}
                  info={item.info}
                  className={item.className ? item.className : ""}
                />
              );
            })}
          </div>
        </div>
        <div className="card my-2">
          <p className="card-header fw-semibold">Observaciones</p>
          <div className="card-body">
            <p className="py-1">
              {data.otras_obs
                ? data.otras_obs
                : "No se ingresaron observaciones"}
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-8 col-12">
        {data.length === 0 ? null : (
          <DetailsChecklist id_cheklist={idChecklist} />
        )}
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default MaqDetalleChecklist;
