import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlGetPlaces } from "../../../../api/URLs/entidadesSecundarias/ubicaciones";
import { urlGetProducts } from "../../../../api/URLs/entidadesSecundarias/productos";
import { urlGetCompanies } from "../../../../api/URLs/entidadesSecundarias/empresas";
import {
  urlCreateTrips,
  urlEditTrips,
  urlGetFareTypes,
} from "../../../../api/URLs/viajes";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
import { useAlertModal } from "../../../../hooks/useAlertModal";
//FORMIK & YUP
import { Formik } from "formik";
import { tripSchema } from "../../../../schemas/Camiones/Trips/tripSchema";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
//--Basic
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomDropdown from "../../../../components/Tools/Inputs/CustomDropdown";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";

const FormViajes = ({ trip, operativo, reFetch, setReFetch }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  //Products
  const [products, setproducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  //Fare Types
  const [fareTypes, setFareTypes] = useState([]);
  const [selectedFareType, setSelectedFareType] = useState(null);
  //Clients
  const [clients, setClients] = useState([]);
  //--client
  const [selectedClient, setSelectedClient] = useState(null);
  //Places
  const [places, setPlaces] = useState([]);
  const [selectedPlaceO, setSelectedPlaceO] = useState(null);
  const [selectedPlaceD, setSelectedPlaceD] = useState(null);
  //Initial Values
  let newValues, updateValues;
  if (trip) {
    updateValues = {
      tarifa: trip.tarifa ? trip.tarifa : "",
      obs: trip.obs ? trip.obs : "",
      fecha_inicio: trip.fecha_inicio ? trip.fecha_inicio.slice(0, 10) : "",
      fecha_fin: trip.fecha_fin ? trip.fecha_fin.slice(0, 10) : "",
      tarifa_chofer: trip.tarifa_chofer ? trip.tarifa_chofer : "",
      ctrl_op_saf: trip.ctrl_op_saf ? trip.ctrl_op_saf : "",
    };
  } else {
    newValues = {
      tarifa: "",
      obs: "",
      fecha_inicio: "",
      fecha_fin: "",
      tarifa_chofer: "",
      ctrl_op_saf: "",
    };
  }
  const initialValues = trip ? updateValues : newValues;
  //POST - PUT de Viajes
  const handleSubmit = async (values) => {
    Object.keys(values).forEach((key) => {
      if (key === "fecha_fin" && values[key] === "") {
        values[key] = null;
      }
    });
    const merged = {
      ...values,
      id_tipo_tarifa: selectedFareType,
      id_ubicacion_origen: selectedPlaceO,
      id_ubicacion_destino: selectedPlaceD,
      id_producto: selectedProduct,
      id_cliente: selectedClient,
      id_operativo: operativo.id,
    };
    try {
      setSendLoading(true);
      let api_response;
      if (!trip) {
        api_response = await axiosWithAuth.post(urlCreateTrips, merged);
      } else {
        api_response = await axiosWithAuth.put(urlEditTrips, {
          ...merged,
          id: trip.id,
        });
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  //GET - places / tipo tarifa
  const getParallel = async () => {
    setLoading(true);
    const promise_array = [];
    promise_array.push(axiosWithAuth.get(urlGetFareTypes));
    promise_array.push(axiosWithAuth.get(urlGetPlaces));
    promise_array.push(axiosWithAuth.get(urlGetProducts));
    promise_array.push(axiosWithAuth.get(urlGetCompanies));
    try {
      const api_responses = await Promise.all(promise_array);
      const [
        fareTypes_response,
        places_response,
        products_response,
        companies_response,
      ] = api_responses;
      setFareTypes(fareTypes_response.data);
      setPlaces(places_response.data);
      setproducts(products_response.data);
      setClients(companies_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //HANDLE DROPDOWS
  const handleSelectFareType = async (f) => {
    setSelectedFareType(f.id);
  };
  const handleSelectProduct = async (p) => {
    setSelectedProduct(p.id);
  };
  const handleSelectPlace = async (p, selector) => {
    if (selector === "o") {
      setSelectedPlaceO(p.id);
    } else {
      setSelectedPlaceD(p.id);
    }
  };
  //HANDLE EDIT
  const handleEdit = () => {
    if (trip) {
      setSelectedFareType(trip.id_tipo_tarifa);
      setSelectedPlaceO(trip.id_ubi_origen);
      setSelectedPlaceD(trip.id_ubi_destino);
      setSelectedProduct(trip.id_producto);
      setSelectedClient(trip.id_cliente);
    }
  };
  //console.log(clients)
  useEffect(() => {
    getParallel();
    handleEdit();
  }, []);
  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      <FormTitle prop={trip} text={"Viaje"} />
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={tripSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          isSubmitting,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit} className="shadow-none">
            <div className="row">
              <section className="col-lg-6">
                {/* UBICACION */}
                <Form.Label>Ubicación Incio</Form.Label>
                <CustomDropdown
                  options={places}
                  onSelect={(p) => handleSelectPlace(p, "o")}
                  displayProperty={"desc_place"}
                  btnName={"Seleccione una ubicación"}
                  defaultOption={trip ? { desc_place: trip.ubi_origen } : null}
                />
                {/* UBICACION */}
                <Form.Label>Ubicación Final</Form.Label>
                <CustomDropdown
                  options={places}
                  onSelect={(p) => handleSelectPlace(p, "d")}
                  displayProperty={"desc_place"}
                  btnName={"Seleccione una ubicación"}
                  defaultOption={trip ? { desc_place: trip.ubi_destino } : null}
                />
                {/* TIPO TARIFA */}
                <Form.Label>Tipo de Tarifa</Form.Label>
                <CustomDropdown
                  options={fareTypes}
                  onSelect={handleSelectFareType}
                  displayProperty={"desc_tarifa"}
                  btnName={"Seleccione tipo de tarifa"}
                  defaultOption={
                    trip ? { desc_tarifa: trip.desc_tarifa } : null
                  }
                />
                {/* TARIFA */}
                <FormGroup>
                  <Form.Label>Tarifa</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ingrese valor de la tarifa"
                    name="tarifa"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tarifa}
                  />
                </FormGroup>
                {/* TARIFA CHOFER */}
                <FormGroup>
                  <Form.Label>Tarifa Chofer</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ingrese la tarifa de chofer"
                    name="tarifa_chofer"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tarifa_chofer}
                  />
                </FormGroup>
                {/* CTRL OPERATIVO SAF */}
                <FormGroup>
                  <Form.Label>CTRL OPERATIVO SAF</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ingrese valor numerico"
                    name="ctrl_op_saf"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ctrl_op_saf}
                  />
                </FormGroup>
              </section>
              <section className="col-lg-6">
                {/* PRODUCTO */}
                <Form.Label>Producto</Form.Label>
                <CustomDropdown
                  options={products}
                  onSelect={handleSelectProduct}
                  displayProperty={"desc_producto"}
                  btnName={"Seleccione un producto"}
                  defaultOption={
                    trip ? { desc_producto: trip.desc_producto } : null
                  }
                />
                {/* CLIENTE */}
                <Form.Label>Cliente</Form.Label>
                <CustomDropdown
                  options={clients}
                  onSelect={(v) => setSelectedClient(v.id)}
                  displayProperty={"nomb_empresa"}
                  btnName={"Seleccione un cliente"}
                  defaultOption={
                    trip ? { nomb_empresa: trip.desc_cliente } : null
                  }
                />
                {/* FECHA INICIO */}
                <FormGroup>
                  <Form.Label>Fecha Inicio de Viaje</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha_inicio"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fecha_inicio}
                    isInvalid={
                      errors.fecha_inicio &&
                      touched.fecha_inicio &&
                      errors.fecha_inicio
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fecha_inicio &&
                      touched.fecha_inicio &&
                      errors.fecha_inicio}
                  </Form.Control.Feedback>
                </FormGroup>
                {/* FECHA FIN */}
                <FormGroup>
                  <Form.Label>Fecha Final de Viaje</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha_fin"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fecha_fin}
                  />
                </FormGroup>
                {/* OBSERVACIONES */}
                <FormGroup>
                  <Form.Label>Observaciones</Form.Label>
                  <Form.Control
                    type="text"
                    name="obs"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.obs}
                  />
                </FormGroup>
              </section>
              <div className="col-md-6 offset-md-3">
                <ButtonSubmit
                  prop={trip}
                  isSubmitting={isSubmitting}
                  loading={sendLoading}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormViajes;
