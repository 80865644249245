//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlSendChecklist } from "../../../../../api/URLs/mantenimiento";
//ROUTER
import { useNavigate } from "react-router-dom";
//HOOKS
import { useAlertModal } from "../../../../../hooks/useAlertModal";
import { exportChecklist } from "../extra/exportChecklist";
//FORMIK & YUP
import { Formik, Form } from "formik";
import {
  sendChecklistSchema,
  closeChecklistSchema,
} from "../../../../../schemas/Mantenimiento/sendChecklist";
//--Basic
import CustomAlertMsj from "../../../../../components/Tools/Basics/CustomAlertMsj";
import ButtonSubmit from "../../../../../components/Tools/Basics/BtnSubmit";
import CustomInput from "../../../../../components/Tools/Inputs/CustomInput";
import CustomCheckbox from "../../../../../components/Tools/Inputs/CustomCheckbox";
import CustomTextArea from "../../../../../components/Tools/Inputs/CustomTextArea";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Form as FormBoot } from "react-bootstrap";

const ChecklistForm = ({
  valuesChecklist,
  maquina,
  setNoFooter,
  cerrar,
  reFetch,
  setReFetch,
}) => {
  const navigate = useNavigate();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  let closeValues, openValues;
  if (cerrar) {
    closeValues = {
      psw_maquinista: "",
      psw_mecanico: "",
      horas_uso_fin: "",
      otras_obs: maquina.otras_obs ? maquina.otras_obs : "",
      id_maquina: maquina.id_maquina,
      id_result_chk: maquina.id_resultado_chk,
      identificador_maquina: maquina.identificador_maq,
      id_maquinista: maquina.id_maquinista,
      maq_operativa_fin: "defect",
    };
  } else {
    openValues = {
      psw_maquinista: "",
      psw_mecanico: "",
      horas_uso: "",
      otras_obs: "",
      export: false,
      id_maquina: maquina.id_maquina,
      id_checklist: maquina.id_checklist,
      identificador_maquina: maquina.id_interno,
      campos_checklist: valuesChecklist,
      maq_operativa_ini: "defect",
    };
  }
  const initialValues = cerrar ? closeValues : openValues;
  const schema = cerrar ? closeChecklistSchema : sendChecklistSchema;
  const maq_operativa = cerrar ? "maq_operativa_fin" : "maq_operativa_ini";
  //POST
  const handleSubmit = async (values) => {
    const maq_operativa = cerrar
      ? values.maq_operativa_fin
      : values.maq_operativa_ini;
    if (maq_operativa === "defect") {
      return handleAlertError(
        "Es obligatorio seleccionar un estado de maquina"
      );
    }
    try {
      if (cerrar) {
        const api_response = await axiosWithAuth.put(urlSendChecklist, values);
        handleAlertSuccess(api_response);
        setReFetch(!reFetch);
      } else {
        const api_response = await axiosWithAuth.post(urlSendChecklist, values);
        if (values.export) exportChecklist(values);
        handleAlertSuccess(api_response);
        setNoFooter(true);
        setTimeout(() => {
          navigate("/checklists");
        }, 3000);
      }
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    }
  };
  if (alertState === 1)
    return (
      <>
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
        {cerrar ? null : (
          <div
            className={`text-center text-secondary mt-2 ${
              cerrar ? "d-none" : null
            }`}
          >
            <div className="spinner-border spinner-border-sm my-1" />
            <p className="fw-bold">Redirigiendo a selección de checklists...</p>
          </div>
        )}
      </>
    );
  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <CustomInput
              name={cerrar ? "horas_uso_fin" : "horas_uso"}
              label={"Horas de uso"}
              loading={isSubmitting}
              typeInput={"number"}
              placeholder={"Ejem: 7.3"}
            />
            <CustomTextArea
              name={"otras_obs"}
              label={"Otras observaciones"}
              placeholder={"Observaciones"}
              loading={isSubmitting}
            />
            <FormBoot.Label>Estado de Maquina</FormBoot.Label>
            <ButtonGroup className="w-100">
              <Button
                variant={
                  values[maq_operativa] === true ? "primary" : "outline-primary"
                }
                onClick={() => setFieldValue(maq_operativa, true)}
              >
                Operativa
              </Button>
              <Button
                variant={
                  values[maq_operativa] === false
                    ? "primary"
                    : "outline-primary"
                }
                onClick={() => setFieldValue(maq_operativa, false)}
              >
                No Operativa
              </Button>
            </ButtonGroup>
            <CustomInput
              name={"psw_maquinista"}
              label={"Contraseña Maquinista"}
              placeholder={"Ingresar contraseña"}
              typeInput={"password"}
              loading={isSubmitting}
              autoComplete="off"
            />
            <CustomInput
              name={"psw_mecanico"}
              label={"Contraseña Mecanico"}
              placeholder={"Ingresar contraseña"}
              typeInput={"password"}
              loading={isSubmitting}
              autoComplete="off"
            />
            <div className={cerrar ? "d-none" : null}>
              <CustomCheckbox name={"export"} text={"¿Exportar checklist?"} />
            </div>
            <ButtonSubmit
              variant={"primary"}
              text={"Confirmar"}
              loading={isSubmitting}
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChecklistForm;
