import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import {
  urlGetChecklists,
  urlDeleteChecklists,
} from "../../../../../api/URLs/mantenimiento";
//ROUTER
import { useNavigate } from "react-router-dom";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
//FORMULARIO
import FormCabeceras from "../forms/FormCabeceras";
import CabeceraDetails from "./CabeceraDetails";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
import PlainModal from "../../../../../components/Modals/ModalPlain";
//--Table
import TableMain from "../../../../../components/Tools/Tables/TableMain/TableMain";
//--Basic
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";
import NoWrapContainer from "../../../../../components/Tools/Basics/NoWrapContainer";

const TableCabeceras = () => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //useNavigate
  const navigate = useNavigate();
  //States
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [checklists, setChecklists] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [modalState, setModalState] = useState(null);
  //--Modal
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET
  const getChecklists = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetChecklists);
      setChecklists(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
      setSelectedChecklist(null);
    }
  };
  //DELETE
  const handleDelete = async (id_checklist) => {
    const merged = { id: id_checklist };
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.delete(urlDeleteChecklists, {
        data: merged,
      });
      setReFetch(!reFetch);
      handleSucces(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const confirmDelete = (checklist) => {
    if (
      window.confirm(
        `Confirmar eliminación de la maquina: ${checklist.nombre}?`
      )
    ) {
      handleDelete(checklist.id);
    }
  };
  //HANDLES
  const handleForm = (c) => {
    if (c) {
      setSelectedChecklist(c);
    } else {
      setSelectedChecklist(null);
    }
    setModalState(null);
    setShowModalPlain(true);
  };
  const displayDetails = (c) => {
    setSelectedChecklist(c);
    setModalState("details");
    setShowModalPlain(true);
  };
  //BOTONES
  const actions = (checklist) => {
    return (
      <NoWrapContainer>
        <CustomBtn type={"edit_table"} onClick={() => handleForm(checklist)} />
        <CustomBtn
          type={"delete_table"}
          onClick={() => confirmDelete(checklist)}
        />
        <CustomBtn
          type={"link_table"}
          text={"Ver Items"}
          onClick={() =>
            navigate(`/checklists/detalle/${checklist.id}`, {
              state: { checklist },
            })
          }
        />
      </NoWrapContainer>
    );
  };
  const details = (checklist) => {
    return (
      <CustomBtn
        type={"link_table"}
        text={"Ver Detalle"}
        btnType={"p-0"}
        onClick={() => displayDetails(checklist)}
      />
    );
  };
  const buttons = [
    {
      type: "plus_table_header",
      text: " Crear Nueva",
      click: handleForm,
      noData: true,
    },
  ];
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id" },
    { header: "Nombre", field: "nombre" },
    { header: "Detalle", body: details },
    { body: actions },
  ];
  useEffect(() => {
    getChecklists();
  }, [reFetch]);
  return (
    <div>
      <TableMain
        data={checklists}
        title={"Checklist Cabeceras"}
        columns={columns}
        loading={loading}
        textFilter
        scrollSize={"md"}
        buttons={buttons}
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <PlainModal
          show={true}
          handleClose={handleCloseModalPlain}
          size={modalState ? "lg" : null}
        >
          {modalState ? (
            <CabeceraDetails chkl={selectedChecklist} />
          ) : (
            <FormCabeceras
              selectedChecklist={selectedChecklist}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          )}
        </PlainModal>
      ) : null}
    </div>
  );
};

export default TableCabeceras;
