//--Table
import TableRows from "../../../../../components/Tools/Tables/TableRows/TableRows";
//--Basic
import BoxContainer from "../../../../../components/Tools/Cards/BoxContainer/BoxContainer";

const TableDatosChofer = ({ driver }) => {
  //ROWS
  const rowsDatos = [
    {
      first_title: "Nombre:",
      first_prop: "apelnomb",
      second_title: "Id:",
      second_prop: "id_chofer",
    },
    {
      first_title: "Cuil:",
      first_prop: "cuil",
      second_title: "Saldo:",
      second_prop: "saldo_caja",
      second_formater: "priece_color",
    },
    {
      first_title: "Tel:",
      first_prop: "telefono",
      second_title: "Empresa:",
      second_prop: "nomb_empresa",
    },
    {
      first_title: "Cod. Postal:",
      first_prop: "codigopostal",
      second_title: "Correo:",
      second_prop: "correo",
    },
    {
      first_title: "Prov:",
      first_prop: "nombrecorto",
      second_title: "Localidad:",
      second_prop: "nombre",
    },
    {
      first_title: "Dirección:",
      first_prop: "direccion",
      second_title: "Cred. Puerto:",
      second_prop: "num_cred_puerto",
    },
  ];
  const rowsVencimientos = [
    {
      first_title: "Lic. Conducir:",
      first_prop: "dateLicConducir",
      first_formater: "date",
    },
    {
      first_title: "Carga Gral:",
      first_prop: "dateCargaGral",
      first_formater: "date",
      second_title: "Carga Peligrosa:",
      second_prop: "dateCargaPeligrosa",
      second_formater: "date",
    },
    {
      first_title: "Cred. Puerto:",
      first_prop: "dateCredPuerto",
      first_formater: "date",
      second_title: "Rev. Medica:",
      second_prop: "dateRevMedica",
      second_formater: "date",
    },
  ];
  return (
    <div className="row">
      <div className="col-md-6">
        <BoxContainer minSize={"md"} maxSize={"md"}>
          <TableRows
            data={driver}
            title={"Datos"}
            scrollSize={"md"}
            rows={rowsDatos}
          />
        </BoxContainer>
      </div>
      <div className="col-md-6">
        <BoxContainer minSize={"md"} maxSize={"md"}>
          <TableRows
            data={driver}
            title={"Documentación"}
            scrollSize={"md"}
            rows={rowsVencimientos}
          />
        </BoxContainer>
      </div>
    </div>
  );
};

export default TableDatosChofer;
