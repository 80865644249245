import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlGetTyres } from "../../../../api/URLs/cubiertas";
import { urlSwitchEntities } from "../../../../api/URLs/utilities";
//FORMULARIO
import FormCubiertas from "../forms/Form";
//HOOK
import { useModal } from "../../../../hooks/useModal";
//COMPONENTS
//--Modal
import ModalPlain from "../../../../components/Modals/ModalPlain";
import ModalAlert from "../../../../components/Modals/ModalAlert";
//--Table
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
//--Basic
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";
import NoWrapContainer from "../../../../components/Tools/Basics/NoWrapContainer";

const TableCubiertas = () => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [tyres, setTyres] = useState([]);
  const [selectedTyre, setSelectedTyre] = useState(null);
  //Modal Plain
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET
  const getTyres = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetTyres);
      setTyres(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //POST Switch ACT-DESC
  const handleSwitchActive = async (id) => {
    const merged = { nombre_entidad: "cubiertas", id_entidad: id };
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.post(urlSwitchEntities, merged);
      setReFetch(!reFetch);
      handleSucces(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const confirmDelete = (t) => {
    if (window.confirm(`Esta seguro de borrar la cubierta ${t.numero}`)) {
      handleSwitchActive(t.id_cubierta);
    }
  };
  //HANDLES
  const handleTyres = (t) => {
    if (t) {
      setSelectedTyre(t);
    } else {
      setSelectedTyre(null);
    }
    setShowModalPlain(true);
  };
  //BOTONES
  const buttons = [
    {
      type: "plus_table_header",
      text: " Cubierta",
      click: handleTyres,
      noData: true,
    },
  ];
  const actions = (t) => {
    return (
      <NoWrapContainer>
        <CustomBtn type={"edit_table"} onClick={() => handleTyres(t)} />
        <CustomBtn type={"delete_table"} onClick={() => confirmDelete(t)} />
      </NoWrapContainer>
    );
  };
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id_cubierta" },
    { header: "Num.", field: "numero" },
    { header: "Marca", field: "marca" },
    { header: "Medida", field: "medida" },
    { header: "Km", field: "tyreKms", formater: "km" },
    { body: actions },
  ];
  useEffect(() => {
    getTyres();
  }, [reFetch]);
  return (
    <div>
      <TableMain
        data={tyres}
        columns={columns}
        buttons={buttons}
        loading={loading}
        title={"Cubiertas"}
        keyProp={"id_cubierta"}
        textFilter
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain}>
          <FormCubiertas
            tyre={selectedTyre}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default TableCubiertas;
