import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlGetPredefinedTeams } from "../../../../../api/URLs/viajes";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
//FORMS
import FormAltaTeam from "../../forms/Teams/FormAltaTeam";
//COMPONENTS
//--Modal
import ModalPlain from "../../../../../components/Modals/ModalPlain";
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//--Table
import TableMain from "../../../../../components/Tools/Tables/TableMain/TableMain";

const TableTeams = () => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [reFetch, setReFetch] = useState(false);
  //Modal Plain
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET
  const getTeams = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetPredefinedTeams);
      setTeams(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //Clase de fila
  const rowClass = (team) => {
    if (!team.camion_es_propio) return "bg-danger bg-opacity-25";
  };
  //BOTONES
  const buttons = [
    {
      type: "plus_table_header",
      text: " Nuevo Equipo",
      click: () => setShowModalPlain(true),
      noData: true,
    },
  ];
  //COLUMNAS
  const columns = [
    { header: "Camión", field: "patente_camion" },
    {
      header: "Propio",
      field: "camion_es_propio",
      formater: "boolean_color",
      sortable: true,
    },
    { header: "Batea", field: "patente_acoplado" },
    { header: "Chofer", field: "apelnomb_choferv" },
    { header: "Chof. Cuil", field: "cuil_chofer" },
  ];
  useEffect(() => {
    getTeams();
  }, [reFetch]);
  return (
    <div>
      <p className="mb-2">
        Listado de equipos predefinidos para viajes. Los equipos pintados de
        rojo <b className="text-danger">NO</b> son propios de la empresa.
      </p>
      <TableMain
        data={teams}
        columns={columns}
        buttons={buttons}
        loading={loading}
        title={"Equipos"}
        rowClass={rowClass}
        textFilter
        noStriped
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          <FormAltaTeam reFetch={reFetch} setReFetch={setReFetch}/>
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default TableTeams;
