import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../api/axiosConfig";
import { urlGetChklSinCerrar } from "../../../api/URLs/mantenimiento";
//HOOKS
import { useModal } from "../../../hooks/useModal";
//COMPONENTS
//--Modal
import ModalAlert from "../../../components/Modals/ModalAlert";
//--
import ListChklAbiertas from "./components/ListChklAbiertas";
import FormChklAbiertas from "./components/FormChklAbiertas";

const ChklAbiertas = () => {
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(null);
  const [data, setData] = useState([]);
  const [reFetch, setReFetch] = useState(false);
  const getChkl = async () => {
    try {
      if (values) {
        setLoading(true);
        const api_response = await axiosWithAuth.post(
          urlGetChklSinCerrar,
          values
        );
        setData(api_response.data);
      }
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getChkl();
  }, [reFetch]);
  return (
    <div className="custom-container">
      <FormChklAbiertas
        setValues={setValues}
        reFetch={reFetch}
        setReFetch={setReFetch}
      />
      <div className="my-3">
        <ListChklAbiertas
          data={data}
          reFetch={reFetch}
          setReFetch={setReFetch}
          loading={loading}
          values={values}
        />
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default ChklAbiertas;
