import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlGetMuelleBuques } from "../../../../api/URLs/muelle";
//Components
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
//Modal
import { useModal } from "../../../../hooks/useModal";
import ModalAlert from "../../../../components/Modals/ModalAlert";

const TableEstado = () => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [auxReload, setAuxReload] = useState(false);

  //Get Data
  const getData = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetMuelleBuques);
      setData(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //Clase de fila
  const rowClass = (data) => {
    return {
      "bg-success bg-opacity-50 text-white fw-bold": data.ctrlUltimaPosicion === 6,
    };
  };
  //Columns
  const columns = [
    { field: "ctrl", header: "Cod." },
    { field: "nombre", header: "Nomb." },
    { field: "tipocarga", header: "Carga" },
    { field: "muelle", header: "Muelle" },
    { field: "ETA", header: "ETA", formater: "date_time" },
    { field: "ultimaPosicion", header: "UP" },
    { field: "ultimaposicionfecha", header: "UPF", formater: "date" },
    { field: "toneladas", header: "TM" },
    { field: "puerto", header: "Puerto" },
  ];

  setTimeout(() => {
    setAuxReload(!auxReload);
  }, 300000);
  useEffect(() => {
    getData();
  }, [auxReload]);
  return (
    <div>
      <TableMain
        data={data}
        columns={columns}
        loading={loading}
        rowClass={rowClass}
        noHeader
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default TableEstado;
