//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlEventos } from "../../../../api/URLs/correos";
//HOOKS
import { useAlertModal } from "../../../../hooks/useAlertModal";
//FORMIK
import { Formik, Form } from "formik";
//COMPONENTS
//--Modal
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
//--Basic
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";
import CustomInput from "../../../../components/Tools/Inputs/CustomInput";

const FormEvento = ({ event, reFetch, setReFetch }) => {
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //Initial Values
  let newValues, updateValues;
  if (event) {
    updateValues = {
      id: event.id,
      evento: event.evento ? event.evento : "",
    };
  } else {
    newValues = {
      evento: "",
    };
  }
  const initialValues = event ? updateValues : newValues;
  //POST - PUT
  const handleSubmit = async (values) => {
    try {
      let api_response;
      if (!event) {
        api_response = await axiosWithAuth.post(urlEventos, values);
      } else {
        api_response = await axiosWithAuth.put(urlEventos, values);
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    }
  };
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <FormTitle prop={event} text={"Evento"} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <CustomInput
              name={"evento"}
              label={"Nombre del Evento"}
              placeholder={"Ingresar evento"}
              loading={isSubmitting}
            />
            <ButtonSubmit prop={event} loading={isSubmitting} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormEvento;
