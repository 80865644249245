import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetPlaces,
  urlCreatePlaces,
  urlEditPlaces,
} from "../../../../api/URLs/entidadesSecundarias/ubicaciones";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMIK & YUP
import { Formik } from "formik";
import { placesSchema } from "../../../../schemas/Camiones/EntidadesSecundarias/placesSchema";
//BOOTSTRAP
import { Button, Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../../components/Tools/Tables/Table/Table";
//--Basic
import BoxContainer from "../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";

const FormPlaces = () => {
  //useModal
  const {
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleError,
    handleSucces,
  } = useModal();
  //States
  const [reForm, setReForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [place, setPlace] = useState(null);
  const [places, setPlaces] = useState([]);
  //INITIAL VALUES
  let newValues, updateValues;
  if (place) {
    updateValues = {
      desc_place: place.desc_place ? place.desc_place : "",
    };
  } else {
    newValues = {
      desc_place: "",
    };
  }
  const initialValues = place ? updateValues : newValues;
  //GET
  const getPlaces = async () => {
    try {
      setLoadingTable(true);
      const api_response = await axiosWithAuth.get(urlGetPlaces);
      setPlaces(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoadingTable(false);
    }
  };
  //POST - PUT
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      let api_response;
      if (!place) {
        api_response = await axiosWithAuth.post(urlCreatePlaces, values);
      } else {
        api_response = await axiosWithAuth.put(urlEditPlaces, {
          ...values,
          id: place.id,
        });
      }
      handleSucces(api_response);
      setReForm(!reForm);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
      setPlace(null);
    }
  };
  //HANDLE EDIT
  const handleEdit = async (p) => {
    setPlace(p);
    setReForm(!reForm);
  };
  //BOTONES
  const actions = (p) => {
    return (
      <CustomBtn
        type={"edit_table"}
        onClick={() => handleEdit(p)}
      />
    );
  };
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id" },
    { header: "Ubicaciones", field: "desc_place" },
    { header: "Editar", body: actions },
  ];
  useEffect(() => {
    getPlaces();
  }, [reForm]);
  return (
    <div className="row">
      <div className="col-md-8">
        <BoxContainer maxSize={"lg"} minSize={"lg"}>
          <Table
            data={places}
            columns={columns}
            keyProp={"id"}
            title={"Ubicaciones"}
            scrollSize={"lg"}
            textFilter
            loading={loadingTable}
          />
        </BoxContainer>
      </div>
      <div className="col-md-4">
        {!loading ? (
          <>
            <div
              className={`alert alert-${place ? "success" : "primary"} w-100`}
              role="alert"
            >
              {`${place ? "Modificar" : "Agregar nueva"} Ubicación ${
                place ? place.desc_place : ""
              }`}
            </div>
            <Formik
              enableReinitialize
              validationSchema={placesSchema}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit} className="shadow-none mb-2">
                  {/* UBICACION */}
                  <FormGroup>
                    <Form.Control
                      type="text"
                      placeholder="Ingresar ubicación"
                      name="desc_place"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.desc_place}
                      isInvalid={
                        errors.desc_place &&
                        touched.desc_place &&
                        errors.desc_place
                      }
                    />
                  </FormGroup>
                  <Button
                    variant={place ? "success" : "primary"}
                    className="w-100 mt-3"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {place ? "Modificar" : "Agregar"}
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <CenterSpinner />
        )}
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormPlaces;
