//RUTAS VEHICULOS PERSONAL
//--gam
export const urlGetPersonalVehicles = "/api/personal/vehicles";
export const urlCreatePersonalVehicles = "/api/personal/vehicles";
export const urlEditPersonalVehicles = "/api/personal/vehicles";
//--fam eventos
export const urlGetEventCategories = "/api/pv/evntscats";
export const urlCreateEvent = "/api/pv/linkpvevent";
export const urlGetEvents = "/api/pv/listpvenets";
//--cubiertas
export const urlGetPersonalVehiclesTyres = "/api/pv/tyres";
export const urlCreatePersonalVehiclesTyres = "/api/pv/tyre";
export const urlEditPersonalVehiclesTyres = "/api/pv/tyre";
export const urlGetPVTyresNotAsigned = "/api/pv/tyre-not-asigned";
//--sectores
export const urlGetPersonalVehiclesSectors = "/api/pv/sectores";
