//Components
import TableEstado from "./components/TableEstado";

const EstadoMuelle = () => {
  return (
    <div className="custom-container">
      <TableEstado />
    </div>
  );
};

export default EstadoMuelle;
