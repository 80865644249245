import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlGetEquiposSAF } from "../../../../../api/URLs/viajes";
//ROUTER
import { useLocation } from "react-router-dom";
//Basics
import Divider from "../../../../../components/Tools/Basics/Divider";
import TableMain from "../../../../../components/Tools/Tables/TableMain/TableMain";
import CenterSpinner from "../../../../../components/Tools/Basics/Spinner";
//Modal
import { useModal } from "../../../../../hooks/useModal";
import ModalAlert from "../../../../../components/Modals/ModalAlert";

const TableVehicleSAF = () => {
  const location = useLocation();
  const data = location.state.data;

  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);

  const getInfo = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetEquiposSAF, {
        params: {
          ctrl_operativo: data.ctrl_op_saf,
        },
      });
      setInfo(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  const columns_1 = [
    { field: "patcha", header: "Patcha" },
    { field: "dni", header: "DNI" },
  ];
  const columns_2 = [
    { field: "patente", header: "Patente" },
    { field: "cuil", header: "Cuil" },
  ];
  useEffect(() => {
    getInfo();
  }, []);
  return (
    <div className="custom-container">
      <h2>
        <b>Auditar Equipos SAF | Viaje {data.id}</b>
      </h2>
      <Divider />
      {loading ? (
        <CenterSpinner />
      ) : (
        <>
          {info ? (
            <div className="row">
              <div className="col-md-6">
                <TableMain
                  data={info[0]}
                  columns={columns_1}
                  title={"Camiones SI SAF NO LOG"}
                  loading={loading}
                />
              </div>
              <div className="col-md-6">
                <TableMain
                  data={info[1]}
                  columns={columns_2}
                  title={"Camiones SI LOG NO SAF"}
                  loading={loading}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default TableVehicleSAF;
