import * as ExcelJS from "exceljs";
import { dateSQLformat } from "../../../../functions/dateSQLformat";

export const informeExportExcel = (data, fechas, maq_id_interno) => {
  //Fucncion para crear un nuevo objeto con los maquinistas de cada fecha
  const maquinistasObject = () => {
    const maquinistas = data[0].historico.reduce((acc, currentValue, index) => {
      acc[`value_${index}`] = currentValue.maquinista;
      return acc;
    }, {});
    return {
      nombre: "MAQUINISTA",
      ...maquinistas,
    };
  };
  //Fucncion para crear un nuevo objeto con las horas de uso de cada fecha
  const horasUsoObject = () => {
    const horas_de_uso = data[0].historico.reduce(
      (acc, currentValue, index) => {
        acc[`value_${index}`] = currentValue.horas_uso_fin
          ? `${currentValue.horas_uso_fin}`
          : `${currentValue.horas_uso}`;
        return acc;
      },
      {}
    );
    return {
      nombre: "HORAS DE USO",
      ...horas_de_uso,
    };
  };
  //Creamos el arreglo que se usara como data principal en el excel
  //Conformado por el arreglo original formateado (formatData), maquinistas y horas_de_uso
  const formatData = data.map((item) => {
    const newProps = item.historico.reduce((acc, currentValue, index) => {
      acc[`value_${index}`] = currentValue.valor;
      return acc;
    }, {});
    return {
      ...item,
      ...newProps,
    };
  });
  const maquinistas = maquinistasObject();
  const horas_de_uso = horasUsoObject();
  const finalData = [maquinistas, horas_de_uso, ...formatData];
  //Definimos las fechas que se utilizaron como headers
  const firstDates = data[0].historico;
  const columnsDates = firstDates.map((item, i) => {
    return {
      header: dateSQLformat(item.fecha, "addTime"),
      key: `value_${i}`,
      width: 20,
    };
  });
  //Creamos la hoja de calculo
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(`Informe ${maq_id_interno}`);
  //Definimos las columnas
  ws.columns = [
    {
      header: "",
      key: "nombre",
      width: 30,
    },
    ...columnsDates,
  ];
  //Definimos las filas
  const values = finalData;
  values.forEach((item) => {
    ws.addRow(item);
  });
  //Blob
  wb.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Informe checklist ${maq_id_interno} entre ${fechas.fecha_1} y ${fechas.fecha_2}`;
    a.click();
    URL.revokeObjectURL(url);
  });
};
