import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetMaquinas,
  urlDeleteMaquinas,
} from "../../../../api/URLs/mantenimiento";
//ROUTER
import { useNavigate } from "react-router-dom";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMULARIO
import FormMaquinas from "../forms/FormMaquinas";
import FormLinkChecklist from "../forms/FormLinkChecklist";
import InformeModal from "./InformeModal";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import PlainModal from "../../../../components/Modals/ModalPlain";
//--Table
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
//--Basic
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";
import NoWrapContainer from "../../../../components/Tools/Basics/NoWrapContainer";

const TableMaquinas = () => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //useNavigate
  const navigate = useNavigate();
  //States
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [maquinas, setMaquinas] = useState([]);
  const [selectedMaquina, setSelectedMaquina] = useState(null);
  const [modalState, setModalState] = useState(null);
  //--Modal
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET
  const getMaquinas = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetMaquinas);
      setMaquinas(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //DELETE
  const handleDelete = async (id_maquina) => {
    const merged = { id: id_maquina };
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.delete(urlDeleteMaquinas, {
        data: merged,
      });
      setReFetch(!reFetch);
      handleSucces(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const confirmDelete = (maquina) => {
    if (
      window.confirm(
        `Confirmar eliminación de la maquina: ${maquina.id_interno}?`
      )
    ) {
      handleDelete(maquina.id_maquina);
    }
  };
  //HANDLES
  const handleInforme = (maquina) => {
    setSelectedMaquina(maquina);
    setModalState("informe");
    setShowModalPlain(true);
  };
  const handleForm = (maquina) => {
    if (maquina) {
      setSelectedMaquina(maquina);
    } else {
      setSelectedMaquina(null);
    }
    setModalState(null);
    setShowModalPlain(true);
  };
  const handleChecklist = (maquina) => {
    setSelectedMaquina(maquina);
    setModalState("linkChkl");
    setShowModalPlain(true);
  };
  //BOTONES
  const actions = (maquina) => {
    return (
      <NoWrapContainer>
        <CustomBtn
          btnType={"info btn-sm py-0 me-1"}
          text={"Informe"}
          onClick={() => handleInforme(maquina)}
        />
        <CustomBtn type={"edit_table"} onClick={() => handleForm(maquina)} />
        <CustomBtn
          type={"delete_table"}
          onClick={() => confirmDelete(maquina)}
        />
        <CustomBtn
          type={"link_table"}
          text={"Checklists"}
          onClick={() =>
            navigate(`/maquina/checklists/${maquina.id_maquina}`, {
              state: { maquina },
            })
          }
        />
      </NoWrapContainer>
    );
  };
  const checklistButton = (maquina) => {
    return maquina.id_checklist ? (
      <NoWrapContainer>
        <span>{maquina.nombre_checklist}</span>
        <CustomBtn
          type={"link_table"}
          icon={"bi bi-pencil-square ms-1"}
          onClick={() => handleChecklist(maquina)}
        />
      </NoWrapContainer>
    ) : (
      <CustomBtn
        type={"link_table"}
        text={"Vincular"}
        onClick={() => handleChecklist(maquina)}
      />
    );
  };
  const buttons = [
    {
      type: "plus_table_header",
      text: " Maquina",
      click: handleForm,
      noData: true,
    },
  ];
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id_maquina" },
    { header: "Id Int.", field: "id_interno" },
    { header: "Tipo", field: "tipo", sortable: true },
    { header: "Marca", field: "marca" },
    { header: "Modelo", field: "modelo" },
    {
      header: "Checklist.",
      field: "id_checklist",
      body: checklistButton,
      sortable: true,
    },
    { body: actions },
  ];
  useEffect(() => {
    getMaquinas();
  }, [reFetch]);
  return (
    <div>
      <TableMain
        data={maquinas}
        title={"Maquinas"}
        columns={columns}
        buttons={buttons}
        loading={loading}
        textFilter
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <PlainModal
          show={true}
          handleClose={handleCloseModalPlain}
          size={modalState === "linkChkl" ? "lg" : null}
        >
          {modalState === "linkChkl" ? (
            <FormLinkChecklist
              maquina={selectedMaquina}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          ) : modalState === "informe" ? (
            <InformeModal maq={selectedMaquina}/>
          ) : (
            <FormMaquinas
              maquina={selectedMaquina}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          )}
        </PlainModal>
      ) : null}
    </div>
  );
};

export default TableMaquinas;
