import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import {
  urlGetOperativos,
  urlSwitchOperativoEstado,
  urlGetOC,
} from "../../../../../api/URLs/operativos";
//ROUTER
import { useNavigate } from "react-router-dom";
//HOOKS/FUNCTIONS
import { useModal } from "../../../../../hooks/useModal";
import { exportCleanExcel } from "../../../../../functions/exportCleanExcel";
import { filterAndSortData } from "../../../../../functions/filterAndSortData";
//FORMS
import FormOperativos from "../../forms/Form";
//COMPONENTS
//--Modal
import ModalPlain from "../../../../../components/Modals/ModalPlain";
import ModalAlert from "../../../../../components/Modals/ModalAlert";
//--Table
import TableMain from "../../../../../components/Tools/Tables/TableMain/TableMain";
//--Basic
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";
import NoWrapContainer from "../../../../../components/Tools/Basics/NoWrapContainer";

const TableOperativos = () => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //useNavigate
  const navigate = useNavigate();
  //states
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [operativos, setOperativos] = useState([]);
  const [selectedOperativo, setSelectedOperativo] = useState(null);
  //Modal Plain
  const handleCloseModalPlain = () => setShowModalPlain(false);
  const [showModalPlain, setShowModalPlain] = useState(false);
  //GET
  const getParallel = async () => {
    setLoading(true);
    try {
      const api_response = await axiosWithAuth.get(urlGetOperativos);
      setOperativos(api_response.data);
      setSelectedOperativo(null);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //EXPORT PAR OC
  const exportOC = async (operativo) => {
    try {
      const api_response = await axiosWithAuth.get(urlGetOC, {
        params: {
          id_operativo: operativo.id,
        },
      });
      const data = api_response.data;
      const fileName = `OC - ${operativo.nombre}`;
      const selectProps = [
        "operativo",
        "chofer",
        "chof_razon_social",
        "cuit_empresa_chof",
        "fecha",
        "tarifa",
        "tipo_tarifa",
        "toneladas",
        "horas",
      ];
      const filteredData = filterAndSortData(data, selectProps);
      exportCleanExcel(filteredData, fileName);
      setReFetch(!reFetch)
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    }
  };
  //BUTTON CREATE
  const buttons = [
    {
      type: "plus_table_header",
      text: " Operativo",
      click: () => handleOperativo(),
      noData: true,
    },
  ];
  //HANDLES OPERATIVOS
  const handleOperativo = (v) => {
    if (v) {
      setSelectedOperativo(v);
    } else {
      setSelectedOperativo(null);
    }
    setShowModalPlain(true);
  };
  const handleConfirmOperativoState = (operativo) => {
    if (
      window.confirm(
        `Esta seguro que el operativo ${operativo.nombre} esta listo para facturar?`
      )
    ) {
      handleOperativoState(operativo.id);
    }
  };
  const handleOperativoState = async (id_operativo) => {
    const merged = { id_estado: 10, id_operativo: id_operativo };
    try {
      setLoading(true);
      const api_responses = await axiosWithAuth.put(
        urlSwitchOperativoEstado,
        merged
      );
      setReFetch(!reFetch);
      handleSucces(api_responses);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const actionButton = (operativo) => {
    return (
      <NoWrapContainer>
        <CustomBtn
          type={"edit_table"}
          onClick={() => handleOperativo(operativo)}
          disabled={operativo.id_estado === 10 ? true : false}
        />
        <CustomBtn
          type={"link_table"}
          text={"Ver Viajes"}
          onClick={() =>
            navigate(`/operativos/viajes/${operativo.id}`, {
              state: { operativo },
            })
          }
        />
      </NoWrapContainer>
    );
  };
  const stateButton = (operativo) => {
    return (
      <CustomBtn
        btnType={`${
          operativo.id_estado === 10 ? "danger" : "info"
        } btn-sm py-0`}
        text={
          operativo.id_estado === 10 ? "Listo para Facturar" : "Sin Revisar"
        }
        disabled={operativo.id_estado === 10 ? true : false}
        onClick={() => handleConfirmOperativoState(operativo)}
      />
    );
  };
  const stateOC = (op) => {
    return (
      <div className="d-flex">
        <CustomBtn
          btnType={"success btn-sm py-0 me-1"}
          icon={"bi bi-download"}
          onClick={() => exportOC(op)}
        />
        <p className={`text-${op.imprimio_oc ? "success" : "danger"}`}>
          {op.imprimio_oc ? "Si" : "No"}
        </p>
      </div>
    );
  };
  const columns = [
    { field: "id", header: "Id" },
    { field: "ctrl_op_saf", header: "CTRL SAF" },
    { field: "nombre", header: "Operativos" },
    { field: "desc_tipo", header: "Tipo", sortable: true },
    { field: "cliente", header: "Cliente", sortable: true },
    {
      field: "tnskilos",
      header: "TnsKilos",
      formater: "round_number",
      sortable: true,
    },
    { field: "id_proforma", header: "Nº Proforma" },
    { field: "id_estado", header: "Estado", body: stateButton, sortable: true },
    { field: "imprimio_oc", header: "OC", body: stateOC, sortable: true },
    { body: actionButton },
  ];
  useEffect(() => {
    getParallel();
  }, [reFetch]);
  return (
    <div>
      <TableMain
        data={operativos}
        columns={columns}
        buttons={buttons}
        keyProp={"id"}
        loading={loading}
        title={"Operativos"}
        textFilter
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain}>
          <FormOperativos
            selectedOperativo={selectedOperativo}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default TableOperativos;
