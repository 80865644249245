import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetTyresBrand,
  urlGetTyresSize,
} from "../../../../api/URLs/cubiertas";
import {
  urlCreatePersonalVehiclesTyres,
  urlEditPersonalVehiclesTyres,
} from "../../../../api/URLs/vehiculosPersonal";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMIK & YUP
import { Formik } from "formik";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import { useAlertModal } from "../../../../hooks/useAlertModal";
//--Basic
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomDropdown from "../../../../components/Tools/Inputs/CustomDropdown";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";

const FormCubiertasPersonal = ({ tyre, reFetch, setReFetch }) => {
  //useModal
  const {
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleError,
  } = useModal();
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
    handleAlertWarning,
  } = useAlertModal();
  //States
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  //INITIAL VALUES
  let newValues, updateValues;
  if (tyre) {
    updateValues = {
      numero: tyre.numero ? tyre.numero : "",
    };
  } else {
    newValues = {
      numero: "",
    };
  }
  const initialValues = tyre ? updateValues : newValues;
  //VALIDACIONES (dropdowns)
  const validateOptions = () => {
    if (!selectedBrand) {
      handleAlertWarning("No se ha seleccionado marca");
      return false;
    }
    if (!selectedSize) {
      handleAlertWarning("No se ha seleccionado medida");
      return false;
    }
    return true;
  };
  //POST - PUT
  const handleSubmit = async (values) => {
    const validations = validateOptions();
    if (!validations) return;
    const merged = {
      ...values,
      id_marca: selectedBrand,
      id_medida: selectedSize,
    };
    try {
      setSendLoading(true);
      let api_response;
      if (!tyre) {
        api_response = await axiosWithAuth.post(
          urlCreatePersonalVehiclesTyres,
          merged
        );
      } else {
        api_response = await axiosWithAuth.put(urlEditPersonalVehiclesTyres, {
          ...merged,
          id: tyre.id_cubierta,
        });
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  //GET - peticion paralela de marcas y medidas
  const getBrandAndSize = async () => {
    setLoading(true);
    const promise_array = [];
    promise_array.push(await axiosWithAuth.get(urlGetTyresBrand));
    promise_array.push(await axiosWithAuth.get(urlGetTyresSize));
    try {
      const api_responses = await Promise.all(promise_array);
      const [brand_response, size_response] = api_responses;
      setBrands(brand_response.data);
      setSizes(size_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //SELECTED BRAND & SIZES
  const handleSelectBrand = async (b) => {
    setSelectedBrand(b.id);
  };
  const handleSelectSize = async (s) => {
    setSelectedSize(s.id);
  };
  //SELECTED IDs IN EDIT
  const handleEdit = () => {
    if (tyre) {
      setSelectedBrand(tyre.id_marca);
      setSelectedSize(tyre.id_medida);
    }
  };
  useEffect(() => {
    getBrandAndSize();
    handleEdit();
  }, []);
  if (loading) return <CenterSpinner />;
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      <FormTitle prop={tyre} text={"Cubierta"}/>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="shadow-none">
            {/* MARCA */}
            <Form.Label>Marca de cubierta</Form.Label>
            <CustomDropdown
              options={brands}
              onSelect={handleSelectBrand}
              defaultOption={tyre ? tyre : null}
              displayProperty={"marca"}
              btnName={"Seleccione una marca"}
            />
            {/* MEDIDA */}
            <Form.Label>Medida de cubierta</Form.Label>
            <CustomDropdown
              options={sizes}
              onSelect={handleSelectSize}
              defaultOption={tyre ? tyre : null}
              displayProperty={"medida"}
              btnName={"Seleccione una medida"}
            />
            {/* NUMERO */}
            <FormGroup>
              <Form.Label>Numero</Form.Label>
              <Form.Control
                type="text"
                placeholder="Numero"
                name="numero"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.numero}
                isValid={touched.numero && !errors.numero}
                isInvalid={errors.numero && touched.numero && errors.numero}
              />
              <Form.Control.Feedback type="invalid">
                {errors.numero && touched.numero && errors.numero}
              </Form.Control.Feedback>
            </FormGroup>
            <ButtonSubmit
              prop={tyre}
              isSubmitting={isSubmitting}
              loading={sendLoading}
              modalMinSize={"sm"}
            />
          </Form>
        )}
      </Formik>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormCubiertasPersonal;
