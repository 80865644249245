import { useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlCreateDistance,
  urlEditDistance,
} from "../../../../api/URLs/viajes";
//HOOKS
import { useAlertModal } from "../../../../hooks/useAlertModal";
//FORMIK & YUP
import { Formik, Form } from "formik";
//COMPONENTS
//--Basic
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import CustomInput from "../../../../components/Tools/Inputs/CustomInput";

const FormViajesDistancia = ({ trip, reFetch, setReFetch }) => {
  const id_origen = trip.id_ubi_origen;
  const id_destino = trip.id_ubi_destino;
  const distance = trip.distancia_calculada;
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [sendLoading, setSendLoading] = useState(false);
  //Initial Values
  let newValues, updateValues;
  if (distance) {
    updateValues = {
      id: trip.id_distancia,
      distancia_km: distance,
    };
  } else {
    newValues = {
      distancia_km: "",
    };
  }
  const initialValues = distance ? updateValues : newValues;
  const handleSubmit = async (values) => {
    const merged = { ...values, id_origen: id_origen, id_destino: id_destino };
    try {
      setSendLoading(true);
      let api_response;
      if (!distance) {
        api_response = await axiosWithAuth.post(urlCreateDistance, merged);
      } else {
        api_response = await axiosWithAuth.put(urlEditDistance, merged);
      }
      setReFetch(!reFetch);
      handleAlertSuccess(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };
  const distanceDisplay = (t) => {
    if (t) {
      return <h4>La distancia registrada es <b>{t}km</b></h4>;
    } else {
      return <h4>Aún no se ha registrado la distancia</h4>;
    }
  };
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <h3>{`${trip.ubi_origen} - ${trip.ubi_destino}`}</h3>
      {distanceDisplay(distance)}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <CustomInput
              name={"distancia_km"}
              label={"Distancia en km"}
              placeholder={"Ingresar la distancia"}
              loading={sendLoading}
              typeInput={"number"}
            />
            <ButtonSubmit
              variant={"info"}
              text={distance ? "Modificar" : "Enviar"}
              isSubmitting={isSubmitting}
              loading={sendLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormViajesDistancia;
