//RUTAS MANTENIMIENTO
//--Detalle de una checklist
export const urlGetChecklistDetalle = "/api/resultado/det/checklist";
export const urlGetChecklistCabecera = "/api/checklists/resultado/cabecera";
//--Checklists sin cerrar
export const urlGetChklSinCerrar = "/api/maquinista/checklists/sinc";
//--gabm maquinas
export const urlGetMaquinas = "/api/maquinas";
export const urlCreateMaquinas = "/api/maquinas";
export const urlEditMaquinas = "/api/maquinas";
export const urlDeleteMaquinas = "/api/maquinas";
//--Maquinas tipos
export const urlGetMaquinasTipos = "/api/maquinas-tipos";
//--Maquinas link checklist
export const urlLinkMaquinaChecklist = "/api/maquina-link-chkl";
//--Checklists de una maquina y detalle
export const urlGetChecklists_x_maquina = "/api/resultados/checklist";
//--gabm checklist cabeceras
export const urlGetChecklists = "/api/checklist";
export const urlCreateChecklists = "/api/checklist";
export const urlEditChecklists = "/api/checklist";
export const urlDeleteChecklists = "/api/checklist";
//--gabm checklist items
export const urlGetItems = "/api/items";
export const urlCreateItems = "/api/item/checklist";
export const urlEditItems = "/api/item/checklist";
export const urlDeleteItems = "/api/item/checklist";
//--Items Tipos
export const urlGetItemsTipos = "/api/items-tipos";
//--Items de una cheklist [A] / items libres de una checklist [N]
export const urlGetItems_x_Checklist = "/api/items/checklist"; //Params: id_checklist | accion (A, N)
//--Add/delete items a checklist
export const urlLinkItemsCheckclist = "/api/link/chkl-item";
export const urlUnlinkItemsChecklist = "/api/unlink/chkl-item";
//--Enviar checklist maquinista
export const urlSendChecklist = "/api/checklist-maquina";
//--gabm maquinistas
export const urlGetMaquinistas = "/api/maquinistas";
export const urlCreateMaquinistas = "/api/maquinistas";
export const urlEditMaquinistas = "/api/maquinistas";
export const urlDeleteMaquinistas = "/api/maquinistas";
//--Checklist por dia
export const urlGetChecklists_x_dia = "/api/checklists/dia";
//--Checklist corregir horas
export const urlChklCorregirHoras = "/api/checklists";
