import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlLinkVehiclesTyres } from "../../../../api/URLs/vehiculos";
//Tables
import Table from "../../../../components/Tools/Tables/Table/Table";
import BoxContainer from "../../../../components/Tools/Cards/BoxContainer/BoxContainer";
//Modal
import { useModal } from "../../../../hooks/useModal";
import ModalPlain from "../../../../components/Modals/ModalPlain";
import ModalAlert from "../../../../components/Modals/ModalAlert";
//Form
import FormVehiclesTyres from "../forms/FormVehiclesTyres";

const CubiertasVehiculo = ({ vehicle }) => {
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();

  //States
  const [loading, setLoading] = useState(false);
  const [tyres, setTyres] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [selector, setSelector] = useState(null);
  const [showModalPlain, setShowModalPlain] = useState(false);
  const [reFetch, setReFetch] = useState(false);

  //Modal Plain
  const handleCloseModalPlain = () => setShowModalPlain(false);

  //GET vehicles
  const getData = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(
        `${urlLinkVehiclesTyres}/${vehicle.id}`
      );
      setTyres(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Show data
  const columns = [
    { field: "id_cubierta", header: "Id" },
    { field: "marca", header: "Marca" },
    { field: "medida", header: "Medida" },
    { field: "numero", header: "Numero" },
    { field: "tyreKms", header: "Km", formater: "km" },
  ];

  const handleTyre = (vehicle, selector) => {
    setSelectedVehicle(vehicle);
    setSelector(selector);
    setShowModalPlain(true);
  };
  const buttonsTrips = [
    {
      type: "plus_table_header",
      styles: "me-1",
      text: " Asignar",
      click: () => handleTyre(vehicle, 1),
    },
    {
      type: "minus_table_header",
      text: " Desasignar",
      click: () => handleTyre(vehicle),
    },
  ];

  useEffect(() => {
    getData();
  }, [reFetch]);

  return (
    <div className="row">
      <div className="col-md-12">
        <BoxContainer minSize={"lg"} maxSize={"lg"}>
          <Table
            title={"Cubiertas asignadas"}
            data={tyres}
            reFetch={reFetch}
            columns={columns}
            keyProp={"id"}
            loading={loading}
            buttons={buttonsTrips}
            textFilter
            scrollSize={"lg"}
          />
        </BoxContainer>
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          <FormVehiclesTyres
            vehicle={selectedVehicle}
            reFetch={reFetch}
            setReFetch={setReFetch}
            selector={selector}
          />
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default CubiertasVehiculo;
