import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlChklCorregirHoras } from "../../../../api/URLs/mantenimiento";
//HOOKS
import { useAlertModal } from "../../../../hooks/useAlertModal";
//FORMIK & YUP
import { Formik, Form } from "formik";
//--Basic
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import CustomInput from "../../../../components/Tools/Inputs/CustomInput";
import { dateSQLformat } from "../../../../functions/dateSQLformat";

const FormCambiarHoraChkl = ({ chkl, reFetch, setReFetch }) => {
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  const initialValues = {
    horas_uso_inicio: chkl.horas_uso ? chkl.horas_uso : "",
    horas_uso_fin: chkl.horas_uso_fin ? chkl.horas_uso_fin : "",
  };
  const templateInfoChkl = (
    <div className="fw-light">
      <p>
        Modificar horas registradas en checklist de la fecha{" "}
        <span className="fw-semibold">
          {dateSQLformat(chkl.fec_restulado_chk)}
        </span>
      </p>
      <p>
        Maquina <span className="fw-semibold">{chkl.identificador_maq}</span>
      </p>
      <p>
        Maquinista <span className="fw-semibold">{chkl.maquinista}</span>
      </p>
    </div>
  );
  const handleSubmit = async (values) => {
    try {
      const response = await axiosWithAuth.post(
        `${urlChklCorregirHoras}/${chkl.id_resultado_chk}/corregir-horas`,
        values
      );
      setReFetch(!reFetch);
      handleAlertSuccess(response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    }
  };
  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      {templateInfoChkl}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <CustomInput
              name={"horas_uso_inicio"}
              label={"Horas de uso Inicio"}
              placeholder={"Ingresar horas"}
              typeInput={"number"}
              loading={isSubmitting}
            />
            <CustomInput
              name={"horas_uso_fin"}
              label={"Horas de uso Fin"}
              placeholder={"Ingresar horas"}
              typeInput={"number"}
              loading={isSubmitting}
            />
            <ButtonSubmit
              text={"Modificar"}
              isSubmitting={isSubmitting}
              loading={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormCambiarHoraChkl;
