import { useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlCreateWebRoutes,
  urlEditWebRoutes,
} from "../../../../api/URLs/rutasWeb";
//HOOK
import { useAlertModal } from "../../../../hooks/useAlertModal";
//FORMIK & YUP
import { Formik } from "formik";
//BOOTSTRAP
import { Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//Basic
import CustomAlertMsj from "../../../../components/Tools/Basics/CustomAlertMsj";
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import FormTitle from "../../../../components/Tools/Basics/FormTitle";

const FormRoutes = ({ routes, reFetch, setReFetch }) => {
  //useAlertModal
  const {
    alertMsj,
    alertState,
    alertType,
    handleAlertSuccess,
    handleAlertError,
  } = useAlertModal();
  //States
  const [sendLoading, setSendLoading] = useState(false);
  const [displayCheck, setDisplayCheck] = useState(
    routes ? (routes.display ? true : false) : false
  );
  //Initial Values
  let newValues, updateValues;

  if (routes) {
    updateValues = {
      path: routes.path ? routes.path : "",
      category: routes.category ? routes.category : "",
      descrip: routes.descrip ? routes.descrip : "",
      element: routes.element ? routes.element : "",
    };
  } else {
    newValues = {
      path: "",
      category: "",
      element: "",
      descrip: "",
    };
  }
  const initialValues = routes ? updateValues : newValues;

  //POST - PUT
  const handleSubmit = async (values) => {
    let api_response;
    try {
      setSendLoading(true);
      if (!routes) {
        api_response = await axiosWithAuth.post(urlCreateWebRoutes, {
          ...values,
          display: displayCheck ? 1 : 0,
        });
      } else {
        api_response = await axiosWithAuth.put(urlEditWebRoutes, {
          ...values,
          id: routes.id,
          display: displayCheck ? 1 : 0,
        });
      }
      handleAlertSuccess(api_response);
      setReFetch(!reFetch);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleAlertError(auxError);
    } finally {
      setSendLoading(false);
    }
  };

  if (alertState === 1)
    return <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />;
  return (
    <div>
      <FormTitle prop={routes} text={"Ruta"} />
      {alertState === 2 ? (
        <CustomAlertMsj alertMsj={alertMsj} alertType={alertType} />
      ) : null}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="shadow-none">
            <div className="row">
              <div className="col-md-6">
                {/* Categoria */}
                <FormGroup>
                  <Form.Label>Categoria</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Categoria"
                    name="category"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.category}
                  />
                </FormGroup>
                {/* Path */}
                <FormGroup>
                  <Form.Label>Path</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Path"
                    name="path"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.path}
                  />
                </FormGroup>
                {/* Display */}
                <div className="d-flex mt-4">
                  <input
                    type="checkbox"
                    checked={displayCheck}
                    onChange={() => setDisplayCheck(!displayCheck)}
                  />
                  <Form.Label className="col-md-6">Display</Form.Label>
                </div>
              </div>
              <div className="col-md-6">
                {/* Elemento */}
                <FormGroup>
                  <Form.Label>Elemento</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Elemento"
                    name="element"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.element}
                  />
                </FormGroup>
                <FormGroup>
                  {/* Descripcion */}
                  <Form.Label>Descripcion</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Descripcion"
                    name="descrip"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.descrip}
                  />
                </FormGroup>
                <ButtonSubmit
                  prop={routes}
                  isSubmitting={isSubmitting}
                  loading={sendLoading}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormRoutes;
