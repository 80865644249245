import { useLocation } from "react-router-dom";
import Divider from "../../../components/Tools/Basics/Divider";
import TableMaquinistaChkl from "./components/TableMaquinistaChkl";

const MaquinistaChecklists = () => {
   //Location
   const location = useLocation();
   const maquinista = location.state.maq;
  return (
    <div className="custom-container">
      <h2> <b>Maquinista | {maquinista.nombre}</b></h2>
      <Divider/>
      <TableMaquinistaChkl maquinista={maquinista} />
    </div>
  )
}

export default MaquinistaChecklists
