import { useLocation } from "react-router-dom";
import LinkItems from "./components/LinkItems";
import Divider from "../../../../components/Tools/Basics/Divider";

const ChecklistsDetalles = () => {
  //Location
  const location = useLocation();
  const checklist = location.state.checklist;
  return (
    <div className="custom-container">
      <h2>
        <b>{checklist.nombre}</b>
      </h2>
      <Divider />
      <LinkItems checklist={checklist} />
    </div>
  );
};

export default ChecklistsDetalles;
