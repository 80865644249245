import React from "react";
//NavTabs
import NavTabs from "../../../components/NavBars/NavTabs/NavTabs";
//Components
import TableCubiertas from "./components/TableCubiertas";
import FormTyreBrand from "../EntidadesSecundarias/Cubiertas/FormTyreBrand";
import FormTyreSize from "../EntidadesSecundarias/Cubiertas/FormTyreSize";

const Cubiertas = () => {
  const sections = [
    { name: "Cubiertas", component: <TableCubiertas /> },
    { name: "Marcas", component: <FormTyreBrand /> },
    { name: "Medidas", component: <FormTyreSize /> },
  ];
  return (
    <div className="custom-container">
      <h2>
        <b>Cubiertas Terminales y Servicios</b>
      </h2>
      <NavTabs sections={sections} />
    </div>
  );
};

export default Cubiertas;
