import React from "react";
import "./OperativoHeaderDetails.css";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";

const OperativoHeaderDetails = ({ title, details, loading }) => {
  return (
    <div className="operativo-header-details d-md-flex pt-3">
      {!loading ? (
        <>
          <h3>{title}</h3>
          <p className="d-flex align-items-center">{details}</p>
        </>
      ) : (
        <CenterSpinner />
      )}
    </div>
  );
};

export default OperativoHeaderDetails;
