import TableMaquinas from "./components/TableMaquinas";
import Divider from "../../../components/Tools/Basics/Divider"

const Maquinas = () => {
  return (
    <div className="custom-container">
      <h2>
        <b>Maquinas Terminales y Servicios</b>
      </h2>
      <Divider />
      <TableMaquinas/>
    </div>
  );
};

export default Maquinas;
