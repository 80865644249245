import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlGetTeamTrip, urlRemoveTeam } from "../../../../../api/URLs/viajes";
//HOOKS/FUNCTIONS
import { useModal } from "../../../../../hooks/useModal";
//FORMS
import FormInitTrip from "../../forms/Teams/FormInitTrip";
import FormViajesDistancia from "../../forms/FormViajesDistancia";
import FormTeam from "../../forms/Teams/FormTeam";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
import ModalPlain from "../../../../../components/Modals/ModalPlain";
//--Table
import Table from "../../../../../components/Tools/Tables/Table/Table";
//--Basic
import BoxContainer from "../../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";

const TableTripsTeams = ({ trip, operativo, reFetch, setReFetch }) => {
  //useModal
  const {
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleError,
    handleSucces,
  } = useModal();
  //states
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);
  //Modal State
  const [modalState, setModalState] = useState(null);
  //Modal Plain
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET TEAMS
  const getTeams = async () => {
    try {
      setLoading(true);
      const trips_response = await axiosWithAuth.get(
        `${urlGetTeamTrip}/${trip.id}`
      );
      setTeams(trips_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setSelectedTeam(null);
      setLoading(false);
    }
  };
  //REMOVE TEAM
  const handleRemoveTeam = async (team) => {
    const merged = { id_viaje: team.id_viaje, id_equipo: team.id_equipo };
    setLoading(true);
    try {
      const api_response = await axiosWithAuth.post(urlRemoveTeam, merged);
      setReFetch(!reFetch);
      handleSucces(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //CONFIRM DELETE TEAM
  const confirmDelete = (t) => {
    if (window.confirm(`Confirmar eliminación del equipo ${t.id_equipo}`)) {
      handleRemoveTeam(t);
    }
  };
  //DISABLED ESTADO 10
  const disabledState = () => {
    if (operativo.id_estado === 10) {
      return true;
    }
    return false;
  };
  //HANDLE EQUIPOS
  const handleTeams = (team) => {
    if (!trip.distancia_calculada) {
      setModalState(null);
    } else {
      setModalState("create");
    }
    setShowModalPlain(true);
  };
  const handleEdit = (team) => {
    if (!trip.distancia_calculada) {
      setModalState(null);
    } else {
      const teamFormat = {
        id_chofer: team.id_chofer,
        id_camion: team.id_camion,
        id_acoplado: team.id_acoplado,
      };
      setSelectedTeam(teamFormat);
      setDataEdit(team);
      setModalState("edit");
    }
    setShowModalPlain(true);
  };
  const handleDistance = () => {
    setModalState(null);
    setShowModalPlain(true);
  };
  //COLUMNS EQUIPOS
  const endTeamTrip = (t) => {
    return (
      <div className="d-flex">
        {operativo.id_estado === 10 ? null : (
          <>
            <CustomBtn type={"edit_table"} onClick={() => handleEdit(t)} />
            <CustomBtn type={"delete_table"} onClick={() => confirmDelete(t)} />
          </>
        )}
      </div>
    );
  };
  const propio = (t) => {
    return (
      <span
        className={`border-start border-${
          t.camion_es_propio ? "success" : "danger"
        } border-3`}
      >
        {t.id_equipo}
      </span>
    );
  };
  const buttons = [
    {
      styles: "info btn-sm me-1",
      icon: "bi bi-geo-alt-fill",
      text: " Distancia",
      click: () => handleDistance(),
      noData: true,
      disabled: disabledState(),
    },
    {
      type: "plus_table_header",
      text: " Equipo",
      click: handleTeams,
      noData: true,
      disabled: disabledState(),
    },
  ];
  const columns = [
    { header: "Id", body: propio },
    { field: "patente_camion", header: "Camión" },
    { field: "patente_acoplado", header: "Batea" },
    { field: "apelnomb_choferv", header: "Chofer" },
    { field: "proveedor", header: "Proveedor" },
    { field: "cant_viajes", header: "Viajes" },
    { header: "Km. I.V", field: "son_km_idayvuelta", formater: "boolean" },
    { field: "toneladas", header: "Ton." },
    { field: "horas_trabajadas", header: "Hs" },
    { field: "esLargadist", header: "Larga Dist.", formater: "boolean" },
    { body: endTeamTrip },
  ];
  useEffect(() => {
    if (trip) {
      getTeams();
    }
  }, [trip, reFetch]);
  return (
    <div className="col-lg-7">
      <BoxContainer minSize={"md"} maxSize={"lg"}>
        <Table
          title={"Equipos asignados"}
          loading={loading}
          data={teams}
          columns={columns}
          scrollSize={"lg"}
          buttons={buttons}
        />
      </BoxContainer>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <ModalPlain
          show={true}
          handleClose={handleCloseModalPlain}
          size={modalState === 2 ? "sm" : "lg"}
        >
          {modalState === "create" ? (
            <FormInitTrip
              trip={trip}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          ) : modalState === "edit" ? (
            <FormTeam
              trip={trip}
              team={selectedTeam}
              dataEdit={dataEdit}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          ) : (
            <FormViajesDistancia
              trip={trip}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          )}
        </ModalPlain>
      ) : null}
    </div>
  );
};

export default TableTripsTeams;
