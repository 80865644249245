import { useState } from "react";
import CustomCard from "../../Checklists/EnviarChecklist/extra/CustomCard";
import { dateSQLformat } from "../../../../functions/dateSQLformat";
import ChecklistForm from "../../Checklists/EnviarChecklist/components/ChecklistForm";
import PlainModal from "../../../../components/Modals/ModalPlain";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
//--Img
import auto from "../../../../assets/Autoelevadora.png";
import pala from "../../../../assets/pala.png";
import retro from "../../../../assets/retro.png";

const ListChklAbiertas = ({ data, reFetch, setReFetch, loading, values }) => {
  const [noFooter, setNoFooter] = useState(null);
  const [selectedChkl, setSelectedChkl] = useState(null);
  //--Modal
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  const Desc = ({ d }) => {
    return (
      <div className="w-100">
        <p className="fw-semibold fs-5">{d.identificador_maq}</p>
        <p className="text-end fw-light">
          {dateSQLformat(d.fec_restulado_chk, "addTime")}
        </p>
        <p className="text-end fs-sm fw-light">{d.maquinista}</p>
      </div>
    );
  };
  const NoChkl = () => {
    return (
      <div className="text-center">
        <i className="bi bi-exclamation-diamond fs-1 text-danger" />
        <p className="fw-semibold">
          No existen checklists abiertas vinculadas a la contraseña que se acaba
          de ingresar
        </p>
      </div>
    );
  };
  return (
    <div className="d-flex justify-content-center flex-wrap">
      {loading ? (
        <CenterSpinner />
      ) : values ? (
        <>
          {!data || data.length === 0 ? (
            <NoChkl />
          ) : (
            data.map((d, i) => (
              <CustomCard
                key={i}
                img={
                  d.id_tipo_maquina === 1
                    ? auto
                    : d.id_tipo_maquina === 2
                    ? pala
                    : retro
                }
                desc={<Desc d={d} />}
                onClick={() => (setSelectedChkl(d), setShowModalPlain(true))}
              />
            ))
          )}
        </>
      ) : null}
      {showModalPlain ? (
        <PlainModal
          show={true}
          handleClose={handleCloseModalPlain}
          noFooter={noFooter}
        >
          <ChecklistForm
            maquina={selectedChkl}
            cerrar={true}
            setNoFooter={setNoFooter}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </PlainModal>
      ) : null}
    </div>
  );
};

export default ListChklAbiertas;
