import { useEffect, useState } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlHistoryCost } from "../../../../api/URLs/operativos";
//Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
import { useModal } from "../../../../hooks/useModal";
//Table
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";

const HistorialCostos = ({ cost }) => {
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  //GET
  const getData = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlHistoryCost);
      setHistory(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  const historyCols = [
    { field: "hora_tys", header: "Por Hora", formater: "priece" },
    { field: "km_camionero", header: "Por Km", formater: "priece" },
    { field: "fecha", header: "Fecha", formater: "date", sortable: true },
  ];

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <TableMain
        title={"Historial de Costos"}
        data={history}
        keyProp={"id"}
        columns={historyCols}
        loading={loading}
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default HistorialCostos;
