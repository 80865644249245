import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlGetDriverCashMovements } from "../../../../../api/URLs/choferes";
//HOOKS | FUNCTIONS
import { useModal } from "../../../../../hooks/useModal";
//FORMULARIOS
import FormCashMov from "../../forms/FormCashMov";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
import PlainModal from "../../../../../components/Modals/ModalPlain";
//--Table
import Table from "../../../../../components/Tools/Tables/Table/Table";
//--Basic
import BoxContainer from "../../../../../components/Tools/Cards/BoxContainer/BoxContainer";

const TableCashMovs = ({ driver }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [driverCashMovements, setDriverCashMovements] = useState([]);
  //--Modal
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET
  const getDriverCashMovements = async () => {
    setLoading(true);
    try {
      const api_response = await axiosWithAuth.get(urlGetDriverCashMovements, {
        params: {
          id_chof: driver.id_chofer,
        },
      });
      setDriverCashMovements(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //BOTONES
  const buttons = [
    {
      type: "plus_table_header",
      text: " Movimiento",
      click: () => setShowModalPlain(true),
      noData: true,
    },
  ];
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id" },
    { header: "Fecha", field: "fecha", formater: "date", sortable: true },
    {
      header: "Importe",
      field: "importe",
      formater: "priece_color",
      sortable: true,
    },
    {
      header: "Saldo Total",
      field: "saldo_total",
      formater: "priece_color",
      sortable: true,
    },
    { header: "Movimiento", field: "movimiento_descripcion" },
    { header: "Proveedor", field: "proveedor" },
    { header: "Obs", field: "obs" },
  ];
  useEffect(() => {
    getDriverCashMovements();
  }, [reFetch]);
  return (
    <div>
      <BoxContainer minSize={"lg"} maxSize={"lg"}>
        <Table
          data={driverCashMovements}
          keyProp={"id"}
          columns={columns}
          scrollSize={"lg"}
          title={"Movimientos"}
          loading={loading}
          buttons={buttons}
          textFilter
        />
      </BoxContainer>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <PlainModal show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          <FormCashMov
            driver={driver}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </PlainModal>
      ) : null}
    </div>
  );
};

export default TableCashMovs;
