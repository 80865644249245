import { useState, useEffect } from "react";
//FORMS
import FormViajes from "../../forms/FormViajes";
import FormContract from "../../forms/FormContract";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import { urlGetContracts } from "../../../../../api/URLs/operativos";
import { urlGetTnsSAF } from "../../../../../api/URLs/viajes";
//ROUTER
import { useNavigate } from "react-router-dom";
//--Modal
import ModalPlain from "../../../../../components/Modals/ModalPlain";
import ModalAlert from "../../../../../components/Modals/ModalAlert";
import { useModal } from "../../../../../hooks/useModal";
//--Table
import TableRows from "../../../../../components/Tools/Tables/TableRows/TableRows";
//--Basic
import BoxContainer from "../../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import CardContracts from "../../../../../components/Tools/Basics/CardContracts";

const TableTripsData = ({ operativo, data, loading, reFetch, setReFetch }) => {
  //useModal
  const { showModal, modalType, msjModal, handleClose, handleError } =
    useModal();
  //Modal Plain
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //useNavigate
  const navigate = useNavigate();
  //Trip
  const [tnsSeekLoading, setTnsSeekLoading] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [contracts, setContracts] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const [modalState, setModalState] = useState(null);
  //GET Contracts
  const getContracts = async () => {
    try {
      const api_response = await axiosWithAuth.get(urlGetContracts, {
        params: { id_viaje: data.id },
      });
      setContracts(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    }
  };
  //GET tns SAF
  const getTnsSAF = async () => {
    try {
      setTnsSeekLoading(true);
      await axiosWithAuth.get(urlGetTnsSAF, {
        params: { ctrl_op_saf: data.ctrl_op_saf },
      });
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setTnsSeekLoading(false);
    }
  };
  //DISABLED ESTADO 10
  const disabledState = () => {
    if (operativo.id_estado === 10) {
      return true;
    }
    return false;
  };
  //HANDLE
  const handleEdit = (trip) => {
    setModalState(1);
    setSelectedTrip(trip);
    setShowModalPlain(true);
  };

  const getAuditSAF = (data) => {
    navigate(`/operativos/viajes/${data.id}/${data.ctrl_op_saf}`, {
      state: { data },
    });
  };
  //COLUMNS VIAJES
  const rows = [
    {
      first_title: "Operativo:",
      first_prop: "desc_operativo",
      second_title: "Fecha:",
      second_prop: "fecha_inicio",
      second_formater: "date",
    },
    {
      first_title: "Tipo:",
      first_prop: "desc_tipo_operativo",
      second_title: "Cliente:",
      second_prop: "desc_cliente",
    },
    {
      first_title: "Producto:",
      first_prop: "desc_producto",
      second_title: "Toneladas:",
      second_prop: "toneladas_acumuladas",
    },
    {
      first_title: "Tipo Tarifa:",
      first_prop: "desc_tarifa",
      second_title: "Tarifa:",
      second_prop: "tarifa",
      second_formater: "priece",
    },
    {
      first_title: "Tarifa Chofer:",
      first_prop: "tarifa_chofer",
      first_formater: "priece",
      second_title: "Horas Total:",
      second_prop: "horas_acumuladas",
    },
    {
      first_title: "Salida:",
      first_prop: "ubi_origen",
      second_title: "Destino:",
      second_prop: "ubi_destino",
    },
    {
      first_title: "Obs:",
      first_prop: "obs",
      second_title: "Distancia:",
      second_prop: "distancia_calculada",
      second_formater: "km",
    },
    {
      first_title: "Ctrl SAF:",
      first_prop: "ctrl_op_saf",
    },
  ];
  const buttons = [
    {
      styles: "success btn-sm me-1",
      text: tnsSeekLoading ? "Cargando..." : "Buscar tns SAF",
      click: getTnsSAF,
      noData: true,
      disabled:
        operativo.id_estado === 10 ? true : tnsSeekLoading ? true : false,
    },
    {
      styles: "info btn-sm me-1",
      text: "Auditar equipos SAF",
      click: getAuditSAF,
    },
    {
      type: "edit_table_header",
      click: handleEdit,
      disabled: disabledState(),
    },
  ];

  const handleNewContract = (trip) => {
    setModalState(2);
    setSelectedTrip(trip);
    setShowModalPlain(true);
    setSelectedContract(null);
  };

  const buttonContract = [
    {
      type: "plus_table_header",
      click: handleNewContract,
      disabled: disabledState(),
    },
  ];

  const handleContractEdit = (contract) => {
    if (operativo.id_estado === 10) {
      return;
    } else setSelectedContract(contract);
    setModalState(2);
    setShowModalPlain(true);
  };
  useEffect(() => {
    getContracts();
  }, [reFetch]);
  return (
    <div className="col-lg-5">
      <BoxContainer minSize={"md"}>
        <TableRows
          title={"Datos de Viaje"}
          loading={loading}
          data={data}
          rows={rows}
          scrollSize={"md"}
          buttons={buttons}
        />
        <CardContracts
          buttons={buttonContract}
          trip={data}
          data={contracts}
          handleEdit={handleContractEdit}
          operativo={operativo}
        />
      </BoxContainer>
      {showModalPlain ? (
        <ModalPlain show={true} handleClose={handleCloseModalPlain} size={"lg"}>
          {modalState === 1 ? (
            <FormViajes
              trip={selectedTrip}
              operativo={operativo}
              reFetch={reFetch}
              setReFetch={setReFetch}
            />
          ) : modalState === 2 ? (
            <FormContract
              trip={selectedTrip}
              selectedContract={selectedContract}
              reFetch={reFetch}
              setReFetch={setReFetch}
              setSelectedContract={setSelectedContract}
            />
          ) : null}
        </ModalPlain>
      ) : null}
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default TableTripsData;
