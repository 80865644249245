import CardsMaquinas from "./components/CardsMaquinas";

const Checklists = () => {
  return (
    <div className="custom-container">
      <CardsMaquinas />
    </div>
  );
};

export default Checklists;
