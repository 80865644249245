import * as Yup from "yup";

export const driversSchema = Yup.object().shape({
  cuil: Yup.string()
    .matches(/^\d{8}$/, "Ingrese un numero de DNI valido")
    .required("Este campo es obligatorio"),
  direccion: Yup.string().required("Este campo es obligatorio"),
  codigoPostal: Yup.number().required("Este campo es obligatorio"),
  telefono: Yup.string().required("Este campo es obligatorio"),
  apelnomb: Yup.string().required("Este campo es obligatorio"),
  num_cred_puerto: Yup.number().required("Este campo es obligatorio"),
});
