import { useEffect, useState } from "react";
//Tables
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
//API ROUTES
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlGetResumenOperativos } from "../../../../api/URLs/operativos";
//Basics
import Divider from "../../../../components/Tools/Basics/Divider";
//Modal
import { useModal } from "../../../../hooks/useModal";
import ModalAlert from "../../../../components/Modals/ModalAlert";

const ResumenesOperativos = () => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [fetch, reFetch] = useState(false);

  //Handle Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDates((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  //Get data
  const getData = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetResumenOperativos, {
        params: {
          fecha1: dates.startDate,
          fecha2: dates.endDate,
        },
      });
      setData(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };

  //Submit and fetch data
  const handleSubmit = (e) => {
    e.preventDefault();
    reFetch(true);
  };
  //Show data
  const columns = [
    { field: "id_op_saf", header: "Id Op. Saf" },
    { field: "id_operativo_web", header: "Id Op. Web" },
    { field: "operativo", header: "Operativo" },
    { field: "fecha", header: "Fecha", formater: "date", sortable: true },
    { field: "proforma", header: "Proforma" },
    { field: "estado", header: "Estado" },
    { field: "cliente", header: "Cliente" },
    { field: "proveedor", header: "Proveedor" },
    { field: "origen", header: "Origen" },
    { field: "destino", header: "Destino" },
    { field: "viajes", header: "Viajes" },
    { field: "kms", header: "Kms." },
    { field: "horas_trabajadas", header: "Hs. Totales" },
    { field: "toneladas", header: "Tns.", formater: "round_number" },
    { field: "tipo_tarifa_cliente", header: "Tipo T. Cliente" },
    { field: "tarifa_cliente", header: "T. Cliente", formater: "priece" },
    { field: "tipo_tarifa_chofer", header: "Tipo T. Chofer" },
    { field: "tarifa_chofer", header: "T. Chofer", formater: "priece" },
    { field: "obs", header: "Obs." },
  ];
  useEffect(() => {
    getData();
    if (fetch) {
      reFetch(false);
    }
  }, [fetch]);
  return (
    <div className="custom-container">
      <h2>
        <b>Resumenes Operativos</b>
      </h2>
      <Divider />
      <form className="mb-3" onSubmit={handleSubmit}>
        <div className="d-flex align-items-end">
          <div>
            <span>Fecha Desde:</span>
            <input
              type="date"
              name="startDate"
              className="border rounded"
              style={{ height: "38px" }}
              value={dates.startDate}
              onChange={handleChange}
            ></input>
          </div>
          <div className="mx-2">
            <span>Fecha Hasta:</span>
            <input
              type="date"
              name="endDate"
              className="border rounded"
              style={{ height: "38px" }}
              value={dates.endDate}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn btn-primary px-3">
            Filtrar
          </button>
        </div>
      </form>
      <TableMain
        data={data}
        columns={columns}
        keyProp={"id"}
        textFilter
        title={"Historial"}
        loading={loading}
        excelExport
        compactCells
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default ResumenesOperativos;
