import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../../api/axiosConfig";
import {
  urlGetItems,
  urlDeleteItems,
} from "../../../../../api/URLs/mantenimiento";
//HOOKS
import { useModal } from "../../../../../hooks/useModal";
//FORMULARIO
import FormItems from "../forms/FormItems";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../../components/Modals/ModalAlert";
import PlainModal from "../../../../../components/Modals/ModalPlain";
//--Table
import TableMain from "../../../../../components/Tools/Tables/TableMain/TableMain";
//--Basic
import CustomBtn from "../../../../../components/Tools/Basics/CustomBtn";
import NoWrapContainer from "../../../../../components/Tools/Basics/NoWrapContainer";

const TableItems = () => {
  //useModal
  const {
    handleError,
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleSucces,
  } = useModal();
  //States
  const [loading, setLoading] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  //--Modal
  const [showModalPlain, setShowModalPlain] = useState(false);
  const handleCloseModalPlain = () => setShowModalPlain(false);
  //GET
  const getItems = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlGetItems);
      setItems(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
      setSelectedItem(null);
    }
  };
  //DELETE
  const handleDelete = async (id_item) => {
    const merged = {id: id_item}
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.delete(urlDeleteItems, {data: merged});
      setReFetch(!reFetch);
      handleSucces(api_response);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  const confirmDelete = (item) => {
    if (
      window.confirm(
        `Confirmar eliminación del Item: ${item.nombre}?`
      )
    ) {
      handleDelete(item.id_item);
    }
  };
  //HANDLES
  const handleForm = (item) => {
    if (item) {
      setSelectedItem(item);
    } else {
      setSelectedItem(null);
    }
    setShowModalPlain(true);
  };
  //BOTONES
  const actions = (item) => {
    return (
      <NoWrapContainer>
        <CustomBtn type={"edit_table"} onClick={() => handleForm(item)} />
        <CustomBtn type={"delete_table"} onClick={() => confirmDelete(item)} />
      </NoWrapContainer>
    );
  };
  const buttons = [
    {
      type: "plus_table_header",
      text: " Item",
      click: handleForm,
      noData: true,
    },
  ];
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id_item" },
    { header: "Nombre", field: "nombre" },
    { header: "Tipo", field: "tipo" },
    { header: "Placeholder", field: "placeholder" },
    { body: actions },
  ];
  useEffect(() => {
    getItems();
  }, [reFetch]);
  return (
    <div>
      <TableMain
        data={items}
        title={"Checklist Items"}
        columns={columns}
        buttons={buttons}
        loading={loading}
        textFilter
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
      {showModalPlain ? (
        <PlainModal show={true} handleClose={handleCloseModalPlain}>
          <FormItems
            item={selectedItem}
            reFetch={reFetch}
            setReFetch={setReFetch}
          />
        </PlainModal>
      ) : null}
    </div>
  );
};

export default TableItems;
