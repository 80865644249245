import { useState } from "react";
import { useField } from "formik";

const ChecklistInput = ({ text, children, ...props }) => {
  const [field] = useField({ ...props, type: "checkbox" });
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="my-2 ms-1 text-nowrap">
      <input
        className={"input-size-lg"}
        type="checkbox"
        {...field}
        {...props}
      />
      <span>{text}</span>
      <button
        onClick={() => setExpanded(!expanded)}
        type="button"
        className={`btn btn-sm p-0 pb-1 ms-1 text-decoration-underline text-${
          expanded ? "danger" : "primary"
        }`}
      >
        {expanded ? "Cancel" : "Obs"}
      </button>
      <div>{expanded && children}</div>
    </div>
  );
};

export default ChecklistInput;
