export function filterAndSortData(data, selectProps) {
  const filteredData = data.map((item) => {
    const filteredItem = {};
    selectProps.forEach((prop) => {
      if (item.hasOwnProperty(prop)) {
        filteredItem[prop] = item[prop];
      }
    });
    return filteredItem;
  });
  return filteredData;
}
