//FORMIK & YUP
import { Formik, Form } from "formik";
//--Basic
import ButtonSubmit from "../../../../components/Tools/Basics/BtnSubmit";
import CustomInput from "../../../../components/Tools/Inputs/CustomInput";

const FormChklAbiertas = ({ reFetch, setReFetch, setValues }) => {
  const initialValues = {
    psw_maquinista: "",
  };
  const handleSubmit = (values, { resetForm }) => {
    setValues(values);
    resetForm();
    setReFetch(!reFetch);
  };
  return (
    <>
      <p className="text-center fw-semibold">
        Ingresar contraseña de un maquinista para ver sus checklists abiertas
      </p>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="d-flex align-items-end justify-content-center">
            <CustomInput
              typeInput={"password"}
              name={"psw_maquinista"}
              placeholder={"Ingresar contraseña"}
              loading={isSubmitting}
              size={"lg"}
              autoComplete="off"
            />
            <div className="mx-2">
              <ButtonSubmit
                variant={"primary"}
                className={"btn-lg"}
                text={"Consultar"}
                loading={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormChklAbiertas;
