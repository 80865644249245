import { useLocation } from "react-router-dom";
import ChecklistValues from "./components/ChecklistValues";

const ChecklistMaquina = () => {
  //Location
  const location = useLocation();
  const maquina = location.state.item;
  return (
    <div className="custom-container">
      <ChecklistValues maquina={maquina} />
    </div>
  );
};

export default ChecklistMaquina;
