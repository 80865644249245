import { useEffect, useState } from "react";
//Tables
import TableMain from "../../../../components/Tools/Tables/TableMain/TableMain";
//API ROUTES
import { axiosWithAuth } from "../../../../api/axiosConfig";
import { urlDetailFuel } from "../../../../api/URLs/entidadesSecundarias/combustible";
//Basics
import Divider from "../../../../components/Tools/Basics/Divider";
//Modal
import { useModal } from "../../../../hooks/useModal";
import ModalAlert from "../../../../components/Modals/ModalAlert";

const Combustible = () => {
  //useModal
  const { handleError, showModal, modalType, msjModal, handleClose } =
    useModal();
  //States
  const [fuelData, setFuelData] = useState([]);
  const [loading, setLoading] = useState(false);

  //Get data
  const getData = async () => {
    try {
      setLoading(true);
      const api_response = await axiosWithAuth.get(urlDetailFuel, {
        params: {
          id_conductor: "null",
          id_camion: "null",
          fecha: "null",
        },
      });
      setFuelData(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoading(false);
    }
  };
  //Show data
  const columns = [
    { field: "patente", header: "Pat.", sortable: true },
    { field: "apelnomb", header: "Chof.", sortable: true },
    { field: "origen", header: "Origen", sortable: true },
    { field: "kms", header: "Km", formater: "km" },
    { field: "litros", header: "Litros", formater: "litros" },
    { field: "precio_litro", header: "$ Litro", formater: "priece" },
    {
      field: "precio_final",
      header: "$ Final",
      sortable: true,
      formater: "priece",
    },
    { field: "ticket", header: "Ticket" },
    { field: "vale", header: "Vale" },
    {
      header: "Fec. Carga",
      formater: "date",
      field: "fecha_carga",
      sortable: true,
    },
  ];
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="custom-container">
      <h2>
        <b>Historial de Combustible</b>
      </h2>
      <Divider />
      <TableMain
        data={fuelData}
        columns={columns}
        keyProp={"id"}
        textFilter
        title={"Historial"}
        loading={loading}
        excelExport
      />
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Combustible;
