import { useState, useEffect } from "react";
//API
import { axiosWithAuth } from "../../../../api/axiosConfig";
import {
  urlGetCompanies,
  urlCreateCompanies,
  urlEditCompanies,
} from "../../../../api/URLs/entidadesSecundarias/empresas";
//HOOKS
import { useModal } from "../../../../hooks/useModal";
//FORMIK & YUP
import { Formik } from "formik";
import { companiesSchema } from "../../../../schemas/Camiones/EntidadesSecundarias/companiesSchema";
//BOOTSTRAP
import { Button, Form, FormGroup } from "react-bootstrap";
//COMPONENTS
//--Modal
import ModalAlert from "../../../../components/Modals/ModalAlert";
//--Table
import Table from "../../../../components/Tools/Tables/Table/Table";
//--Basic
import BoxContainer from "../../../../components/Tools/Cards/BoxContainer/BoxContainer";
import CenterSpinner from "../../../../components/Tools/Basics/Spinner";
import CustomBtn from "../../../../components/Tools/Basics/CustomBtn";

const FormCompanies = () => {
  //useModal
  const {
    showModal,
    modalType,
    msjModal,
    handleClose,
    handleError,
    handleSucces,
  } = useModal();
  //States
  const [reForm, setReForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [companie, setCompanie] = useState(null);
  const [companies, setCompanies] = useState([]);
  //INITIAL VALUES
  let newValues, updateValues;
  if (companie) {
    updateValues = {
      nomb_empresa: companie.nomb_empresa ? companie.nomb_empresa : "",
      cuit: companie.cuit ? companie.cuit : "",
    };
  } else {
    newValues = {
      nomb_empresa: "",
      cuit: "",
    };
  }
  const initialValues = companie ? updateValues : newValues;
  //GET
  const getCompanies = async () => {
    setLoadingTable(true);
    try {
      const api_response = await axiosWithAuth.get(urlGetCompanies);
      setCompanies(api_response.data);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setLoadingTable(false);
    }
  };
  //POST - PUT
  const handleSubmit = async (values) => {
    Object.keys(values).forEach((key) => {
      if (key === "cuit" && values[key] === "") {
        values[key] = null;
      }
    });
    try {
      setLoading(true);
      let api_response;
      if (!companie) {
        api_response = await axiosWithAuth.post(urlCreateCompanies, values);
      } else {
        api_response = await axiosWithAuth.put(urlEditCompanies, {
          ...values,
          id: companie.id,
        });
      }
      handleSucces(api_response);
      setReForm(!reForm);
    } catch (error) {
      const auxError = error.response.data.msj
        ? error.response.data.msj
        : "Error del servidor";
      handleError(auxError);
    } finally {
      setCompanie(null);
      setLoading(false);
    }
  };
  //HANDLES
  const handleEdit = async (c) => {
    setCompanie(c);
    setReForm(!reForm);
  };
  //BOTONES
  const actions = (c) => {
    return (
      <CustomBtn
        type={"edit_table"}
        onClick={() => handleEdit(c)}
      />
    );
  };
  //COLUMNAS
  const columns = [
    { header: "Id", field: "id" },
    { header: "Nombre", field: "nomb_empresa" },
    { header: "Cuit", field: "cuit" },
    { header: "Editar", body: actions },
  ];
  useEffect(() => {
    getCompanies();
  }, [reForm]);
  return (
    <div className="row">
      <div className="col-md-8">
        <BoxContainer maxSize={"lg"} minSize={"lg"}>
          <Table
            data={companies}
            columns={columns}
            keyProp={"id"}
            title={"Empresas"}
            scrollSize={"lg"}
            textFilter
            loading={loadingTable}
          />
        </BoxContainer>
      </div>
      <div className="col-md-4">
        {!loading ? (
          <>
            <div
              className={`alert alert-${
                companie ? "success" : "primary"
              } w-100 mb-0`}
              role="alert"
            >
              {`${companie ? "Modificar" : "Agregar nueva"} Empresa ${
                companie ? companie.nomb_empresa : ""
              }`}
            </div>
            <Formik
              enableReinitialize
              validationSchema={companiesSchema}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit} className="shadow-none mb-2">
                  {/* NOMBRE */}
                  <FormGroup>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingresar Empresa"
                      name="nomb_empresa"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nomb_empresa}
                      isInvalid={
                        errors.nomb_empresa &&
                        touched.nomb_empresa &&
                        errors.nomb_empresa
                      }
                    />
                  </FormGroup>
                  {/* CUIT */}
                  <FormGroup>
                    <Form.Label>
                      Cuit <small>(sin guiones)</small>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Ingresar Cuit"
                      name="cuit"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cuit}
                      isInvalid={errors.cuit && touched.cuit && errors.cuit}
                    />
                  </FormGroup>
                  <Button
                    variant={companie ? "success" : "primary"}
                    className="w-100 mt-3"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {companie ? "Modificar" : "Agregar"}
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <CenterSpinner />
        )}
      </div>
      <ModalAlert
        show={showModal}
        type={modalType}
        msj={msjModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FormCompanies;
