import { dateSQLformat } from "../../../../functions/dateSQLformat";
import * as ExcelJS from "exceljs";

export const exportExcelChklPorDia = (data, dateFrom, dateTo) => {
  //Creamos la hoja de calculo
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet("Informe Checklists");
  //definimos las columnas
  ws.columns = [
    {
      header: "Id",
      key: "id_resultado_chk",
      width: 10,
    },
    {
      header: "Maquinista",
      key: "maquinista",
      width: 40,
    },
    {
      header: "Maquina",
      key: "interno_maq",
      width: 30,
    },
    {
      header: "Tipo Maq",
      key: "tipo_maq",
      width: 25,
    },
    {
      header: "Marca",
      key: "marca",
      width: 25,
    },
    {
      header: "Modelo",
      key: "modelo",
      width: 25,
    },
    {
      header: "Fecha",
      key: "fecha",
      width: 25,
    },
    {
      header: "Horas de uso",
      key: "horas_uso_fin",
      width: 20,
    },
    {
      header: "Estado",
      key: "chk_cerrada",
      width: 15,
    },
  ];
  // Definimos las filas
  const values = data;
  values.forEach((item) => {
    // Formatear el valor de la última columna
    const formattedItem = {
      ...item,
      fecha: dateSQLformat(item.fecha, "addTime"),
      horas_uso_fin: item.horas_uso_fin ? item.horas_uso_fin : item.horas_uso,
      chk_cerrada: item.chk_cerrada ? "Cerrada" : "Abierta",
    };
    ws.addRow(formattedItem);
  });
  //Blob
  wb.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Informe checklists ${dateFrom} - ${dateTo}`;
    a.click();
    URL.revokeObjectURL(url);
  });
};
