import TableChecklistPorDia from "./components/TableChecklistPorDia";

const ChecklistsPorDia = () => {
  return (
    <div className="custom-container">
      <TableChecklistPorDia />
    </div>
  );
};

export default ChecklistsPorDia;
